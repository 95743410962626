package data.products.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductNutritionHeader(
    val title: String? = null,
    @SerialName("per_100_title") val per100title: String? = null,
    @SerialName("per_pack_title") val perPackTitle: String? = null,
    val flavor: String? = null,
    val unit: String? = null
)