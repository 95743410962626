package pages.mainMenu

import csstype.number
import csstype.pct
import data.mainMenu.model.MainMenu
import data.mainMenu.repository.IMainMenuRepository
import emotion.react.css
import kotlinx.browser.window
import mui.material.Box
import mui.material.Container
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.appLogo.NutriAppLogo
import pages.common.components.footer.FooterComponent
import pages.mainMenu.components.MainMenuCardComponent
import react.*
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import util.FirebaseWrapper

class MainMenuPage : KoinComponent {
    private val meinMenuRepository: IMainMenuRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<MainMenuProps> { props ->
        var mainMenu: List<MainMenu> by useState(emptyList())

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_HOME)
        }

        useEffect(props.selectedCountry) {
            mainMenu = meinMenuRepository.getMenuForCountry(props.selectedCountry)
        }

        Box {
            sx {
                pageBackground()
            }
            NutriAppLogo { }

            Box {
                sx {
                    mainHolder()
                }

                img {
                    alt = "Main menu background image"
                    src = if (window.devicePixelRatio > 1) {
                        "${MainPage.BASEPATH}/images/background@2x.png"
                    } else {
                        "${MainPage.BASEPATH}/images/background.png"
                    }
                    css {
                        mainBackgroundImage()
                    }
                }

                if (mainMenu.isNotEmpty()) {
                    Box {
                        sx {
                            cardHolder()
                            width = 100.pct
                        }
                        mainMenu.forEach {
                            MainMenuCardComponent {
                                this.id = it.id
                                this.icon = it.icon
                                this.text = it.text.invoke(props.i18n4k.locale)
                                this.onClick = {
                                    props.onMenuClicked.invoke(it.id)
                                }
                            }
                        }
                    }
                }

                Box {
                    sx {
                        flexGrow = number(1.0)
                    }
                }

                FooterComponent {
                    i18n4k = props.i18n4k
                    onTermsClicked = {
                        props.onTermsClicked()
                    }
                    onSettingsClicked = {
                        props.onSettingsClicked()
                    }
                }
            }
        }
    }
}
