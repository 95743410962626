package pages.screening.selection

import ThemeContext
import csstype.*
import data.screening.model.ScreeningType
import data.screening.model.glim.GlimScore
import data.screening.model.must.MustScore
import data.screening.model.nrs.NrsScore
import data.screening.model.sacrf.SarcScore
import data.screening.model.sacrf.SarfcAnswer
import data.screening.model.snaq.SnaqScore
import data.screening.model.snaqrc.SnaqRCScore
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.card
import pages.common.iconInCircle
import react.*
import util.FirebaseWrapper

class SelectionPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<SelectionPageProps> { props ->
        val theme by useContext(ThemeContext)
        var availableScreeningTypes: List<ScreeningType> by useState(emptyList())

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_METHOD_SELECTOR)
        }

        useEffectOnce {
            props.onPatientInfoChanged.invoke(
                props.patientInfo.copy(
                    glim = GlimScore(),
                    nrs = NrsScore(),
                    must = MustScore(),
                    snaq = SnaqScore(),
                    snaqRC = SnaqRCScore(),
                    sarcF = SarcScore()
                )
            )
        }

        useEffect(props.selectedCountry) {
            val screeningType = mutableListOf<ScreeningType>()
            if (props.selectedCountry.allowNrsSuggestion) {
                screeningType.add(ScreeningType.NRS)
            }
            if (props.selectedCountry.allowSnaq) {
                screeningType.add(ScreeningType.SNAQ)
            }
            if (props.selectedCountry.allowGlim) {
                screeningType.add(ScreeningType.GLIM)
            }
            if (props.selectedCountry.allowSnaqRc) {
                screeningType.add(ScreeningType.SNAQ_RC)
            }
            screeningType.add(ScreeningType.SARCF)
            screeningType.add(ScreeningType.MUST)
            availableScreeningTypes = screeningType
        }

        List {
            sx {
                width = 596.px
                margin = "0 auto 0 auto".unsafeCast<Margin>()
            }
            availableScreeningTypes.forEach {
                Card {
                    key = it.name
                    sx {
                        card()
                        marginBottom = 16.px
                        backgroundColor = Color("#E5F6FC")
                        paddingBottom = 0.px
                    }
                    CardActionArea {
                        Box {
                            onClick = { _ ->
                                props.onScreeningSelected.invoke(it)
                            }
                            sx {
                                padding = 16.px
                                display = Display.flex
                                flexDirection = FlexDirection.column
                                justifyContent = JustifyContent.left
                                alignItems = AlignItems.flexStart
                            }

                            Typography {
                                +Translation.screeningTypeMethodLabel.invoke(props.i18n4k.locale)
                                sx {
                                    fontSize = 14.px
                                    fontWeight = FontWeight.bold
                                }
                            }
                            Box {
                                sx {
                                    width = 100.pct
                                    marginTop = 32.px
                                    display = Display.flex
                                    flexDirection = FlexDirection.row
                                    alignItems = AlignItems.center
                                }
                                Icon {
                                    +"assignment"
                                    sx {
                                        iconInCircle()
                                        color = Color("#64CCC9")
                                    }
                                }
                                Typography {
                                    +it.label.invoke(props.i18n4k.locale)
                                    sx {
                                        flexGrow = number(1.0)
                                        marginLeft = 16.px
                                        marginRight = 16.px
                                        fontSize = 24.px
                                        color = theme.palette.primary.main
                                        fontWeight = integer(500)
                                        width = Length.maxContent
                                    }
                                }
                                Icon {
                                    +"navigate_next"
                                    sx {
                                        color = theme.palette.primary.main
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
