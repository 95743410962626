package data.screening.repository

import data.countyPicker.model.Country
import data.screening.common.PatientInfo
import data.screening.model.ScreeningType
import data.screening.model.basicInfo.PhysicalActivity
import data.screening.model.basicInfo.StressFactor
import data.screening.model.result.request.*
import data.screening.model.sacrf.SarcfQuestion
import data.screening.model.snaq.SnaqQuestion
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import kotlinx.browser.sessionStorage
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.w3c.dom.get
import org.w3c.dom.set
import pages.common.components.questionAnswerSelector.IQuestion

class ScreeningRepository(private val client: HttpClient) : IScreeningRepository, KoinComponent {

    companion object {
        private const val KEY_STORED_PATIENT_INFO = "patientInfo"
        private const val PDF_GENERATOR_URL = "https://abbott.api-webuilders.cz"
        private const val OLD_SK_PDF = "$PDF_GENERATOR_URL/v1/sk/pdf"
    }

    private val json: Json by inject()

    override fun getPhysicalActivities(): List<PhysicalActivity> {
        return PhysicalActivity.physicalActivities
    }

    override fun getStressFactors(): List<StressFactor> {
        return StressFactor.stressFactors
    }

    override fun getSarcfQuestions(): List<IQuestion> {
        return SarcfQuestion.questions
    }

    override fun getStoredPatientInfo(): PatientInfo? {
        val jsonString = sessionStorage[KEY_STORED_PATIENT_INFO] ?: return null
        return try {
            json.decodeFromString(jsonString)
        } catch (t: Throwable) {
            null
        }
    }

    override fun clearSession() {
        sessionStorage.clear()
    }

    override fun getSnaqQuestions(): List<SnaqQuestion> {
        return SnaqQuestion.questions
    }

    override fun storePatientInfo(patientInfo: PatientInfo) {
        val jsonString = json.encodeToString(patientInfo)
        sessionStorage[KEY_STORED_PATIENT_INFO] = jsonString
    }

    override suspend fun generatePdfApi(
        screeningType: ScreeningType,
        country: Country,
        patientInfo: PatientInfo
    ): ByteArray? {
        val bmi = patientInfo.getBMI() ?: 0.0
        return when (screeningType) {
            ScreeningType.MUST -> {
                val mustScore = patientInfo.must
                val request = MustPdfSettings(
                    bmiScore = mustScore.getBmiScore(bmi),
                    weightLossScore = mustScore.getWeightLossScore(patientInfo),
                    isAcuteIllness = mustScore.isAcuteIllness()
                )
                client.post("$PDF_GENERATOR_URL/v1/${country.productsLanguageCode}/pdf/must") {
                    setBody(request)
                    header(HttpHeaders.ContentType, ContentType.Application.Json)
                }.readBytes()
            }
            ScreeningType.NRS -> {
                val nrsScore = patientInfo.nrs
                val startScreening = nrsScore.getStartScreeningInfo(patientInfo)
                val endScreening = EndScreening(screeningScore = nrsScore.getNutritionScoreNRS(patientInfo))
                val illnessSeverity = nrsScore.getIllnessSeverityInfo()
                val request = NrsPdfSettings(
                    startScreening = startScreening,
                    endScreening = endScreening,
                    illnessSeverity = illnessSeverity,
                    isAgeAboveLimit = nrsScore.overAgeLimit
                )
                client.post("$PDF_GENERATOR_URL/v1/${country.productsLanguageCode}/pdf/nrs") {
                    setBody(request)
                    header(HttpHeaders.ContentType, ContentType.Application.Json)
                }.readBytes()

            }
            ScreeningType.SARCF -> {
                val sarcfScore = patientInfo.sarcF
                val request = SarcfPdfSettings(
                    strengthScore = sarcfScore.power.score,
                    helpWithWalkScore = sarcfScore.helpWalking.score,
                    standUpFromChairScore = sarcfScore.gettingUpChair.score,
                    stairsWalkScore = sarcfScore.walkingStairs.score,
                    fallScore = sarcfScore.falls.score
                )

                client.post("$PDF_GENERATOR_URL/v1/${country.productsLanguageCode}/pdf/sarcf") {
                    setBody(request)
                    header(HttpHeaders.ContentType, ContentType.Application.Json)
                }.readBytes()
            }
            else -> {
                null
            }
        }
    }

    override suspend fun generateOldSkApi(request: SkPdfSettings): ByteArray {
        return client.post(OLD_SK_PDF) {
            setBody(request)
            header(HttpHeaders.ContentType, ContentType.Application.Json)
        }.readBytes()
    }
}