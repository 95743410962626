package data.screening.model.sacrf

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation
import pages.common.components.questionAnswerSelector.IAnswer
import pages.common.components.questionAnswerSelector.IQuestion

data class SarcfQuestion(
    override val title: LocalizedString,
    override val text: LocalizedString,
    override val answers: List<IAnswer>
) : IQuestion {

    companion object {

        private val power = SarcfQuestion(
            Translation.sarcfPowerTitle,
            Translation.sarcfPowerQuestion,
            listOf(
                SarfcAnswer(Translation.sarcfPowerLow, SarcScoreOption.LOW.score),
                SarfcAnswer(Translation.sarcfPowerMid, SarcScoreOption.MEDIUM.score),
                SarfcAnswer(Translation.sarcfPowerHigh, SarcScoreOption.HIGH.score),
            )
        )

        private val helpWalk = SarcfQuestion(
            Translation.sarcfHelpWalkTitle,
            Translation.sarcfHelpWalkQuestion,
            listOf(
                SarfcAnswer(Translation.sarcfHelpWalkLow, SarcScoreOption.LOW.score),
                SarfcAnswer(Translation.sarcfHelpWalkMid, SarcScoreOption.MEDIUM.score),
                SarfcAnswer(Translation.sarcfHelpWalkHigh, SarcScoreOption.HIGH.score),
            )
        )

        private val fallsGettingUpChar = SarcfQuestion(
            Translation.sarcfFallsGettingUpCharTitle,
            Translation.sarcfFallsGettingUpCharQuestion,
            listOf(
                SarfcAnswer(Translation.sarcfFallsGettingUpCharLow, SarcScoreOption.LOW.score),
                SarfcAnswer(Translation.sarcfFallsGettingUpCharMid, SarcScoreOption.MEDIUM.score),
                SarfcAnswer(Translation.sarcfFallsGettingUpCharHigh, SarcScoreOption.HIGH.score),
            )
        )

        private val stairsWalk = SarcfQuestion(
            Translation.sarcfWalkingStairsTitle,
            Translation.sarcfWalkingStairsQuestion,
            listOf(
                SarfcAnswer(Translation.sarcfWalkingStairsLow, SarcScoreOption.LOW.score),
                SarfcAnswer(Translation.sarcfWalkingStairsMid, SarcScoreOption.MEDIUM.score),
                SarfcAnswer(Translation.sarcfWalkingStairsHigh, SarcScoreOption.HIGH.score),
            )
        )

        private val falls = SarcfQuestion(
            Translation.sarcfFallsTitle,
            Translation.sarcfFallsQuestion,
            listOf(
                SarfcAnswer(Translation.sarcfFallsLow, SarcScoreOption.LOW.score),
                SarfcAnswer(Translation.sarcfFallsMid, SarcScoreOption.MEDIUM.score),
                SarfcAnswer(Translation.sarcfFallsHigh, SarcScoreOption.HIGH.score),
            )
        )


        val questions = listOf(
            power,
            helpWalk,
            fallsGettingUpChar,
            stairsWalk,
            falls
        )

    }

    override val disabled: Boolean = false


}

class SarfcAnswer(override val text: LocalizedString, override val id: Int) : IAnswer