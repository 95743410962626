package pages.screening.snaqrc

import ThemeContext
import csstype.*
import data.screening.model.RiskLevel
import data.screening.model.snaqrc.SnaqRCQuestions
import data.screening.model.snaqrc.SnaqRCResult
import htmlReactParser
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.*
import pages.common.components.toggle.ToggleComponent
import pages.screening.ScreeningMethodProps
import pages.screening.components.ScreeningScoreComponent
import react.*
import util.FirebaseWrapper

class SnaqRCScreeningPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<ScreeningMethodProps> { props ->
        val theme by useContext(ThemeContext)
        val questions by useState(SnaqRCQuestions.getQuestions())

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_SNAQ_RC_METHOD)
        }

        Container {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }

            questions.questions.forEach {
                val section = it.key
                val questionsPerSection = it.value

                Paper {
                    sx {
                        card()
                        width = 100.pct
                        marginTop = if (section.sectionNr != 1) {
                            24.px
                        } else {
                            0.px
                        }
                        display = Display.flex
                        flexDirection = FlexDirection.column
                    }
                    Typography {
                        +section.text.invoke(props.i18n4k.locale)
                        sx {
                            cardTitle()
                        }
                    }
                    Divider {
                        sx {
                            cardTitleDivider()
                            marginBottom = 16.px
                            width = 100.pct
                        }
                    }

                    questionsPerSection.forEachIndexed { index, question ->
                        if (index > 0) {
                            Divider {
                                sx {
                                    dashesDivider()
                                    marginTop = 16.px
                                    marginBottom = 16.px
                                }
                            }
                        }
                        ToggleComponent {
                            i18n4k = props.i18n4k
                            isToggled = when (question.questionNr) {
                                1 -> props.patientInfo.snaqRC.recklessWeightLoss6Kg
                                2 -> props.patientInfo.snaqRC.recklessWeightLoss3Kg
                                3 -> props.patientInfo.snaqRC.needsSomeoneElseToEat
                                4 -> props.patientInfo.snaqRC.hasReducedAppetite
                                else -> false
                            }
                            text = question.text.invoke(props.i18n4k.locale)
                            onToggleChanged = { isSelected ->

                                val snaqRCInfo = when (question.questionNr) {
                                    1 -> props.patientInfo.snaqRC.copy(recklessWeightLoss6Kg = isSelected == true)
                                    2 -> props.patientInfo.snaqRC.copy(recklessWeightLoss3Kg = isSelected == true)
                                    3 -> props.patientInfo.snaqRC.copy(needsSomeoneElseToEat = isSelected == true)
                                    4 -> props.patientInfo.snaqRC.copy(hasReducedAppetite = isSelected == true)
                                    else -> null
                                }

                                snaqRCInfo?.let { snaq ->
                                    props.onPatientInfoChanged.invoke(
                                        props.patientInfo.copy(
                                            snaqRC = snaq
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }

            ScreeningScoreComponent {
                i18n4k = props.i18n4k
                title = Translation.snaqRCScoreTitle
                results = SnaqRCResult.results
                risklevel = props.patientInfo.snaqRC.getRiskLevel(props.patientInfo)
                score = props.patientInfo.snaqRC.getTotal(props.patientInfo)
                pointsText = Translation.screeningScorePoints
                showPoints = props.country.uiSettings.showScorePoints
            }

            Card {
                sx {
                    card()
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    marginTop = 24.px
                }

                Typography {
                    +Translation.snaqRCRecommendedTreatmentTitle.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }

                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                Container {
                    sx {
                        normalFontSize()
                        marginLeft = 24.px
                        marginRight = 24.px
                        fontFamily = string("Calibri")
                        "> ul" {
                            margin = 0.px
                            paddingLeft = 16.px
                            "> li" {
                                "> ul" {
                                    margin = 0.px
                                    paddingLeft = 16.px
                                }
                            }
                        }
                    }

                    val text = when (props.patientInfo.snaqRC.getRiskLevel(props.patientInfo)) {
                        RiskLevel.HIGH -> Translation.snaqRCHighRiskSuggestions.invoke(props.i18n4k.locale)
                        RiskLevel.MEDIUM -> Translation.snaqRCMidRiskSuggestions.invoke(props.i18n4k.locale)
                        RiskLevel.LOW -> Translation.snaqRCNoRiskSuggestions.invoke(props.i18n4k.locale)
                        RiskLevel.NO_RISK -> Translation.snaqRCNoRiskSuggestions.invoke(props.i18n4k.locale)
                    }
                    text.let {
                        +htmlReactParser(it)
                    }
                }

            }

            // continue button
            Button {
                +Translation.snaqContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                disabled = !props.patientInfo.snaq.isValid()
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }
    }
}
