package data.screening.model.nrs

import data.screening.common.PatientInfo
import data.screening.model.IScore
import data.screening.model.RiskLevel
import data.screening.model.nrs.complication.NrsChronicDisease
import data.screening.model.nrs.complication.NrsJip
import data.screening.model.nrs.complication.NrsLargerOperation
import data.screening.model.nrs.complication.NrsMinorIllness
import kotlinx.serialization.Serializable
import kotlin.math.max
import kotlin.math.min

@Serializable
data class NrsScore(
    val complicationNRSMinorIllness: NrsMinorIllness = NrsMinorIllness(),
    val complicationNRSChronicDisease: NrsChronicDisease = NrsChronicDisease(),
    val complicationNRSLargerOperation: NrsLargerOperation = NrsLargerOperation(),
    val complicationNRSJip: NrsJip = NrsJip(),
    val lastWeekNutrition: LastWeekNutrition? = null,
    val weighLossTime: WeighLossTime? = null,
    val previousWeight: Int? = null,
    val overAgeLimit: Boolean = false
) : IScore {

    override fun getTotal(patientInfo: PatientInfo): Int {
        return getNutritionScoreNRS(patientInfo) + getIllnessScoreNRS() + getAgeOverScore()
    }

    override fun getRiskLevel(patientInfo: PatientInfo): RiskLevel {
        return when (getTotal(patientInfo)) {
            0 -> {
                RiskLevel.NO_RISK
            }
            in 1..2 -> {
                RiskLevel.LOW
            }
            else -> {
                RiskLevel.HIGH
            }
        }
    }

    override fun isValid(): Boolean {
        return previousWeight != null && weighLossTime != null && lastWeekNutrition != null
    }

    fun getStartScreeningInfo(patientInfo: PatientInfo): StartScreening {
        val bmi = patientInfo.getBMI() ?: 0.0
        val bmiUnderLimit: Boolean = bmi < 20.5
        val percentLoss = getWeightPercentLoss(patientInfo)
        val weightLossInThreeMonths =
            percentLoss > 5 && weighLossTime == WeighLossTime.ONE_MONTH ||
                    percentLoss > 10 && weighLossTime == WeighLossTime.TWO_MONTHS ||
                    percentLoss > 15 && weighLossTime == WeighLossTime.THREE_MONTHS

        val foodIntakeLastWeek = lastWeekNutrition != LastWeekNutrition.HIGHEST
        val chronicIllness = getIllnessScoreNRS() > 1
        return StartScreening(
            isBmiUnderLimit = bmiUnderLimit,
            isWeightLossInThreeMonths = weightLossInThreeMonths,
            isFoodIntakeLastWeek = foodIntakeLastWeek,
            isChronicIllness = chronicIllness
        )

    }

    fun getIllnessSeverityInfo(): IllnessSeverity {
        return IllnessSeverity(
            isMinorIllness = complicationNRSMinorIllness.selected,
            isChronicIllness = complicationNRSChronicDisease.selected,
            isBiggerSurgery = complicationNRSLargerOperation.selected,
            isIcu = complicationNRSJip.selected
        )
    }

    fun getWeightPercentLoss(patientInfo: PatientInfo): Int {
        val prev = (previousWeight ?: 0).toDouble()
        val curr = (patientInfo.weightKg ?: 0).toDouble()
        val loss = if (prev == 0.0 || curr == 0.0) {
            0
        } else {
            (((prev - curr) / prev) * 100).toInt()
        }
        return min(max(0, loss), 100)
    }

    fun getWeightLossScore(patientInfo: PatientInfo): Int {
        val percentLoss = getWeightPercentLoss(patientInfo)
        return when {
            percentLoss < 5 -> {
                0
            }
            5.0 <= percentLoss && percentLoss <= 10.0 -> {
                1
            }
            else -> {
                2
            }
        }
    }

    fun getAgeOverScore(): Int {
        return if (overAgeLimit) {
            1
        } else {
            0
        }
    }

    fun getIllnessScoreNRS(): Int {
        return listOf(
            complicationNRSChronicDisease,
            complicationNRSLargerOperation,
            complicationNRSJip
        ).maxOf { it.getComplicationScore() }
    }

    fun getNutritionScoreNRS(patientInfo: PatientInfo): Int {
        var nutritionScore = 0
        val bmi = patientInfo.getBMI() ?: 0.0
        val percentLoss = getWeightPercentLoss(patientInfo)

        if (percentLoss > 5 && weighLossTime == WeighLossTime.THREE_MONTHS) { // loss greater than 5 in 3 months
            nutritionScore = 1
        }

        if (lastWeekNutrition == LastWeekNutrition.HIGH) { // 50-75% food intake
            nutritionScore = 1
        }

        if (percentLoss > 5 && weighLossTime == WeighLossTime.TWO_MONTHS) { // loss greater than 5 in 2 months
            nutritionScore = 2
        }
        if (bmi in 18.5..20.5) {
            nutritionScore = 2
        }

        if (lastWeekNutrition == LastWeekNutrition.LOW) { // 25-50% food intake
            nutritionScore = 2
        }

        if (percentLoss > 5 && weighLossTime == WeighLossTime.ONE_MONTH) { // loss greater than 5 in one month
            nutritionScore = 3
        }

        if (percentLoss > 10 && weighLossTime == WeighLossTime.TWO_MONTHS) { // loss greater than 10  in 2 months
            nutritionScore = 3
        }

        if (percentLoss > 15 && weighLossTime == WeighLossTime.THREE_MONTHS) { // loss greater than 15 in 3 month
            nutritionScore = 3
        }

        if (bmi < 18.5) {
            nutritionScore = 3
        }

        if (lastWeekNutrition == LastWeekNutrition.LOWEST) { // 0-25% food intake
            nutritionScore = 3
        }

        return nutritionScore
    }
}