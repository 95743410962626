package pages.screening

import data.screening.common.PatientInfo
import data.screening.model.ScreeningType
import data.screening.repository.IScreeningRepository
import emotion.react.css
import localization.Translation
import mui.material.Box
import mui.material.Container
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.appLogo.NutriAppLogo
import pages.common.components.appToolbar.AppToolbarComponent
import pages.common.components.stepper.StepperComponent
import pages.common.contentHolder
import pages.screening.glim.GlimScreeningPage
import pages.screening.must.MustScreeningPage
import pages.screening.nrs.NrsScreeningPage
import pages.screening.patientInfo.PatientInfoPage
import pages.screening.result.ResultPage
import pages.screening.sarcf.SarcfPage
import pages.screening.selection.SelectionPage
import pages.screening.snaq.SnaqScreeningPage
import pages.screening.snaqrc.SnaqRCScreeningPage
import react.FC
import react.create
import react.router.*
import react.useEffectOnce
import react.useState
import util.FirebaseWrapper

class ScreeningPage : KoinComponent {
    companion object {
        private const val SCREENING_SELECTION = "selection"
        const val SCREENING_SARCF = "sarcf"
        const val SCREENING_MUST = "must"
        const val SCREENING_SNAQ = "snaq"
        const val SCREENING_SNAQ_RC = "snaqrc"
        const val SCREENING_GLIM = "glim"
        const val SCREENING_NRS = "nrs"
        const val SCREENING_RESULT = "result"

        private const val PAGE_ID_BASE_INFO = 1
        private const val PAGE_ID_SCREENING_SELECTION = 2
        private const val PAGE_ID_SCREENING_SARCF = 3
        private const val PAGE_ID_SCREENING_MUST = 5
        private const val PAGE_ID_SCREENING_SNAQ = 6
        private const val PAGE_ID_SCREENING_SNAQ_RC = 7
        private const val PAGE_ID_SCREENING_GLIM = 8
        private const val PAGE_ID_SCREENING_NRS = 9
        private const val PAGE_ID_SCREENING_RESULT_NRS = 10
        private const val PAGE_ID_SCREENING_RESULT_MUST = 11
        private const val PAGE_ID_SCREENING_RESULT_SARCF = 12
        private const val PAGE_ID_SCREENING_RESULT_SNAQ = 13
        private const val PAGE_ID_SCREENING_RESULT_SNAQ_RC = 14
        private const val PAGE_ID_SCREENING_RESULT_GLIM = 15

        private const val STEP_BASE_INFO = 1
        private const val STEP_SCREENING_SELECTION = 2
        private const val STEP_SCREENING_CALCULATION = 3
        private const val STEP_SCREENING_RESULT_NRS = 4
        private const val STEP_SCREENING_RESULT_MUST = 5
        private const val STEP_SCREENING_RESULT_SARCF = 6
        private const val STEP_SCREENING_RESULT_SNAQ = 7
        private const val STEP_SCREENING_RESULT_SNAQ_RC = 8
        private const val STEP_SCREENING_RESULT_GLIM = 9
    }

    private val screeningRepository: IScreeningRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    private val patientInfoPage = PatientInfoPage()
    private val selectionPage = SelectionPage()
    private val resultPage = ResultPage()
    private val mustScreeningPage = MustScreeningPage()
    private val snaqScreeningPage = SnaqScreeningPage()
    private val snaqRCScreeningPage = SnaqRCScreeningPage()
    private val glimScreeningPage = GlimScreeningPage()
    private val sarcfScreeningPage = SarcfPage()
    private val nrsScreeningPage = NrsScreeningPage()

    private fun getPageId(): Int {
        val location = useLocation()
        return when (location.pathname) {
            "${MainPage.PAGE_SCREENING}/${SCREENING_SARCF}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_SARCF}/" -> PAGE_ID_SCREENING_SARCF
            "${MainPage.PAGE_SCREENING}/${SCREENING_SELECTION}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_SELECTION}/" -> PAGE_ID_SCREENING_SELECTION
            "${MainPage.PAGE_SCREENING}/${SCREENING_MUST}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_MUST}/" -> PAGE_ID_SCREENING_MUST
            "${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ}/" -> PAGE_ID_SCREENING_SNAQ
            "${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ_RC}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ_RC}/" -> PAGE_ID_SCREENING_SNAQ_RC
            "${MainPage.PAGE_SCREENING}/${SCREENING_GLIM}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_GLIM}/" -> PAGE_ID_SCREENING_GLIM
            "${MainPage.PAGE_SCREENING}/${SCREENING_NRS}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_NRS}/" -> PAGE_ID_SCREENING_NRS
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SNAQ}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SNAQ}/" -> PAGE_ID_SCREENING_RESULT_SNAQ
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SNAQ_RC}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SNAQ_RC}/" -> PAGE_ID_SCREENING_RESULT_SNAQ_RC
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_GLIM}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_GLIM}/" -> PAGE_ID_SCREENING_RESULT_GLIM
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_MUST}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_MUST}/" -> PAGE_ID_SCREENING_RESULT_MUST
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_NRS}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_NRS}/" -> PAGE_ID_SCREENING_RESULT_NRS
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SARCF}",
            "${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SARCF}/" -> PAGE_ID_SCREENING_RESULT_SARCF
            else -> PAGE_ID_BASE_INFO
        }
    }

    private fun getStepId(pageId: Int): Int {
        return when (pageId) {
            PAGE_ID_SCREENING_SARCF, PAGE_ID_SCREENING_MUST, PAGE_ID_SCREENING_SNAQ, PAGE_ID_SCREENING_SNAQ_RC, PAGE_ID_SCREENING_GLIM, PAGE_ID_SCREENING_NRS -> STEP_SCREENING_CALCULATION
            PAGE_ID_SCREENING_SELECTION -> STEP_SCREENING_SELECTION
            PAGE_ID_SCREENING_RESULT_SNAQ -> STEP_SCREENING_RESULT_SNAQ
            PAGE_ID_SCREENING_RESULT_SNAQ_RC -> STEP_SCREENING_RESULT_SNAQ_RC
            PAGE_ID_SCREENING_RESULT_GLIM -> STEP_SCREENING_RESULT_GLIM
            PAGE_ID_SCREENING_RESULT_SARCF -> STEP_SCREENING_RESULT_SARCF
            PAGE_ID_SCREENING_RESULT_MUST -> STEP_SCREENING_RESULT_MUST
            PAGE_ID_SCREENING_RESULT_NRS -> STEP_SCREENING_RESULT_NRS
            else -> STEP_BASE_INFO
        }
    }

    private fun getTitle(pageId: Int, props: ScreeningPageProps): String {
        return when (pageId) {
            PAGE_ID_SCREENING_SARCF -> Translation.sarcfTitle
            PAGE_ID_SCREENING_SELECTION -> Translation.screeningTypeTitle
            PAGE_ID_SCREENING_MUST -> Translation.mustTitle
            PAGE_ID_SCREENING_SNAQ -> Translation.snaqTitle
            PAGE_ID_SCREENING_SNAQ_RC -> Translation.snaqRCTitle
            PAGE_ID_SCREENING_GLIM -> Translation.glimTitle
            PAGE_ID_SCREENING_NRS -> Translation.nrsTitle
            PAGE_ID_SCREENING_RESULT_NRS, PAGE_ID_SCREENING_RESULT_SARCF, PAGE_ID_SCREENING_RESULT_MUST, PAGE_ID_SCREENING_RESULT_SNAQ, PAGE_ID_SCREENING_RESULT_SNAQ_RC, PAGE_ID_SCREENING_RESULT_GLIM -> Translation.result
            else -> Translation.basicCaloricEquation
        }.invoke(props.i18n4k.locale)
    }

    private fun getBackText(pageId: Int, props: ScreeningPageProps): String {
        return when (pageId) {
            PAGE_ID_SCREENING_SARCF -> Translation.backToMethodSelection
            PAGE_ID_SCREENING_MUST -> Translation.backToMethodSelection
            PAGE_ID_SCREENING_SNAQ -> Translation.backToMethodSelection
            PAGE_ID_SCREENING_SNAQ_RC -> Translation.backToMethodSelection
            PAGE_ID_SCREENING_GLIM -> Translation.backToMethodSelection
            PAGE_ID_SCREENING_NRS -> Translation.backToMethodSelection
            PAGE_ID_SCREENING_SELECTION -> Translation.backToSimpleCaloric
            PAGE_ID_SCREENING_RESULT_SNAQ, PAGE_ID_SCREENING_RESULT_SNAQ_RC, PAGE_ID_SCREENING_RESULT_GLIM, PAGE_ID_SCREENING_RESULT_SARCF, PAGE_ID_SCREENING_RESULT_MUST, PAGE_ID_SCREENING_RESULT_NRS -> Translation.backToScreening
            else -> Translation.backToMainPage
        }.invoke(props.i18n4k.locale)
    }

    private fun savePatientInfoChanges(patientInfo: PatientInfo) {
        screeningRepository.storePatientInfo(patientInfo)
    }

    val component = FC<ScreeningPageProps> { props ->
        val navigate = useNavigate()
        var storedInfo by useState(screeningRepository.getStoredPatientInfo() ?: PatientInfo())
        var stepState by useState(1)

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_CALORIC_EQUATION)
        }

        val pageId = getPageId()
        val stepId = getStepId(pageId)
        val titleState = getTitle(pageId, props)
        val backTextState = getBackText(pageId, props)

        AppToolbarComponent {
            title = titleState
            backText = backTextState
            onClicked = {
                when (stepId) {
                    STEP_SCREENING_CALCULATION -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SELECTION}/")
                    STEP_SCREENING_SELECTION -> navigate(MainPage.PAGE_SCREENING)
                    STEP_SCREENING_RESULT_NRS -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_NRS}/")
                    STEP_SCREENING_RESULT_MUST -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_MUST}/")
                    STEP_SCREENING_RESULT_SARCF -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SARCF}/")
                    STEP_SCREENING_RESULT_SNAQ -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ}/")
                    STEP_SCREENING_RESULT_SNAQ_RC -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ_RC}/")
                    STEP_SCREENING_RESULT_GLIM -> navigate("${MainPage.PAGE_SCREENING}/${SCREENING_GLIM}/")
                    else -> props.onNavigateBack.invoke()
                }
            }
        }

        NutriAppLogo {}

        Box {
            sx {
                contentHolder()
            }

            Routes {
                Route {
                    path = SCREENING_SELECTION
                    element = selectionPage.component.create {
                        i18n4k = props.i18n4k
                        selectedCountry = props.selectedCountry
                        patientInfo = storedInfo
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onScreeningSelected = {
                            stepState = 3
                            firebaseWrapper.trackScanningStart(it)
                            when (it) {
                                ScreeningType.SARCF -> {
                                    navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SARCF}/")
                                }
                                ScreeningType.MUST -> {
                                    navigate("${MainPage.PAGE_SCREENING}/${SCREENING_MUST}/")
                                }
                                ScreeningType.SNAQ -> {
                                    navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ}/")
                                }
                                ScreeningType.SNAQ_RC -> {
                                    navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SNAQ_RC}/")
                                }
                                ScreeningType.GLIM -> {
                                    navigate("${MainPage.PAGE_SCREENING}/${SCREENING_GLIM}/")
                                }
                                ScreeningType.NRS -> {
                                    navigate("${MainPage.PAGE_SCREENING}/${SCREENING_NRS}/")
                                }
                            }
                        }
                    }
                }

                Route {
                    path = "${SCREENING_RESULT}/:screeningType"
                    element = resultPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onInvalidResult = {
                            stepState = 1
                            navigate("${MainPage.PAGE_SCREENING}/")
                        }
                        onFinish = {
                            stepState = 1
                            screeningRepository.clearSession()
                            navigate("/")
                        }
                    }
                }

                Route {
                    path = SCREENING_MUST
                    element = mustScreeningPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onNavigateNext = {
                            stepState = 4
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_MUST}/")
                        }
                    }
                }

                Route {
                    path = SCREENING_SNAQ
                    element = snaqScreeningPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onNavigateNext = {
                            stepState = 4
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SNAQ}/")
                        }
                    }
                }

                Route {
                    path = SCREENING_SNAQ_RC
                    element = snaqRCScreeningPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onNavigateNext = {
                            stepState = 4
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SNAQ_RC}/")
                        }
                    }
                }

                Route {
                    path = SCREENING_GLIM
                    element = glimScreeningPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onNavigateNext = {
                            stepState = 4
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_GLIM}/")
                        }
                    }
                }

                Route {
                    path = "$SCREENING_SARCF/*"
                    element = sarcfScreeningPage.component.create {
                        i18n4k = props.i18n4k
                        patientInfo = storedInfo
                        country = props.selectedCountry
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onNavigateNext = {
                            stepState = 4
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_SARCF}")
                        }
                    }
                }

                Route {
                    path = SCREENING_NRS
                    element = nrsScreeningPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                        onNavigateNext = {
                            stepState = 4
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_RESULT}/${SCREENING_NRS}/")
                        }
                    }
                }

                Route {
                    path = "/"
                    element = patientInfoPage.component.create {
                        i18n4k = props.i18n4k
                        country = props.selectedCountry
                        patientInfo = storedInfo
                        onNavigateNext = {
                            stepState = 2
                            navigate("${MainPage.PAGE_SCREENING}/${SCREENING_SELECTION}/")
                        }
                        onPatientInfoChanged = {
                            storedInfo = it
                            savePatientInfoChanges(it)
                        }
                    }
                }
            }
        }

        StepperComponent {
            step = stepState
            stepsCount = 4
        }
    }
}
