package data.products.model

import kotlinx.serialization.Serializable

@Serializable
data class ProductCaloricDensity(
    var unit: String = "",
    var energyUnit: String = "",
    var valueResult: Double = 0.0
) {

    companion object {
        fun energyToCaloricDensity(energy: ProductEnergy?, caloricValue : Double?, unit: String): ProductCaloricDensity {
            val energyUnit = energy?.unit?.substringBefore("/").orEmpty()
            return ProductCaloricDensity(
                valueResult = caloricValue ?: 0.0,
                energyUnit = energyUnit,
                unit = unit
            )
        }
    }


    override fun toString(): String {
        return "$valueResult $energyUnit/$unit"
    }
}