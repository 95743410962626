package data.screening.model.glim

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation
import pages.common.components.questionAnswerSelector.IAnswer
import pages.common.components.questionAnswerSelector.IQuestion

class GlimQuestions {

    companion object {
        val glimBmiQuestion = GlimSelectQuestion(
            title = null,
            text = Translation.glimBmiInfoQuestion,
            answers = listOf(
                GlimAnswer(Translation.glimBmiInfoAnswer1, GlimBMIScoreOption.ANSWER_1.id),
                GlimAnswer(Translation.glimBmiInfoAnswer2, GlimBMIScoreOption.ANSWER_2.id),
                GlimAnswer(Translation.glimBmiInfoAnswer3, GlimBMIScoreOption.ANSWER_3.id),
            )
        )

        val glimEthologicQuestions = listOf(
            GlimQuestion(text = null, title = Translation.glimEthologyQuestion1, questionNr = 1),
            GlimQuestion(text = null, title = Translation.glimEthologyQuestion2, questionNr = 2),
            GlimQuestion(text = null, title = Translation.glimEthologyQuestion3, questionNr = 3)

        )
    }
}

class GlimSelectQuestion(
    override val title: LocalizedString?,
    override val text: LocalizedString,
    override val answers: List<IAnswer>
) : IQuestion {
    override val disabled: Boolean = false
}

class GlimAnswer(override val text: LocalizedString, override val id: Int) : IAnswer

