package data.products.model

import kotlinx.serialization.Serializable
import util.serializable.ProductsMapSerializer

@Serializable
data class ProductsData(val lastUpdate : String?,
                        @Serializable(with = ProductsMapSerializer::class)
                        val countryProducts : LinkedHashMap<String,List<Product>>)



