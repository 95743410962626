package pages.screening.must

import ThemeContext
import csstype.*
import data.screening.model.RiskLevel
import data.screening.model.must.MustResult
import htmlReactParser
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.*
import pages.common.components.inputBox.InputBoxComponent
import pages.common.components.labelValueInBox.LabelValueInBoxComponent
import pages.common.components.riskProgressBar.RiskProgressBarComponent
import pages.common.components.toggle.ToggleComponent
import pages.screening.ScreeningMethodProps
import pages.screening.components.ScreeningScoreComponent
import react.*
import util.FirebaseWrapper

class MustScreeningPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()
    val component = FC<ScreeningMethodProps> { props ->

        val theme by useContext(ThemeContext)

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_MUST_METHOD)
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }
            Paper {
                sx {
                    card()
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.mustWeightLostTitle.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }
                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                    }
                }

                Container {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceAround
                        alignItems = AlignItems.center
                        marginLeft = 24.px
                        marginRight = 24.px
                        paddingBottom = 40.px
                        paddingTop = 40.px
                    }

                    InputBoxComponent {
                        inputChangeCallback = {
                            val mustInfo = props.patientInfo.must.copy(
                                previousWeight = it.toIntOrNull()
                            )
                            props.onPatientInfoChanged.invoke(
                                props.patientInfo.copy(
                                    must = mustInfo
                                )
                            )
                        }
                        isFlexGrowEnabled = false
                        hint = Translation.weightUnit.invoke(props.i18n4k.locale)
                        unit = Translation.weightUnit.invoke(props.i18n4k.locale)
                        title = Translation.mustNormalWeightTitle.invoke(props.i18n4k.locale)
                        subtitle = Translation.mustNormalWeightText.invoke(props.i18n4k.locale)
                        value = props.patientInfo.must.previousWeight?.toString() ?: ""
                    }

                    RiskProgressBarComponent {
                        text = Translation.risk.invoke(props.i18n4k.locale)
                        showButtons = false
                        value = props.patientInfo.must.getWeightPercentLoss(props.patientInfo)
                        onValueChanged = {
                            // nothing
                        }
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                LabelValueInBoxComponent {
                    label = Translation.mustScoreWeightLoss.invoke(props.i18n4k.locale)
                    value = props.patientInfo.must.getWeightLossScore(props.patientInfo).toString()
                    color = Color("#B3175A")
                }
            }

            Paper {
                sx {
                    card()
                    width = 100.pct
                    marginTop = 24.px
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.mustAcuteDisease.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }
                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                ToggleComponent {
                    i18n4k = props.i18n4k
                    isToggled = props.patientInfo.must.nutritionAcuteIllnessDeficit
                    onToggleChanged = {
                        val mustInfo = props.patientInfo.must.copy(
                            nutritionAcuteIllnessDeficit = it == true
                        )
                        props.onPatientInfoChanged.invoke(
                            props.patientInfo.copy(
                                must = mustInfo
                            )
                        )
                    }
                    text = "${Translation.mustAcuteDiseaseText.invoke(props.i18n4k.locale)} ${
                        Translation.mustAcuteDiseaseText2.invoke(props.i18n4k.locale)
                    } "
                }

                Divider {
                    sx {
                        dashesDivider()
                        marginTop = 16.px
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                LabelValueInBoxComponent {
                    label = Translation.mustAcuteDiseaseScore.invoke(props.i18n4k.locale)
                    value = props.patientInfo.must.getNutritionDeficitScore().toString()
                    color = Color("#64CCC9")
                }
            }

            ScreeningScoreComponent {
                i18n4k = props.i18n4k
                title = Translation.mustRiskTitle
                results = MustResult.results
                risklevel = props.patientInfo.must.getRiskLevel(props.patientInfo)
                score = props.patientInfo.must.getTotal(props.patientInfo)
                pointsText = Translation.screeningScorePoints
                showPoints = props.country.uiSettings.showScorePoints
            }

            Card {
                sx {
                    card()
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    marginTop = 24.px
                }

                Typography {
                    +Translation.mustRecommendedTreatmentTitle.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }

                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                Container {
                    sx {
                        normalFontSize()
                        marginLeft = 24.px
                        marginRight = 24.px
                        fontFamily = string("Calibri")
                        "> ul" {
                            margin = 0.px
                            paddingLeft = 16.px
                            "> li" {
                                "> ul" {
                                    margin = 0.px
                                    paddingLeft = 16.px
                                }
                            }
                        }
                    }

                    val text = when (props.patientInfo.must.getRiskLevel(props.patientInfo)) {
                        RiskLevel.HIGH -> Translation.mustTreatmentStepsHigh.invoke(props.i18n4k.locale)
                        RiskLevel.MEDIUM -> Translation.mustTreatmentStepsMid.invoke(props.i18n4k.locale)
                        RiskLevel.LOW -> Translation.mustTreatmentStepsLow.invoke(props.i18n4k.locale)
                        else -> null
                    }
                    text?.let {
                        +htmlReactParser(it)
                    }
                }

            }

            // continue button
            Button {
                +Translation.mustContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                disabled = !props.patientInfo.must.isValid()
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }
    }
}
