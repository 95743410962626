package data

import org.koin.dsl.module
import util.FirebaseWrapper

val firebaseModule = module {

    single {
        FirebaseWrapper()
    }
}