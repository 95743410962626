package pages.common.components.appToolbar

import ThemeContext
import csstype.*
import mui.material.*
import mui.system.sx
import pages.common.backNavigationText
import pages.common.toolbar
import react.*

val AppToolbarComponent = FC<AppToolbarComponentProps> { props ->
    val theme by useContext(ThemeContext)

    Toolbar {
        variant = ToolbarVariant.regular
        sx {
            toolbar(theme)
        }
        Typography {
            +props.title
        }
        if(props.backText != null) {
            Button {
                +(props.backText ?: "")
                startIcon = Fragment.create {
                    Icon {
                        +"arrow_back"
                    }
                }
                variant = ButtonVariant.text
                onClick = {
                    props.onClicked.invoke()
                }
                sx {
                    position = Position.absolute
                    right = 16.px
                    backNavigationText()
                }
            }
        }
    }
}
