package pages.product.search

import MainPage.Companion.PAGE_PRODUCTS
import csstype.px
import data.cookies.repository.ICookiesRepository
import data.products.model.Product
import data.products.model.ProductSearchFilter
import data.products.repository.IProductsRepository
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import mui.material.Container
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.product.search.filter.ProductFilterComponent
import pages.product.search.list.ProductListComponent
import react.*
import react.router.useNavigate
import util.FirebaseWrapper
import util.format

class ProductSearchPage : KoinComponent {
    private val cookiesRepository: ICookiesRepository by inject()
    private val productsRepository: IProductsRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    @OptIn(DelicateCoroutinesApi::class)
    val component = FC<ProductSearchPageProps> { props ->

        val navigate = useNavigate()

        val selectedCountry = cookiesRepository.getSelectedCountry()
        var searchFilterState: ProductSearchFilter by useState(ProductSearchFilter.createNewFilter())
        var allProducts: List<Product> by useState(emptyList())
        var filteredProducts: List<Product> by useState(emptyList())

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_PRODUCT_CATALOGUE)
        }

        useEffect(selectedCountry) {
            selectedCountry?.let {
                GlobalScope.launch {
                    allProducts = productsRepository.getAllProducts(it)
                }
            }
        }

        useEffect(allProducts, searchFilterState, selectedCountry) {
            if (allProducts.isEmpty()) {
                return@useEffect
            }

            selectedCountry?.let {
                GlobalScope.launch {
                    filteredProducts = productsRepository.getProductsByFilter(it, searchFilter = searchFilterState)
                }
            }
        }

        ProductFilterComponent {
            i18n4k = props.i18n4k
            searchFilter = searchFilterState
            filterChangeCallback = {
                searchFilterState = it
            }
            caloricDensityValues =
                allProducts.sortedBy { it.caloricDensityValue }.distinctBy { it.caloricDensityValue }
                    .mapNotNull { it.caloricDensityValue?.format(2) }
            proteinsValues = allProducts.sortedBy { it.getProteinValue() }.distinctBy { it.getProteinValue() }
                .mapNotNull { it.getProteinValue()?.format(2) }
        }

        Container {
            sx {
                height = 24.px
            }
        }

        ProductListComponent {
            i18n4k = props.i18n4k
            products = filteredProducts
            onProductClick = {
                navigate("$PAGE_PRODUCTS/${it.id}/")
            }
        }
    }
}
