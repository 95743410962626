package pages.product.search.filter

import csstype.*
import localization.Translation
import mui.material.Container
import mui.material.Divider
import mui.material.Paper
import mui.material.Typography
import mui.system.sx
import pages.common.card
import pages.common.cardTitle
import pages.common.cardTitleDivider
import pages.common.components.inputBox.InputBoxDropDownComponent
import pages.common.components.toggle.ToggleComponent
import react.*
import util.format

val ProductFilterComponent = FC<ProductFilterComponentProps> { props ->
    Paper {
        sx {
            card()
        }
        Typography {
            +Translation.productFilterTitle.invoke(props.i18n4k.locale)
            sx {
                cardTitle()
            }
        }
        Divider {
            sx {
                cardTitleDivider()
            }
        }

        // density and protein inputs
        Container {
            sx {
                paddingTop = 24.px
                paddingBottom = 24.px
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.spaceBetween
            }
            InputBoxDropDownComponent {
                dropDownValues = props.caloricDensityValues
                title = Translation.productFilterCaloricDensity.invoke(props.i18n4k.locale)
                hint = "kcal/ml"
                unit = "kcal/ml"
                value = props.searchFilter.caloric?.format(2) ?: "kcal/ml"
                isFlexGrowEnabled = true
                inputChangeCallback = {
                    val newSearchFilter = props.searchFilter.copy(
                        caloric = it.toDoubleOrNull()
                    )
                    props.filterChangeCallback.invoke(newSearchFilter)
                }

            }
            Container {
                sx {
                    flexShrink = number(0.0)
                    width = 16.px
                }
            }

            InputBoxDropDownComponent {
                dropDownValues = props.proteinsValues
                title = Translation.productFilterProtein.invoke(props.i18n4k.locale)
                hint = "g"
                unit = "g"
                value = props.searchFilter.proteins?.format(2) ?: "g"
                isFlexGrowEnabled = true
                inputChangeCallback = {
                    val newFilter = props.searchFilter.copy(
                        proteins = it.toDoubleOrNull()
                    )
                    props.filterChangeCallback.invoke(newFilter)
                }
            }
        }

        Container {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                width = 100.pct
            }

            // diabetes
            ToggleComponent {
                i18n4k = props.i18n4k
                isToggled = props.searchFilter.diabetes
                isFullWidth = true
                text = Translation.productFilterDiabetes.invoke(props.i18n4k.locale)
                onToggleChanged = {
                    val newSearchFilter = props.searchFilter.copy(
                        diabetes = it
                    )
                    props.filterChangeCallback.invoke(newSearchFilter)
                }
            }

            Divider {
                sx {

                }
                /* TODO styles
                css {
                    +ProductPageStyles.toggleFilterDivider
                }*/
            }

            //Fiber
            ToggleComponent {
                i18n4k = props.i18n4k
                isFullWidth = true
                isToggled = props.searchFilter.fiber
                text = Translation.productFilterFiber.invoke(props.i18n4k.locale)
                onToggleChanged = {
                    val newSearchFilter = props.searchFilter.copy(
                        fiber = it
                    )
                    props.filterChangeCallback.invoke(newSearchFilter)
                }
            }
        }
    }
}
