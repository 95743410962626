package pages.product.search.list

import csstype.*

fun PropertiesBuilder.productTextLabel() {
    display = Display.listItem
    marginTop = 6.px
    marginBottom = 6.px
    listStyleType = "disc".unsafeCast<ListStyleType>()
    color = Color("#000000")
    opacity = number(1.0)
    fontSize = 13.px
    fontWeight = integer(400)
}

