package data.screening.model.must

import data.screening.common.PatientInfo
import data.screening.model.IScore
import data.screening.model.RiskLevel
import kotlinx.serialization.Serializable
import kotlin.math.max
import kotlin.math.min

@Serializable
data class MustScore(
    val previousWeight: Int? = null,
    val nutritionAcuteIllnessDeficit: Boolean = false,
) : IScore {

    override fun isValid(): Boolean {
        return previousWeight != null
    }

    fun getBmiScore(bmi: Double): Int {
        return when {
            bmi < 18.5 -> {
                2
            }
            bmi in 18.5..20.0 -> {
                1
            }
            else -> {
                0
            }
        }
    }

    fun getWeightLossScore(patientInfo: PatientInfo): Int {
        val percentLoss = getWeightPercentLoss(patientInfo)
        return when {
            percentLoss < 5 -> {
                0
            }
            5.0 <= percentLoss && percentLoss <= 10.0 -> {
                1
            }
            else -> {
                2
            }
        }
    }

    fun getWeightPercentLoss(patientInfo: PatientInfo): Int {
        val prev = (previousWeight ?: 0).toDouble()
        val curr = (patientInfo.weightKg ?: 0).toDouble()

        val loss = if (prev == 0.0 || curr == 0.0) {
            0
        } else {
            (((prev - curr) / prev) * 100).toInt()
        }
        return min(max(0, loss), 100)
    }

    fun getNutritionDeficitScore(): Int {
        return if (nutritionAcuteIllnessDeficit) {
            2
        } else {
            0
        }

    }

    override fun getTotal(patientInfo: PatientInfo): Int {
        return getBmiScore(patientInfo.getBMI() ?: 0.0) + getNutritionDeficitScore() +
                getWeightLossScore(patientInfo)
    }

    fun isAcuteIllness(): Boolean {
        return nutritionAcuteIllnessDeficit
    }

    override fun getRiskLevel(patientInfo: PatientInfo): RiskLevel {
        return when (getTotal(patientInfo)) {
            0 -> {
                RiskLevel.LOW
            }
            1 -> {
                RiskLevel.MEDIUM
            }
            else -> {
                RiskLevel.HIGH
            }
        }
    }
}