import data.cookies.cookiesModule
import data.countyPicker.countryPickerModule
import data.firebaseModule
import data.mainMenu.mainMenuModule
import data.networkModule
import data.products.productsModule
import data.screening.screeningModule
import kotlinx.browser.document
import org.koin.core.context.startKoin
import react.*
import react.dom.client.createRoot
import react.router.dom.HashRouter
import web.dom.Element

fun main() {
    // start koin
    startKoin {
        modules(
            firebaseModule,
            countryPickerModule,
            cookiesModule,
            mainMenuModule,
            productsModule,
            networkModule,
            screeningModule
        )
    }

    createRoot(document.createElement("div")
        .also {
            it.id = "root"
            document.body!!.appendChild(it)
        } as Element)
        .render(App.create())

}

private val App = FC<Props> {
    val mainPage = MainPage()
    HashRouter {
        ThemeModule {
            mainPage.component{}
        }
    }
}
