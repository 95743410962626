package pages.common.components.questionAnswerSelector

import ThemeContext
import csstype.*
import mui.material.*
import mui.system.sx
import pages.common.cardTitleDivider
import pages.common.normalFontSize
import react.*

val QuestionAnswerSelectorComponent = FC<QuestionAnswerSelectorComponentProps> { props ->
    val theme by useContext(ThemeContext)

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        if (props.question.title != null) {
            Typography {
                +(props.question.title?.invoke(props.i18n4k.locale) ?: "")
                sx {
                    whiteSpace = WhiteSpace.preLine
                    marginLeft = 24.px
                    marginRight = 24.px
                    marginBottom = 8.px
                    marginTop = 24.px
                    fontWeight = integer(500)
                }
            }

            Divider {
                sx {
                    width = 100.pct
                    cardTitleDivider()
                }
            }
        }

        Typography {
            +props.question.text.invoke(props.i18n4k.locale)
            sx {
                normalFontSize()
                marginLeft = 24.px
                marginRight = 24.px
                marginTop = 16.px
                marginBottom = 8.px
            }
        }

        if (props.question.answers.isNotEmpty()) {
            FormControl {
                sx {
                    marginLeft = 40.px
                    marginRight = 40.px
                    marginBottom = 24.px
                }
                RadioGroup {
                    value = props.selectedAnswer?.id?.toString() ?: ""
                    onChange = { element, value ->
                        println("value: $value / element: ${element.target.value}")
                        val answer = props.question.answers.firstOrNull { it.id.toString() == value }
                        props.onAnswerSelected(answer)
                    }

                    props.question.answers.forEach { answer ->
                        FormControlLabel {
                            value = answer.id.toString()
                            label = Typography.create {
                                +answer.text.invoke(props.i18n4k.locale)
                                sx {
                                    normalFontSize()
                                }
                            }
                            disabled = props.question.disabled
                            checked = props.selectedAnswer?.id == answer.id
                            control = Radio.create {
                                disabled = props.question.disabled
                                color = RadioColor.primary
                                sx {
                                    color = theme.palette.primary.main
                                    if (props.question.disabled) {
                                        opacity = number(0.1)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
