package data.products.repository

import data.countyPicker.model.Country
import data.products.model.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.await
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import util.FirebaseWrapper
import util.zeroIfNull

class ProductsRepository(val client: HttpClient) : IProductsRepository, KoinComponent {

    companion object {
        const val REMOTE_VERSION = "version"
        const val REMOTE_PRODUCT_FILES = "productsFiles"

        const val LOCALSTORAGE_PRODUCTS_DATA = "products_data"
        const val FALLBACK_PRODUCTS_LINK = "products/json/products_cz.json"
    }

    private val json: Json by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    override fun getLastVersionRemoteConfig(): String? {
        return firebaseWrapper.firebaseRemoteConfig.getString(REMOTE_VERSION)
    }

    override fun getLastVersionLocalStorage(): String? {
        return getProductsDataFromStorage()?.lastUpdate
    }

    override suspend fun fetchProducts(country: Country) {
        if (isUpdateNeeded(country)) {
            val productsReference =
                firebaseWrapper.firebaseStorage.ref(getProductLinkByCountry(country.productsLanguageCode))
            val url = productsReference.getDownloadURL().await()
            val response: List<Product> = client.get(url.toString()).body()
            response.forEach { product ->
                val downloadUrlImage = product.images?.firstOrNull()
                    ?.let { imagePath -> firebaseWrapper.firebaseStorage.ref(imagePath) }?.getDownloadURL()
                    ?.await() as String
                product.imageThumb = downloadUrlImage
                product.mergeNutrients()
                product.productCaloricDensity =
                    ProductCaloricDensity.energyToCaloricDensity(
                        energy = product.energy,
                        caloricValue = product.caloricDensityValue,
                        unit = product.units,
                    )

                val fullFlavors = ArrayList<ProductFlavor>()
                product.flavorObjects?.forEach { f ->
                    val flavor = ProductFlavor(
                        name = f.name,
                        code = f.code,
                        country = country.productsLanguageCode,
                        idProduct = product.id
                    )
                    fullFlavors.add(flavor)
                }
                product.flavorObjects = fullFlavors
            }

            storeProducts(country, response)
        }

    }

    override suspend fun getAllProducts(country: Country): List<Product> {
        return getProductsDataFromStorage()?.countryProducts?.get(country.productsLanguageCode).orEmpty()
    }

    override suspend fun getProductsByFilter(country: Country, searchFilter: ProductSearchFilter): List<Product> {
        val allProducts = getProductsDataFromStorage()?.countryProducts?.get(country.productsLanguageCode).orEmpty()
        var filteredList = ArrayList<Product>()
        filteredList.addAll(allProducts)

        val protein = searchFilter.proteins
        if (protein != null) {
            filteredList = ArrayList(filteredList.filter { product ->
                product.getProteinValue().zeroIfNull() == protein
            })
        }

        val caloricDensity = searchFilter.caloric

        if (caloricDensity != null) {
            filteredList = ArrayList(filteredList.filter { product ->
                product.productCaloricDensity?.valueResult.zeroIfNull() == caloricDensity
            })
        }

        if (searchFilter.fiber == true) {
            filteredList =
                ArrayList(filteredList.filter { product -> product.includesFiber() })
        }

        if (searchFilter.diabetes == true) {
            filteredList =
                ArrayList(filteredList.filter { product -> product.diabetic == searchFilter.diabetes })
        }

        return filteredList
    }

    override suspend fun getProductDetail(country: Country, idProduct: Long): Product {
        val countryData = getProductsDataFromStorage()?.countryProducts?.get(country.productsLanguageCode)
        val result = countryData?.find { it.id == idProduct }
        return result
            ?: throw  NoSuchElementException()
    }


    override suspend fun fetchRemoteConfig() {
        firebaseWrapper.firebaseRemoteConfig.fetchAndActivate().await()
    }

    override suspend fun authUser() {
        firebaseWrapper.firebaseAuth.signInAnonymously().await()
    }

    private fun storeProducts(country: Country, products: List<Product>) {
        val productsData = getProductsDataFromStorage() ?: ProductsData(
            lastUpdate = getLastVersionRemoteConfig(),
            countryProducts = linkedMapOf()
        )
        productsData.countryProducts[country.productsLanguageCode] = products
        localStorage.setItem(LOCALSTORAGE_PRODUCTS_DATA, json.encodeToString(productsData))
    }

    private fun isUpdateNeeded(country: Country): Boolean {
        val versionConfig = getLastVersionRemoteConfig()
        val versionStorage = getLastVersionLocalStorage()
        if (versionConfig != versionStorage) {
            invalidateCache()
            return true
        }
        val productsData = getProductsDataFromStorage()
        if (productsData?.countryProducts?.get(country.productsLanguageCode).isNullOrEmpty()) {
            return true
        }

        return false
    }

    private fun invalidateCache() {
        localStorage.removeItem(LOCALSTORAGE_PRODUCTS_DATA)
    }

    private fun getProductsDataFromStorage(): ProductsData? {
        val dataJson = localStorage.getItem(LOCALSTORAGE_PRODUCTS_DATA) ?: return null
        return try {
            json.decodeFromString(dataJson)
        } catch (t: Throwable) {
            // t.printStackTrace()
            null
        }
    }

    private fun getProductLinkByCountry(countryCode: String): String {
        return getProductFilesRemoteConfig()[countryCode] ?: FALLBACK_PRODUCTS_LINK
    }

    private fun getProductFilesRemoteConfig(): HashMap<String, String> {
        val productFilesJson = firebaseWrapper.firebaseRemoteConfig.getString(REMOTE_PRODUCT_FILES)
        return try {
            json.decodeFromString(productFilesJson)
        } catch (t: Throwable) {
            // t.printStackTrace()
            return HashMap()
        }
    }
}