package data.screening.model.glim

import data.screening.common.Gender
import data.screening.common.PatientInfo
import data.screening.model.IScore
import data.screening.model.RiskLevel
import kotlinx.serialization.Serializable
import kotlin.math.min
import kotlin.math.pow
import kotlin.math.roundToInt

@Serializable
data class GlimScore(
    val ffmiByUser: Boolean? = null,
    val randomDecreaseBMI: Boolean = false,
    val reducedIntakeLastWeek: Boolean = false,
    val patientAcuteIllness: Boolean = false,
    val patientChronicalMalabsorption: Boolean = false,
    val bmiInfoScoreByUser: GlimBMIScoreOption = GlimBMIScoreOption.ANSWER_NONE,
    private val ffmiValue: Float? = null
) : IScore {

    fun getFfmiValue(): Float? {
        return if (ffmiValue == 0.0f) {
            null
        } else {
            ffmiValue
        }
    }

    fun getFFMI(patientInfo: PatientInfo): Boolean {
        if (ffmiByUser != null) {
            return ffmiByUser
        }
        val percentage = getFfmiPercentage(patientInfo)
        return percentage != null && ((patientInfo.gender == Gender.male && percentage < 17) || (patientInfo.gender == Gender.female && percentage < 15))
    }

    fun getFfmiPercentage(patientInfo: PatientInfo): Int? {
        return if (ffmiValue != null) {
            (ffmiValue / ((patientInfo.heightCm ?: 0) / 100.0).pow(2)).roundToInt()
        } else {
            null
        }
    }

    fun getBMIInfoScore(patientInfo: PatientInfo): GlimBMIScoreOption {
        if (bmiInfoScoreByUser != GlimBMIScoreOption.ANSWER_NONE) {
            return bmiInfoScoreByUser
        }
        return if ((patientInfo.getBMI() ?: 0.0) < 20 && patientInfo.getAge() < 70) {
            GlimBMIScoreOption.ANSWER_2
        } else if ((patientInfo.getBMI() ?: 0.0) < 22 && patientInfo.getAge() > 70) {
            GlimBMIScoreOption.ANSWER_3
        } else if ((patientInfo.getBMI() ?: 0.0) > 20) {
            GlimBMIScoreOption.ANSWER_1
        } else {
            GlimBMIScoreOption.ANSWER_NONE
        }
    }

    override fun getTotal(patientInfo: PatientInfo): Int {
        return getPhenotypicScore(
            patientInfo
        ) + getEthologyScore()
    }


    fun getPhenotypicScore(patientInfo: PatientInfo): Int {
        val randomDecreaseBMIPoints = if (randomDecreaseBMI) {
            1
        } else {
            0
        }

        val ffmiPoints = if (getFFMI(patientInfo)) {
            1
        } else {
            0
        }

        return randomDecreaseBMIPoints + ffmiPoints + getBMIInfoScore(patientInfo).score
    }

    fun getEthologyScore(): Int {
        val reducedIntakeLastWeekPoints = if (reducedIntakeLastWeek) {
            1
        } else {
            0
        }
        val patientAcuteIllnessPoints = if (patientAcuteIllness) {
            1
        } else {
            0
        }

        val patientChronicalMalabsorptionPoints = if (patientChronicalMalabsorption) {
            1
        } else {
            0
        }
        return reducedIntakeLastWeekPoints + patientAcuteIllnessPoints + patientChronicalMalabsorptionPoints
    }

    override fun getRiskLevel(patientInfo: PatientInfo): RiskLevel {
        val phenotypicScore = getPhenotypicScore(
            patientInfo
        )
        val ethologyScore = getEthologyScore()
        val total = getTotal(patientInfo)
        val limitedTotal = if (phenotypicScore == 0 || ethologyScore == 0) {
            min(total, 1)
        } else {
            total
        }

        return when {
            limitedTotal >= 2 -> {
                RiskLevel.HIGH
            }
            limitedTotal == 1 -> {
                RiskLevel.MEDIUM
            }
            else -> {
                RiskLevel.NO_RISK
            }
        }
    }

    override fun isValid(): Boolean {
        return true
    }

}