package data.mainMenu.repository

import data.countyPicker.model.Country
import data.mainMenu.model.MainMenu

class MainMenuRepository: IMainMenuRepository {
    override fun getMenuForCountry(country: Country): List<MainMenu> {
        val menu = MainMenu.all.toMutableList()
        if(!country.allowCompendium) {
            menu.removeAll { it.id == MainMenu.ID_COMPENDIUM }
        }
        if(!country.allowSearch) {
            menu.removeAll { it.id == MainMenu.ID_SEARCH }
        }
        return menu
    }
}