package data.screening.model.basicInfo

import de.comahe.i18n4k.strings.LocalizedString

interface IBasicInfo {
    val id: Int
    val range: IntRange
    val label: LocalizedString

    fun getInitValue(): Int

    fun getMultiplicationLabel(): String {
        if (this == StressFactor.noStressFactor || this == PhysicalActivity.noPhysicalActivity) {
            return ""
        }
        val first = (100 + range.first).toDouble() / 100
        return if (range.first == range.last) {
            "(x $first)"
        } else {
            val second = (100 + range.last).toDouble() / 100
            "(x $first-$second)"
        }
    }

    fun formatToPercent(number: Int): String {
        return if (number < 10) {
            "1.0$number"
        } else {
            "1.$number"
        }
    }

    fun getRangeText(): String {
        return if (range.first != range.last) {
            "(x ${formatToPercent(range.first)} - ${formatToPercent(range.last)})"
        } else {
            "(x ${
                formatToPercent(range.first)
            })"
        }
    }
}