package pages.screening.snaq

import ThemeContext
import csstype.*
import data.screening.model.snaq.SnaqResult
import data.screening.repository.IScreeningRepository
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.*
import pages.common.components.toggle.ToggleComponent
import pages.screening.ScreeningMethodProps
import pages.screening.components.ScreeningScoreComponent
import react.*
import util.FirebaseWrapper

class SnaqScreeningPage : KoinComponent {
    private val screeningRepository: IScreeningRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<ScreeningMethodProps> { props ->
        val theme by useContext(ThemeContext)
        val questions by useState(screeningRepository.getSnaqQuestions())

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_SNAQ_METHOD)
        }

        Container {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }
            Paper {
                sx {
                    card()
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.mustWeightLostTitle.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }
                Divider {
                    sx {
                        cardTitleDivider()
                        marginBottom = 16.px
                        width = 100.pct
                    }
                }

                if (questions.isNotEmpty()) {
                    questions.forEachIndexed { index, questions ->
                        if (index > 0) {
                            Divider {
                                sx {
                                    dashesDivider()
                                    marginTop = 16.px
                                    marginBottom = 16.px
                                }
                            }
                        }
                        ToggleComponent {
                            i18n4k = props.i18n4k
                            isToggled = when (index) {
                                0 -> props.patientInfo.snaq.lostWeightSixMonths
                                1 -> props.patientInfo.snaq.armCircumference
                                2 -> props.patientInfo.snaq.lowTasteLastWeek
                                3 -> props.patientInfo.snaq.fifteenSteps
                                else -> false
                            }
                            text = questions.text.invoke(props.i18n4k.locale)
                            onToggleChanged = {
                                val snaqInfo = when (index) {
                                    0 -> props.patientInfo.snaq.copy(lostWeightSixMonths = it == true)
                                    1 -> props.patientInfo.snaq.copy(armCircumference = it == true)
                                    2 -> props.patientInfo.snaq.copy(lowTasteLastWeek = it == true)
                                    3 -> props.patientInfo.snaq.copy(fifteenSteps = it == true)
                                    else -> null
                                }
                                snaqInfo?.let {
                                    props.onPatientInfoChanged.invoke(
                                        props.patientInfo.copy(
                                            snaq = it
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }

            ScreeningScoreComponent {
                i18n4k = props.i18n4k
                title = Translation.snaqScoreTitle
                results = SnaqResult.results
                risklevel = props.patientInfo.snaq.getRiskLevel(props.patientInfo)
                score = props.patientInfo.snaq.getTotal(props.patientInfo)
                pointsText = Translation.screeningScorePoints
                showPoints = props.country.uiSettings.showScorePoints
            }

            // continue button
            Button {
                +Translation.snaqContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                disabled = !props.patientInfo.snaq.isValid()
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }
    }
}
