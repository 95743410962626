package data.screening.model.snaqrc

import csstype.Color
import data.screening.model.IScreeningScoreResult
import data.screening.model.RiskLevel
import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

data class SnaqRCResult(
    override val riskLevel: RiskLevel,
    override val text: LocalizedString,
    override val icon: String,
    override val color: Color
) : IScreeningScoreResult {

    companion object {
        private val scoreLow = SnaqRCResult(RiskLevel.NO_RISK, Translation.snaqRCNoRisk, "info", Color("#64CCC9"))
        private val mediumScore = SnaqRCResult(RiskLevel.MEDIUM, Translation.snaqRCMidRisk, "visibility", Color("#F5B420"))
        private val scoreHigh = SnaqRCResult(RiskLevel.HIGH, Translation.snaqRCHighRisk, "error_outline", Color("#B3175A"))
        val results = listOf(scoreLow, mediumScore, scoreHigh)
    }
}