package pages.common.components.riskProgressBar

import ThemeContext
import csstype.*
import emotion.react.css
import mui.material.*
import mui.system.sx
import pages.common.normalFontSize
import react.*
import kotlin.math.max
import kotlin.math.min

val RiskProgressBarComponent = FC<RiskProgressBarComponentProps> { props ->
    val theme by useContext(ThemeContext)
    Box {
        sx {
            position = Position.relative
            display = Display.flex
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
        }
        if (props.showButtons == true) {
            Button {
                +"-"
                variant = ButtonVariant.contained
                color = ButtonColor.primary
                disabled = props.min == props.value
                onClick = {
                    if (props.min == null || (props.min ?: 0) < props.value) {
                        props.onValueChanged.invoke(props.value - 1)
                    }
                }
                sx {
                    riskProgressBarControlButton(theme)
                }
            }
        }
        Box {
            sx {
                position = Position.relative
            }
            CircularProgress {
                variant = CircularProgressVariant.determinate
                size = 156.px
                thickness = 1.0
                value = 100.0
                sx {
                    marginLeft = 24.px
                    marginRight = 24.px
                    color = Color("#E5F6FC")
                }
            }
            CircularProgress {
                value = max(-100.0, min(100.0, props.value.toDouble()))
                variant = CircularProgressVariant.determinate
                size = 156.px
                thickness = 1.0
                sx {
                    left = 0.px
                    position = Position.absolute
                    marginLeft = 24.px
                    marginRight = 24.px
                    color = Color("#64CCC9")
                }
            }
        }
        if (props.showButtons == true) {
            Button {
                +"+"
                variant = ButtonVariant.contained
                color = ButtonColor.primary
                disabled = props.max == props.value
                onClick = {
                    if (props.max == null || (props.max ?: 0) > props.value) {
                        props.onValueChanged.invoke(props.value + 1)
                    }
                }
                sx {
                    riskProgressBarControlButton(theme)
                }
            }
        }

        // content in middle
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.center
                position = Position.absolute
                left = 50.pct
                top = 50.pct
                transform = translate((-50).pct, (-50).pct)
            }
            Typography {
                +"${props.value}%"
                sx {
                    fontSize = 24.px
                    textAlign = TextAlign.center
                    color = Color("#64CCC9")
                }
            }
            Typography {
                +props.text
                sx {
                    normalFontSize()
                    textAlign = TextAlign.center
                    fontWeight = integer(500)
                }
            }
        }
    }
}