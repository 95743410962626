package pages.screening.glim

import ThemeContext
import csstype.*
import data.screening.model.glim.GlimBMIScoreOption
import data.screening.model.glim.GlimQuestions
import data.screening.model.glim.GlimResult
import data.screening.model.sacrf.SarcScoreOption
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.*
import pages.common.components.inputBox.SimpleInputComponent
import pages.common.components.labelValueInBox.LabelValueInBoxComponent
import pages.common.components.questionAnswerSelector.IAnswer
import pages.common.components.questionAnswerSelector.IQuestion
import pages.common.components.questionAnswerSelector.QuestionAnswerSelectorComponent
import pages.common.components.toggle.ToggleComponent
import pages.screening.ScreeningMethodProps
import pages.screening.components.ScreeningScoreComponent
import react.*
import util.FirebaseWrapper

class GlimScreeningPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()
    val component = FC<ScreeningMethodProps> { props ->

        val theme by useContext(ThemeContext)
        val patientInfo = props.patientInfo

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_GLIM_METHOD)
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }
            // illness severity
            Paper {
                sx {
                    card()
                    marginTop = 24.px
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.glimPhenotypicCriteriaLabel.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }

                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                ToggleComponent {
                    i18n4k = props.i18n4k
                    isToggled = props.patientInfo.glim.randomDecreaseBMI
                    text = Translation.glimPhenotypicQuestion1.invoke(props.i18n4k.locale)
                    subtitle = Translation.glimPhenotypicQuestion1Hint.invoke(props.i18n4k.locale)
                    onToggleChanged = {
                        val glimInfo = props.patientInfo.glim.copy(
                            randomDecreaseBMI = it == true
                        )
                        props.onPatientInfoChanged.invoke(
                            props.patientInfo.copy(
                                glim = glimInfo
                            )
                        )
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }

                val questionBMI = GlimQuestions.glimBmiQuestion

                QuestionAnswerSelectorComponent {
                    question = questionBMI
                    i18n4k = props.i18n4k
                    selectedAnswer = getSelectedAnswer(props.patientInfo.glim.getBMIInfoScore(patientInfo), questionBMI)
                    onAnswerSelected = { answer ->

                        if (answer != null) {

                            val selectedScore = GlimBMIScoreOption.values().firstOrNull { it.id == answer.id }
                                ?: GlimBMIScoreOption.ANSWER_NONE
                            val glimInfo = props.patientInfo.glim.copy(bmiInfoScoreByUser = selectedScore)


                            props.onPatientInfoChanged.invoke(
                                props.patientInfo.copy(
                                    glim = glimInfo
                                )
                            )
                        }
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }

                ToggleComponent {
                    i18n4k = props.i18n4k
                    isToggled = props.patientInfo.glim.getFFMI(patientInfo)
                    text = Translation.glimPhenotypicQuestion2.invoke(props.i18n4k.locale)
                    subtitle = Translation.glimPhenotypicQuestion2Subtitle.invoke(props.i18n4k.locale)
                    onToggleChanged = {
                        val glimInfo = props.patientInfo.glim.copy(
                            ffmiByUser = it
                        )
                        props.onPatientInfoChanged.invoke(
                            props.patientInfo.copy(
                                glim = glimInfo
                            )
                        )
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceAround
                        alignItems = AlignItems.center
                        marginLeft = 24.px
                        marginRight = 24.px
                        marginTop = 16.px
                        paddingBottom = 40.px
                        paddingTop = 0.px
                    }

                    SimpleInputComponent {
                        val heightMeter = (props.patientInfo.heightCm ?: 0) / 100.0
                        val ffmiPercentage = props.patientInfo.glim.getFfmiPercentage(patientInfo)

                        inputChangeCallback = {
                            val updatedGlim =
                                props.patientInfo.glim.copy(ffmiValue = it.toFloatOrNull(), ffmiByUser = null)
                            props.onPatientInfoChanged.invoke(
                                props.patientInfo.copy(glim = updatedGlim)
                            )
                        }
                        isFlexGrowEnabled = true
                        hint = Translation.glimFFMI.invoke(props.i18n4k.locale)
                        unit = "kg / ($heightMeter)² = ${ffmiPercentage?.toString() ?: "?"} %"
                        title = Translation.glimFFMIHint.invoke(props.i18n4k.locale)
                        value = props.patientInfo.glim.getFfmiValue()?.toString() ?: ""
                    }
                }

                Typography {
                    +Translation.glimPhenotypicQuestion2Hint.invoke(props.i18n4k.locale)
                    sx {
                        marginLeft = 24.px
                        marginRight = 24.px
                        fontSize = 14.px
                        color = theme.palette.text.secondary
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }
                LabelValueInBoxComponent {
                    label = Translation.glimPhenotypicScore.invoke(props.i18n4k.locale)
                    value = props.patientInfo.glim.getPhenotypicScore(patientInfo).toString()
                    color = Color("#64CCC9")
                }
            }

            //ethology
            Paper {
                sx {
                    card()
                    marginTop = 24.px
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.glimEthologyCriteriaLabel.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }
                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                GlimQuestions.glimEthologicQuestions.forEachIndexed { index, question ->

                    if (index > 0) {
                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }
                    }

                    ToggleComponent {
                        i18n4k = props.i18n4k
                        isToggled = when (index) {
                            0 -> props.patientInfo.glim.reducedIntakeLastWeek
                            1 -> props.patientInfo.glim.patientAcuteIllness
                            2 -> props.patientInfo.glim.patientChronicalMalabsorption
                            else -> false
                        }
                        text = question.title.invoke(props.i18n4k.locale)
                        onToggleChanged = {
                            val glimInfo = when (index) {
                                0 -> props.patientInfo.glim.copy(
                                    reducedIntakeLastWeek = it == true
                                )
                                1 -> props.patientInfo.glim.copy(
                                    patientAcuteIllness = it == true
                                )
                                2 -> props.patientInfo.glim.copy(
                                    patientChronicalMalabsorption = it == true
                                )
                                else -> {
                                    null
                                }
                            }
                            glimInfo?.let {
                                props.onPatientInfoChanged.invoke(
                                    props.patientInfo.copy(
                                        glim = glimInfo
                                    )
                                )
                            }
                        }
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }

                LabelValueInBoxComponent {
                    label = Translation.glimEthologyScore.invoke(props.i18n4k.locale)
                    value = props.patientInfo.glim.getEthologyScore().toString()
                    color = Color("#64CCC9")
                }
            }

            ScreeningScoreComponent {
                i18n4k = props.i18n4k
                title = Translation.glimScoreTitle
                results = GlimResult.results
                risklevel = props.patientInfo.glim.getRiskLevel(props.patientInfo)
                score = props.patientInfo.glim.getTotal(props.patientInfo)
                pointsText = Translation.screeningScorePoints
                showPoints = props.country.uiSettings.showScorePoints
            }

            // continue button
            Button {
                +Translation.mustContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }

    }
}

private fun getSelectedAnswer(score: GlimBMIScoreOption, question: IQuestion): IAnswer? {
    return question.answers.firstOrNull { it.id == score.id }
}
