package pages.common.components.buttonGroupPicker

import ThemeContext
import csstype.*
import emotion.react.css
import mui.material.*
import mui.system.sx
import pages.common.normalFontSize
import react.*

val ButtonGroupPickerComponent = FC<ButtonGroupPickerComponentProps> { props ->
    val theme by useContext(ThemeContext)

    ButtonGroup {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.row
            width = 100.pct
        }

        props.items.forEachIndexed { index, item ->
            Button {
                disabled = props.selectedItem == item
                variant = if (props.selectedItem == item) ButtonVariant.contained else ButtonVariant.outlined
                color = ButtonColor.primary
                onClick = {
                    props.onSelectionChanged.invoke(item)
                }
                sx {
                    if (index == 0) {
                        borderTopLeftRadius = 4.px
                        borderBottomLeftRadius = 4.px
                        borderTopRightRadius = 0.px
                        borderBottomRightRadius = 0.px
                    } else if (index == props.items.size - 1) {
                        borderTopLeftRadius = 0.px
                        borderBottomLeftRadius = 0.px
                        borderTopRightRadius = 4.px
                        borderBottomRightRadius = 4.px
                    }
                    borderRadius = 0.px
                    flexGrow = number(1.0)
                    height = 50.px
                    fontWeight = integer(600)
                    lineHeight = 16.px
                    textTransform = "none".unsafeCast<TextTransform>()
                    hover {
                        disabled {
                            color =
                                if (item == props.selectedItem) theme.palette.primary.main else Color("#BFE6F7")
                            if (item == props.selectedItem) {
                                backgroundColor = Color("#DEF3FA")
                            }
                        }
                    }
                    enabled {
                        color =
                            if (item == props.selectedItem) theme.palette.primary.main else Color("#BFE6F7")
                        if (item == props.selectedItem) {
                            backgroundColor = Color("#DEF3FA")
                        }
                    }
                    disabled {
                        color =
                            if (item == props.selectedItem) theme.palette.primary.main else Color("#BFE6F7")
                        if (item == props.selectedItem) {
                            backgroundColor = Color("#DEF3FA")
                        }
                    }
                }
                if (!item.iconName.isNullOrBlank()) {
                    Icon {
                        +(item.iconName ?: "")
                        sx {
                            fontSize = 20.px
                        }
                    }
                }
                Typography {
                    +item.getLabel(props.i18n4k.locale)
                    sx {
                        marginLeft = 4.px
                        normalFontSize()
                    }
                }
            }
        }
    }
}
