package data.screening.common

import data.screening.model.glim.GlimScore
import data.screening.model.must.MustScore
import data.screening.model.nrs.NrsScore
import data.screening.model.sacrf.SarcScore
import data.screening.model.snaq.SnaqScore
import data.screening.model.snaqrc.SnaqRCScore
import kotlinx.serialization.Serializable
import util.calculateAge
import util.serializable.DateSerializer
import kotlin.js.Date
import kotlin.math.pow

@Serializable
data class PatientInfo(
    val gender: Gender = Gender.male,
    @Serializable(with = DateSerializer::class) val birthday: Date? = null,
    val weightKg: Int? = null,
    val heightCm: Int? = null,
    val selectedPhysicalActivity: Int? = null,
    val selectedStressFactors: List<Int> = emptyList(),
    val stressPercents: Int = 0,
    val physicalActivityPercents: Int = 0,
    val sarcF: SarcScore = SarcScore(),
    val must: MustScore = MustScore(),
    val snaq: SnaqScore = SnaqScore(),
    val glim: GlimScore = GlimScore(),
    val snaqRC: SnaqRCScore = SnaqRCScore(),
    val nrs: NrsScore = NrsScore()
) {

    fun isFirstStepValid(): Boolean {
        return weightKg != null && heightCm != null && birthday != null
    }

    fun getBMI(): Double? {
        val height = heightCm ?: return null
        val weight = weightKg ?: return null
        return weight / (height / 100.0).pow(2.0)
    }

    fun getBMRTotal(): Double? {
        val brm = getBRM() ?: return null
        val brmStress = getBRMStress() ?: return null
        val physicalActivity = getPhysicalActivity() ?: return null
        return brm + brmStress + physicalActivity
    }

    fun getPhysicalActivity(): Double? {
        val brm = getBRM() ?: return null
        //val brmStress = getBRMStress() ?: return null
        return brm * (physicalActivityPercents / 100.0)
    }

    fun getBRMStress(): Double? {
        val brm = getBRM() ?: return null
        //   val p = getPhysicalActivity() ?: 0.0
        return brm * (stressPercents / 100.0)
//        return (b * (stressPercents / 100.0))
    }

    fun getBRM(): Double? {
        val height = heightCm ?: return null
        val weight = weightKg ?: return null
        val birthday = this.birthday ?: return null

        val age = birthday.calculateAge()
        return when (gender) {
            Gender.male -> {
                66.473 + (13.7516 * weight) + (5.0033 * height) - (6.755 * age)
            }
            Gender.female -> {
                655.0955 + (9.5634 * weight) + (1.8496 * height) - (4.6756 * age)
            }
            else -> throw Throwable("Not supported gender object.")
        }
    }

    fun getAge(): Int {
        return birthday?.calculateAge() ?: 0
    }

}