package pages.common.components.toggle

import ThemeContext
import csstype.*
import emotion.react.css
import localization.Translation
import mui.material.*
import mui.system.sx
import pages.common.normalFontSize
import react.*

val ToggleComponent = FC<ToggleComponentProps> { props ->
    val theme by useContext(ThemeContext)

    Button {
        variant = ButtonVariant.text
        onClick = {
            props.onToggleChanged(props.isToggled != true)
        }
        sx {
            marginLeft = 16.px
            marginRight = 16.px
            display = Display.flex
            fontSize = 14.px
            flexDirection = FlexDirection.row
            if (props.isFullWidth == true) {
                width = 100.pct
            }
        }
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.start
                flexGrow = number(1.0)
            }
            Typography {
                +props.text
                sx {
                    normalFontSize()
                    flexGrow = number(1.0)
                    textAlign = TextAlign.left
                    textTransform = "none".unsafeCast<TextTransform>()
                    marginRight = 24.px
                    justifyContent = JustifyContent.spaceBetween
                    fontWeight = integer(500)
                    color = if (props.isToggled == true) {
                        theme.palette.secondary.main
                    } else {
                        theme.palette.text.primary
                    }
                }
            }
            if (props.subtitle != null) {
                Typography {
                    +props.subtitle.orEmpty()
                    sx {
                        normalFontSize()
                        flexGrow = number(1.0)
                        textAlign = TextAlign.left
                        textTransform = "none".unsafeCast<TextTransform>()
                        marginRight = 24.px
                        justifyContent = JustifyContent.spaceBetween
                        fontWeight = integer(500)
                        color = if (props.isToggled == true) {
                            theme.palette.secondary.main
                        } else {
                            theme.palette.text.primary
                        }
                    }
                }
            }
        }
        Typography {
            +Translation.nrsAgePatientNegative(props.i18n4k.locale)
            sx {
                normalFontSize()
                textTransform = "none".unsafeCast<TextTransform>()
                color = theme.palette.secondary.main
                opacity = if (props.isToggled != true) {
                    number(1.0)
                } else {
                    number(0.25)
                }
            }
        }
        Switch {
            checked = props.isToggled == true
            onChange = { _, isToggled ->
                props.onToggleChanged(isToggled)
            }
            color = SwitchColor.secondary
        }

        Typography {
            +Translation.nrsAgePatientPositive(props.i18n4k.locale)
            sx {
                normalFontSize()
                textTransform = "none".unsafeCast<TextTransform>()
                color = theme.palette.secondary.main
                opacity = if (props.isToggled == true) {
                    number(1.0)
                } else {
                    number(0.25)
                }
            }
        }
    }
}
