package data.screening.model.nrs

import kotlinx.serialization.Serializable

/**
 * Start screening
 * part "Pocatecni screening" from the PDF
 */
@Serializable
data class StartScreening(
    val isBmiUnderLimit: Boolean,
    val isWeightLossInThreeMonths: Boolean,
    val isFoodIntakeLastWeek: Boolean,
    val isChronicIllness: Boolean
)