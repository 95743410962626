package data.countyPicker.repository

import data.countyPicker.model.Country
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow
import kotlinx.coroutines.launch

class CountryRepository: ICountryRepository {

    private val countries = MutableSharedFlow<List<Country>>(1)

    init {
        GlobalScope.launch {
            countries.emit(Country.countries)
        }
    }

    override suspend fun getAllCountries(): SharedFlow<List<Country>> {
        return countries
    }

}