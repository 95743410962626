package data.screening.model.result.request

import kotlinx.serialization.Serializable

@Serializable
data class SarcfPdfSettings(
    val strengthScore: Int,
    val helpWithWalkScore: Int,
    val standUpFromChairScore: Int,
    val stairsWalkScore: Int,
    val fallScore: Int
)