package pages.screening.nrs

import ThemeContext
import csstype.*
import data.screening.model.nrs.LastWeekNutrition
import data.screening.model.nrs.NrsIllnessQuestion
import data.screening.model.nrs.NrsResult
import data.screening.model.nrs.WeighLossTime
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.*
import pages.common.components.inputBox.InputBoxComponent
import pages.common.components.labelValueInBox.LabelValueInBoxComponent
import pages.common.components.riskProgressBar.RiskProgressBarComponent
import pages.common.components.toggle.ToggleComponent
import pages.screening.ScreeningMethodProps
import pages.screening.components.ScreeningScoreComponent
import react.*
import util.FirebaseWrapper

class NrsScreeningPage : KoinComponent {

    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<ScreeningMethodProps> { props ->
        val theme by useContext(ThemeContext)
        val weightPercentLoss = props.patientInfo.nrs.getWeightPercentLoss(props.patientInfo)

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_NRS_METHOD)
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }
            Paper {
                sx {
                    card()
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.nrsWeightLossTitle.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }
                Divider {
                    sx {
                        cardTitleDivider()
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceAround
                        alignItems = AlignItems.center
                        marginLeft = 24.px
                        marginRight = 24.px
                        paddingBottom = 40.px
                        paddingTop = 40.px
                    }

                    InputBoxComponent {
                        hint = Translation.weightUnit.invoke(props.i18n4k.locale)
                        isFlexGrowEnabled = false
                        title = Translation.mustNormalWeightTitle.invoke(props.i18n4k.locale)
                        value = props.patientInfo.nrs.previousWeight?.toString() ?: ""
                        inputChangeCallback = {
                            val nrsInfo = props.patientInfo.nrs.copy(
                                previousWeight = it.toIntOrNull()
                            )
                            props.onPatientInfoChanged(
                                props.patientInfo.copy(
                                    nrs = nrsInfo
                                )
                            )
                        }
                        unit = Translation.weightUnit.invoke(props.i18n4k.locale)
                    }

                    RiskProgressBarComponent {
                        text = Translation.nrsLoss.invoke(props.i18n4k.locale)
                        showButtons = false
                        value = weightPercentLoss
                        onValueChanged = {
                            // nothing
                        }
                    }
                }

                // weight loss
                Typography {
                    +Translation.nrsWeightLossQuestion.invoke(props.i18n4k.locale)
                    sx {
                        normalFontSize()
                        fontWeight = integer(500)
                        marginLeft = 24.px
                        marginRight = 24.px
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexWrap = FlexWrap.wrap
                        flexDirection = FlexDirection.row
                        marginLeft = 24.px
                        marginRight = 24.px
                        marginTop = 8.px
                    }
                    WeighLossTime.values().forEachIndexed { index, weighLossTime ->
                        Button {
                            id = "weight-loss-${index}"
                            +when (index) {
                                1 -> Translation.nrsWeightLoss2Months
                                2 -> Translation.nrsWeightLoss3Months
                                else -> Translation.nrsWeightLoss1Month
                            }.invoke(props.i18n4k.locale)
                            onClick = {
                                val nrsInfo = props.patientInfo.nrs.copy(
                                    weighLossTime = weighLossTime
                                )
                                props.onPatientInfoChanged(
                                    props.patientInfo.copy(
                                        nrs = nrsInfo
                                    )
                                )
                            }
                            color = ButtonColor.primary
                            sx {
                                textTransform = "none".unsafeCast<TextTransform>()
                                marginRight = 8.px
                                paddingLeft = 16.px
                                paddingRight = 16.px
                                if (props.patientInfo.nrs.weighLossTime == weighLossTime) {
                                    backgroundColor = theme.palette.primary.main
                                    color = NamedColor.white
                                } else {
                                    backgroundColor = Color("#DEF3FA")
                                    color = theme.palette.primary.main
                                }
                                hover {
                                    backgroundColor = Color("#009CDE")
                                    color = NamedColor.white
                                }
                            }
                        }
                    }
                }

                // food intake
                Typography {
                    +Translation.nrsFoodIntakeQuestion.invoke(props.i18n4k.locale)
                    sx {
                        normalFontSize()
                        fontWeight = integer(500)
                        marginLeft = 24.px
                        marginRight = 24.px
                        marginTop = 24.px
                    }
                }

                Box {
                    sx {
                        display = Display.flex
                        flexWrap = FlexWrap.wrap
                        flexDirection = FlexDirection.row
                        marginLeft = 24.px
                        marginRight = 24.px
                        marginTop = 8.px
                        marginBottom = 16.px
                    }
                    LastWeekNutrition.values().forEachIndexed { index, lastWeekNutrition ->
                        Button {
                            id = "weight-loss-${index}"
                            +when (index) {
                                1 -> "25 - 50 %"
                                2 -> "50 - 75 %"
                                3 -> "75 - 100 %"
                                else -> "0 - 25 %"
                            }
                            color = ButtonColor.primary
                            onClick = {
                                val nrsInfo = props.patientInfo.nrs.copy(
                                    lastWeekNutrition = lastWeekNutrition
                                )
                                props.onPatientInfoChanged(
                                    props.patientInfo.copy(
                                        nrs = nrsInfo
                                    )
                                )
                            }
                            sx {
                                textTransform = "none".unsafeCast<TextTransform>()
                                marginRight = 8.px
                                paddingLeft = 16.px
                                paddingRight = 16.px
                                if (props.patientInfo.nrs.lastWeekNutrition == lastWeekNutrition) {
                                    backgroundColor = theme.palette.primary.main
                                    color = NamedColor.white
                                } else {
                                    backgroundColor = Color("#DEF3FA")
                                    color = theme.palette.primary.main
                                }
                                hover {
                                    backgroundColor = Color("#009CDE")
                                    color = NamedColor.white
                                }
                            }
                        }
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                LabelValueInBoxComponent {
                    label = Translation.nrsScoreNutritionalStatus.invoke(props.i18n4k.locale)
                    value = props.patientInfo.nrs.getNutritionScoreNRS(props.patientInfo).toString()
                    color = Color("#64CCC9")
                }
            }

            // illness severity
            Paper {
                sx {
                    card()
                    marginTop = 24.px
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.nrsSeverityDiseaseTitle.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }

                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                NrsIllnessQuestion.questions.forEachIndexed { index, nrsIllnessQuestion ->

                    if (index > 0) {
                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }
                    }

                    ToggleComponent {
                        i18n4k = props.i18n4k
                        isToggled = when (index) {
                            0 -> props.patientInfo.nrs.complicationNRSMinorIllness.selected
                            1 -> props.patientInfo.nrs.complicationNRSChronicDisease.selected
                            2 -> props.patientInfo.nrs.complicationNRSLargerOperation.selected
                            3 -> props.patientInfo.nrs.complicationNRSJip.selected
                            else -> false
                        }
                        text = nrsIllnessQuestion.title.invoke(props.i18n4k.locale)
                        onToggleChanged = {
                            val nrsInfo = when (index) {
                                0 -> {
                                    val complicationInfo = props.patientInfo.nrs.complicationNRSMinorIllness.copy(
                                        selected = it == true
                                    )
                                    props.patientInfo.nrs.copy(
                                        complicationNRSMinorIllness = complicationInfo
                                    )
                                }
                                1 -> {
                                    val diseaseInfo = props.patientInfo.nrs.complicationNRSChronicDisease.copy(
                                        selected = it == true
                                    )
                                    props.patientInfo.nrs.copy(
                                        complicationNRSChronicDisease = diseaseInfo
                                    )
                                }
                                2 -> {
                                    val operationInfo = props.patientInfo.nrs.complicationNRSLargerOperation.copy(
                                        selected = it == true
                                    )
                                    props.patientInfo.nrs.copy(
                                        complicationNRSLargerOperation = operationInfo
                                    )
                                }
                                3 -> {
                                    val jipInfo = props.patientInfo.nrs.complicationNRSJip.copy(
                                        selected = it == true
                                    )
                                    props.patientInfo.nrs.copy(
                                        complicationNRSJip = jipInfo
                                    )
                                }
                                else -> null
                            }

                            nrsInfo?.let {
                                props.onPatientInfoChanged.invoke(
                                    props.patientInfo.copy(
                                        nrs = nrsInfo
                                    )
                                )
                            }

                        }
                    }
                    if (nrsIllnessQuestion.text != null) {
                        Typography {
                            +requireNotNull(nrsIllnessQuestion.text).toString(props.i18n4k.locale)
                            sx {
                                marginLeft = 24.px
                                marginRight = 24.px
                                fontSize = 14.px
                                color = theme.palette.text.secondary
                            }
                        }
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        width = 100.pct
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }

                LabelValueInBoxComponent {
                    label = Translation.nrsSeverityDiseaseScore.invoke(props.i18n4k.locale)
                    value = props.patientInfo.nrs.getIllnessScoreNRS().toString()
                    color = Color("#64CCC9")
                }
            }

            // patient age
            Paper {
                sx {
                    card()
                    marginTop = 24.px
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.column
                }
                Typography {
                    +Translation.nrsPatientAge.invoke(props.i18n4k.locale)
                    sx {
                        cardTitle()
                    }
                }
                Divider {
                    sx {
                        cardTitleDivider()
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                ToggleComponent {
                    i18n4k = props.i18n4k
                    isToggled = props.patientInfo.nrs.overAgeLimit
                    text = Translation.nrsPatientAgeQuestion.invoke(props.i18n4k.locale)
                    onToggleChanged = {
                        val nrsInfo = props.patientInfo.nrs.copy(
                            overAgeLimit = it == true
                        )
                        props.onPatientInfoChanged(
                            props.patientInfo.copy(
                                nrs = nrsInfo
                            )
                        )
                    }
                }

                Divider {
                    sx {
                        dashesDivider()
                        marginTop = 16.px
                        width = 100.pct
                        marginBottom = 16.px
                    }
                }

                LabelValueInBoxComponent {
                    label = Translation.nrsPatientAgeScore.invoke(props.i18n4k.locale)
                    value = props.patientInfo.nrs.getAgeOverScore().toString()
                    color = Color("#64CCC9")
                }
            }

            ScreeningScoreComponent {
                i18n4k = props.i18n4k
                title = Translation.nrsRiskTitle
                results = NrsResult.results
                risklevel = props.patientInfo.nrs.getRiskLevel(props.patientInfo)
                score = props.patientInfo.nrs.getTotal(props.patientInfo)
                pointsText = Translation.screeningScorePoints
                showPoints = props.country.uiSettings.showScorePoints
            }

            if (props.country.allowNrsSuggestion) {
                Card {
                    sx {
                        card()
                        width = 100.pct
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        marginTop = 24.px
                    }

                    Typography {
                        +Translation.mustRecommendedTreatmentTitle.invoke(props.i18n4k.locale)
                        sx {
                            cardTitle()
                        }
                    }

                    Divider {
                        sx {
                            cardTitleDivider()
                            width = 100.pct
                            marginBottom = 16.px
                        }
                    }

                    Typography {
                        +Translation.nrsTreatmentSteps.invoke(props.i18n4k.locale)
                        sx {
                            normalFontSize()
                            marginLeft = 16.px
                            marginRight = 16.px
                        }
                    }
                }
            }

            // continue button
            Button {
                +Translation.mustContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                disabled = !props.patientInfo.nrs.isValid()
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }
    }
}
