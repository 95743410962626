package data.screening.model.snaqrc

import localization.Translation

data class SnaqRCQuestions(
    val questions: LinkedHashMap<SnaqRCSection, List<SnaqRCQuestion>>
) {

    companion object {
        private val questionsPart1 = listOf(
            SnaqRCQuestion(Translation.snaqRCQuestion1, 1),
            SnaqRCQuestion(Translation.snaqRCQuestion2, 2)
        )

        private val questionsPart2 = listOf(
            SnaqRCQuestion(Translation.snaqRCQuestion3, 3),
            SnaqRCQuestion(Translation.snaqRCQuestion4, 4)
        )

        fun getQuestions(): SnaqRCQuestions {
            val map: LinkedHashMap<SnaqRCSection, List<SnaqRCQuestion>> = LinkedHashMap()
            map[SnaqRCSection.section1] = questionsPart1
            map[SnaqRCSection.section2] = questionsPart2
            return SnaqRCQuestions(map)
        }

    }
}


