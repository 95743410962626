package pages.screening.result

import ThemeContext
import csstype.*
import data.countyPicker.model.Country
import data.screening.model.ScreeningType
import data.screening.model.basicInfo.PhysicalActivity
import data.screening.model.basicInfo.StressFactor
import data.screening.model.glim.GlimResult
import data.screening.model.must.MustResult
import data.screening.model.nrs.LastWeekNutrition
import data.screening.model.nrs.NrsResult
import data.screening.model.nrs.WeighLossTime
import data.screening.model.result.request.PacientInfoRequest
import data.screening.model.result.request.SkPdfSettings
import data.screening.model.sacrf.SarcResult
import data.screening.model.snaq.SnaqResult
import data.screening.model.snaqrc.SnaqRCResult
import data.screening.repository.IScreeningRepository
import emotion.react.css
import external.html2canvas
import external.materialIcon64
import external.roboto64
import js.core.get
import jspdf.jsPDF
import jspdf.jsPDFOptions
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.*
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.w3c.dom.HTMLElement
import org.w3c.dom.get
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import pages.common.*
import pages.common.components.labelValue.LabelValueComponent
import pages.screening.ScreeningPage
import pages.screening.components.ScreeningScoreComponent
import react.FC
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import util.FirebaseWrapper
import util.format
import util.toFormattedString
import kotlin.js.Date

class ResultPage : KoinComponent {
    private val screeningRepository: IScreeningRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    private fun createJsPdf(isAutoPrint: Boolean): jsPDF {
        val jsPdf = jsPDF(object : jsPDFOptions {
            override var format: dynamic = "a4"  // a4 [595, 842] // scaled to fit content
            override var unit: String? = "pt"
            override var orientation: String? = "p"
        })
        jsPdf.addFileToVFS("roboto.ttf", roboto64)
        jsPdf.addFileToVFS("Material Icons Outlined-normal.ttf", materialIcon64)
        jsPdf.addFont(
            "roboto.ttf",
            "roboto",
            "normal"
        )
        jsPdf.addFont(
            "Material Icons Outlined-normal.ttf",
            "Material Icons Outlined",
            "normal"
        )

        jsPdf.setFont("roboto")

        if (isAutoPrint) {
            jsPdf.autoPrint()
        }

        return jsPdf
    }

    @OptIn(DelicateCoroutinesApi::class)
    private fun generateOldSkPdf(props: ResultPageProps, screeningType: ScreeningType) {
        GlobalScope.launch {
            try {
                val bmi = props.patientInfo.getBMI()
                val isExaminationBmi = (bmi != null && bmi < 19.0)
                val request: SkPdfSettings = when (screeningType) {
                    ScreeningType.SARCF -> SkPdfSettings(
                        weighLoss = null,
                        isReducedFoodIntake = null,
                        pacientInfo = PacientInfoRequest(
                            gender = props.patientInfo.gender.shortcut,
                            examination = isExaminationBmi
                        )
                    )
                    ScreeningType.MUST -> {
                        SkPdfSettings(
                            weighLoss = props.patientInfo.must.getWeightPercentLoss(props.patientInfo) > 10,
                            isReducedFoodIntake = null,
                            pacientInfo = PacientInfoRequest(
                                gender = props.patientInfo.gender.shortcut,
                                examination = isExaminationBmi
                            )
                        )
                    }
                    ScreeningType.NRS -> {
                        val isFoodIntake = if (props.patientInfo.nrs.lastWeekNutrition == LastWeekNutrition.HIGH) {
                            null // need to be specified by doctor we can above and less of 35%
                        } else {
                            props.patientInfo.nrs.lastWeekNutrition == LastWeekNutrition.LOWEST ||
                                    props.patientInfo.nrs.lastWeekNutrition == LastWeekNutrition.LOW
                        }

                        val percentLossLimit = if (props.patientInfo.getAge() >= 65) {
                            3
                        } else {
                            5
                        } // for age >= 65 = 3 otherwise 5 kg per month
                        val multiplyer = when (props.patientInfo.nrs.weighLossTime) {
                            WeighLossTime.TWO_MONTHS -> 2
                            WeighLossTime.THREE_MONTHS -> 3
                            else -> 1
                        }
                        val percentLoss = props.patientInfo.nrs.getWeightPercentLoss(props.patientInfo)
                        val isWeightLoss = percentLoss >= percentLossLimit * multiplyer

                        SkPdfSettings(
                            weighLoss = isWeightLoss,
                            isReducedFoodIntake = isFoodIntake,
                            pacientInfo = PacientInfoRequest(
                                gender = props.patientInfo.gender.shortcut,
                                examination = isExaminationBmi
                            )
                        )
                    }
                    else -> throw NotImplementedError("Not supported")
                }

                val byteArray = screeningRepository.generateOldSkApi(request)
                byteArray?.let {
                    openPdfInNewWindow(it)
                }
            } catch (t: Throwable) {
                t.printStackTrace()
            }
        }
    }

    private fun openPdfInNewWindow(byteArray: ByteArray) {
        val options = BlobPropertyBag(type = "application/pdf")
        val blob = Blob(blobParts = arrayOf(byteArray), options)
        val urlObject = URL.Companion.createObjectURL(blob)
        window.open(urlObject, "_blank")
    }

    private suspend fun generatePdf(
        props: ResultPageProps,
        screeningType: ScreeningType,
        jsPdf: jsPDF,
        skipRemote: Boolean
    ): Boolean {
        try {
            val byteArray =
                if (!skipRemote) {
                    screeningRepository.generatePdfApi(
                        screeningType,
                        props.country,
                        props.patientInfo
                    )
                } else {
                    null
                }
            if (byteArray == null) {
                window.asDynamic().html2canvas = html2canvas
                val htmlElement =
                    requireNotNull(window.document.getElementsByClassName("printRoot"))[0] as HTMLElement
                jsPdf.html(htmlElement).await()
                return true
            } else {
                openPdfInNewWindow(byteArray)
            }
        } catch (t: Throwable) {
            t.printStackTrace()
        }
        return false
    }


    val component = FC<ResultPageProps> { props ->
        val theme by useContext(ThemeContext)

        val screeningTypeParam = useParams()["screeningType"]?.lowercase()?.trim()
        val screeningType = when (screeningTypeParam) {
            ScreeningPage.SCREENING_NRS -> ScreeningType.NRS
            ScreeningPage.SCREENING_MUST -> ScreeningType.MUST
            ScreeningPage.SCREENING_SARCF -> ScreeningType.SARCF
            ScreeningPage.SCREENING_SNAQ -> ScreeningType.SNAQ
            ScreeningPage.SCREENING_SNAQ_RC -> ScreeningType.SNAQ_RC
            ScreeningPage.SCREENING_GLIM -> ScreeningType.GLIM
            else -> {
                throw Throwable("Unsupported screening type")
            }
        }

        var isGeneratingProtocol by useState(false)
        var isPrintingResults by useState(false)
        var isGeneratingResults by useState(false)

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_RESULT)
        }

        if (!props.patientInfo.isFirstStepValid()) {
            props.onInvalidResult.invoke()
            document.documentElement?.scrollTo(0.0, 0.0)
            return@FC
        }

        val isValid = when (screeningType) {
            ScreeningType.SNAQ -> props.patientInfo.snaq.isValid()
            ScreeningType.SNAQ_RC -> props.patientInfo.snaqRC.isValid()
            ScreeningType.SARCF -> props.patientInfo.sarcF.isValid()
            ScreeningType.MUST -> props.patientInfo.must.isValid()
            ScreeningType.NRS -> props.patientInfo.nrs.isValid()
            ScreeningType.GLIM -> props.patientInfo.glim.isValid()
        }

        val bmi = props.patientInfo.getBMI() ?: 0.0

        if (!isValid) {
            props.onInvalidResult.invoke()
            return@FC
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }
            Paper {
                sx {
                    card()
                }
                Box {
                    className = ClassName("printRoot")
                    sx {
                        width = 595.px
                        margin = 0.px
                        display = Display.flex
                        flexDirection = FlexDirection.column
                    }

                    Typography {
                        +Translation.screeningResultTitle.invoke(props.i18n4k.locale)
                        sx {
                            cardTitle()
                        }
                    }

                    Divider {
                        sx {
                            cardTitleDivider()
                            width = 100.pct
                        }
                    }

                    Box {
                        sx {
                            marginTop = 16.px
                            marginLeft = 24.px
                            marginRight = 24.px
                            display = Display.flex
                            flexDirection = FlexDirection.column
                        }

                        // bmi
                        LabelValueComponent {
                            label = Translation.bmiValue.invoke(props.i18n4k.locale)
                            value = "${requireNotNull(props.patientInfo.getBMI()).format(2)} kg/m2"
                        }

                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }

                        // score
                        LabelValueComponent {
                            label = Translation.scoreNrs.invoke(screeningType.label, props.i18n4k.locale)
                            value = when (screeningType) {
                                ScreeningType.MUST -> props.patientInfo.must.getTotal(props.patientInfo)
                                ScreeningType.SARCF -> props.patientInfo.sarcF.getTotal(props.patientInfo)
                                ScreeningType.NRS -> props.patientInfo.nrs.getTotal(props.patientInfo)
                                ScreeningType.SNAQ -> props.patientInfo.snaq.getTotal(props.patientInfo)
                                ScreeningType.SNAQ_RC -> props.patientInfo.snaqRC.getTotal(props.patientInfo)
                                ScreeningType.GLIM -> props.patientInfo.glim.getTotal(props.patientInfo)
                            }.toString()
                        }

                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }


                        // physical activity
                        val physicalActivity = PhysicalActivity.physicalActivities.firstOrNull {
                            it.id == props.patientInfo.selectedPhysicalActivity
                        } ?: PhysicalActivity.noPhysicalActivity


                        LabelValueComponent {
                            label = Translation.physicalActivity.invoke(props.i18n4k.locale)
                            value = physicalActivity.label.invoke(props.i18n4k.locale)
                        }

                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }

                        // stress factor
                        val stressFactors =
                            StressFactor.stressFactors.filter { props.patientInfo.selectedStressFactors.contains(it.id) }

                        if (stressFactors.isEmpty()) {
                            LabelValueComponent {
                                label = Translation.stressFactor.invoke(props.i18n4k.locale)
                                value = StressFactor.noStressFactor.label.invoke(props.i18n4k.locale)
                            }
                        } else {
                            stressFactors.forEachIndexed { index, stressFactor ->
                                if (index == 0) {
                                    LabelValueComponent {
                                        label = Translation.stressFactor.invoke(props.i18n4k.locale)
                                        value = stressFactor.label.invoke(props.i18n4k.locale)
                                    }
                                } else {
                                    LabelValueComponent {
                                        label = ""
                                        value = stressFactor.label.invoke(props.i18n4k.locale)
                                    }
                                }
                            }
                        }

                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }

                        // caloric equation
                        LabelValueComponent {
                            label = Translation.basicCaloricEquation.invoke(props.i18n4k.locale)
                            value = "${requireNotNull(props.patientInfo.getBMRTotal()).format(0)} kcal"
                        }

                        Divider {
                            sx {
                                dashesDivider()
                                marginTop = 16.px
                                marginBottom = 16.px
                            }
                        }

                        LabelValueComponent {
                            label = Translation.resultDateLabel.invoke(props.i18n4k.locale)
                            value = Date(Date.now()).toFormattedString()
                        }
                    }

                    Container {
                        sx {
                            marginTop = 24.px
                        }

                        ScreeningScoreComponent {

                            isPng = true
                            i18n4k = props.i18n4k
                            title = when (screeningType) {
                                ScreeningType.SARCF -> Translation.resultSarc
                                else -> Translation.resultGeneric
                            }
                            results = when (screeningType) {
                                ScreeningType.SARCF -> SarcResult.results
                                ScreeningType.SNAQ -> SnaqResult.results
                                ScreeningType.SNAQ_RC -> SnaqRCResult.results
                                ScreeningType.MUST -> MustResult.results
                                ScreeningType.NRS -> NrsResult.results
                                ScreeningType.GLIM -> GlimResult.results
                            }
                            risklevel = when (screeningType) {
                                ScreeningType.NRS -> props.patientInfo.nrs.getRiskLevel(props.patientInfo)
                                ScreeningType.MUST -> props.patientInfo.must.getRiskLevel(props.patientInfo)
                                ScreeningType.SARCF -> props.patientInfo.sarcF.getRiskLevel(props.patientInfo)
                                ScreeningType.SNAQ -> props.patientInfo.snaq.getRiskLevel(props.patientInfo)
                                ScreeningType.SNAQ_RC -> props.patientInfo.snaqRC.getRiskLevel(props.patientInfo)
                                ScreeningType.GLIM -> props.patientInfo.glim.getRiskLevel(props.patientInfo)
                            }
                            score = when (screeningType) {
                                ScreeningType.NRS -> props.patientInfo.nrs.getTotal(props.patientInfo)
                                ScreeningType.MUST -> props.patientInfo.must.getTotal(props.patientInfo)
                                ScreeningType.SARCF -> props.patientInfo.sarcF.getTotal(props.patientInfo)
                                ScreeningType.SNAQ -> props.patientInfo.snaq.getTotal(props.patientInfo)
                                ScreeningType.SNAQ_RC -> props.patientInfo.snaqRC.getTotal(props.patientInfo)
                                ScreeningType.GLIM -> props.patientInfo.glim.getTotal(props.patientInfo)
                            }
                            pointsText = Translation.screeningScorePoints
                            showPoints = props.country.uiSettings.showScorePoints
                        }
                    }
                }

                Button {
                    +Translation.finish.invoke(props.i18n4k.locale)
                    variant = ButtonVariant.contained
                    className = ClassName("finishButton")
                    onClick = {
                        firebaseWrapper.trackScanningFinish(
                            screeningType,
                            riskLevel = when (screeningType) {
                                ScreeningType.NRS -> props.patientInfo.nrs.getRiskLevel(props.patientInfo)
                                ScreeningType.MUST -> props.patientInfo.must.getRiskLevel(props.patientInfo)
                                ScreeningType.SARCF -> props.patientInfo.sarcF.getRiskLevel(props.patientInfo)
                                ScreeningType.SNAQ -> props.patientInfo.snaq.getRiskLevel(props.patientInfo)
                                ScreeningType.SNAQ_RC -> props.patientInfo.snaqRC.getRiskLevel(props.patientInfo)
                                ScreeningType.GLIM -> props.patientInfo.glim.getRiskLevel(props.patientInfo)
                            }
                        )
                        props.onFinish.invoke()
                    }
                    sx {
                        secondaryButton(theme)
                        textTransform = "none".unsafeCast<TextTransform>()
                        width = 326.px
                        margin = "48px auto 0px auto".unsafeCast<Margin>()
                    }
                }
            }

            Typography {
                +Translation.nextActions.invoke(props.i18n4k.locale)
                sx {
                    cardTitle()
                    color = NamedColor.white
                    marginTop = 24.px
                }
            }

            val canGenerateProtocol =
                (screeningType == ScreeningType.MUST && props.country.methodSettings.allowMustGenerate) ||
                        (screeningType == ScreeningType.NRS && props.country.methodSettings.allowNrsGenerate) ||
                        (screeningType == ScreeningType.SNAQ && props.country.methodSettings.allowSnaqGenerate) ||
                        (screeningType == ScreeningType.SNAQ_RC && props.country.methodSettings.allowSnaqRCGenerate) ||
                        (screeningType == ScreeningType.SARCF && props.country.methodSettings.allowSarcGenerate)

            // generate protocol
            if (canGenerateProtocol)
                Button {
                    +Translation.generateProtocol.invoke(props.i18n4k.locale)
                    onClick = {
                        GlobalScope.launch(CoroutineExceptionHandler { _, _ ->
                            isGeneratingProtocol = false
                        }) {
                            isGeneratingProtocol = true
                            val jsPdf = createJsPdf(false)
                            if (props.country != Country.countrySK) {
                                if (generatePdf(
                                        props = props,
                                        screeningType = screeningType,
                                        jsPdf = jsPdf,
                                        skipRemote = false
                                    )
                                ) {
                                    jsPdf.output("dataurlnewwindow")
                                }
                            } else {
                                generateOldSkPdf(
                                    props = props,
                                    screeningType = screeningType
                                )
                            }
                            isGeneratingProtocol = false
                        }
                    }
                    disabled = isPrintingResults || isGeneratingProtocol || isGeneratingResults
                    sx {
                        secondaryButton(theme)
                        textTransform = "none".unsafeCast<TextTransform>()
                        width = 326.px
                    }

                    if (isGeneratingProtocol) {
                        CircularProgress {
                            variant = CircularProgressVariant.indeterminate
                            size = 24.px
                            sx {
                                buttonIcon()
                            }
                        }
                    } else {
                        Icon {
                            +"description"
                            className = ClassName("ignoreChildren")
                            sx {
                                buttonIcon()
                            }
                        }
                    }
                }

            if (props.country == Country.countrySK) {
                Button {
                    +Translation.generatePdf.invoke(props.i18n4k.locale)
                    onClick = {
                        GlobalScope.launch(CoroutineExceptionHandler { _, _ ->
                            isGeneratingResults = false
                        }) {
                            isGeneratingResults = true
                            val jsPdf = createJsPdf(false)
                            if (generatePdf(
                                    props = props,
                                    screeningType = screeningType,
                                    jsPdf = jsPdf,
                                    skipRemote = false
                                )
                            ) {
                                jsPdf.output("dataurlnewwindow")
                            }
                            isGeneratingResults = false
                        }
                    }
                    disabled = isPrintingResults || isGeneratingProtocol || isGeneratingResults
                    sx {
                        secondaryButton(theme)
                        marginTop = 16.px
                        textTransform = "none".unsafeCast<TextTransform>()
                        width = 326.px
                    }
                    if (isGeneratingResults) {
                        CircularProgress {
                            variant = CircularProgressVariant.indeterminate
                            size = 24.px
                            sx {
                                buttonIcon()
                            }
                        }
                    } else {
                        Icon {
                            +"description"
                            className = ClassName("ignoreChildren")
                            sx {
                                buttonIcon()
                            }
                        }
                    }
                }
            }

            // print pdf
            Button {
                +Translation.printResult.invoke(props.i18n4k.locale)
                onClick = {
                    GlobalScope.launch(CoroutineExceptionHandler { _, _ ->
                        isPrintingResults = false
                    }) {
                        isPrintingResults = true
                        val jsPdf = createJsPdf(true)
                        if (generatePdf(
                                props = props,
                                screeningType = screeningType,
                                jsPdf = jsPdf,
                                skipRemote = !canGenerateProtocol
                            )
                        ) {
                            jsPdf.output("dataurlnewwindow")
                        }
                        isPrintingResults = false
                    }
                }
                disabled = isPrintingResults || isGeneratingProtocol || isGeneratingResults
                sx {
                    secondaryButton(theme)
                    textTransform = "none".unsafeCast<TextTransform>()
                    marginTop = 16.px
                    width = 326.px
                }
                if (isPrintingResults) {
                    CircularProgress {
                        variant = CircularProgressVariant.indeterminate
                        size = 24.px
                        sx {
                            buttonIcon()
                        }
                    }
                } else {
                    Icon {
                        +"print"
                        sx {
                            buttonIcon()
                        }
                    }
                }
            }

            Typography {
                +Translation.resultTerms.invoke(props.i18n4k.locale)
                sx {
                    fontSize = 13.px
                    color = NamedColor.white
                    marginTop = 48.px
                    textAlign = TextAlign.center
                    maxWidth = 480.px
                }
            }
        }
    }
}
