package data.screening.model.nrs

import csstype.Color
import data.screening.model.IScreeningScoreResult
import data.screening.model.RiskLevel
import data.screening.model.must.MustResult
import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

data class NrsResult(
    override val riskLevel: RiskLevel,
    override val text: LocalizedString,
    override val icon: String,
    override val color: Color
) : IScreeningScoreResult {

    companion object {

        private val scoreNo = NrsResult(RiskLevel.NO_RISK, Translation.levelRiskNo, "info", Color("#64CCC9"))
        private val scoreLow = MustResult(RiskLevel.LOW, Translation.levelRiskLow, "check_circle", Color("#64CCC9"))
        private val scoreHigh = NrsResult(RiskLevel.HIGH, Translation.levelRiskHigh, "error_outline", Color("#B3175A"))
        val results = listOf(scoreNo, scoreLow, scoreHigh)
    }

}