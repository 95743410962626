package localization

import de.comahe.i18n4k.Locale
import de.comahe.i18n4k.messages.MessageBundle
import de.comahe.i18n4k.messages.providers.MessagesProvider
import de.comahe.i18n4k.strings.LocalizedString
import de.comahe.i18n4k.strings.LocalizedStringFactory1
import kotlin.Array
import kotlin.Int
import kotlin.String

/**
 * Massage constants for bundle 'Translation'. Generated by i18n4k.
 */
public object Translation : MessageBundle() {
  /**
   * Back to main page
   */
  public val backToMainPage: LocalizedString = getLocalizedString0(0)

  /**
   * Back to Questionnaire Selection
   */
  public val backToMethodSelection: LocalizedString = getLocalizedString0(1)

  /**
   * Back to Product Search
   */
  public val backToProductSearch: LocalizedString = getLocalizedString0(2)

  /**
   * Back
   */
  public val backToScreening: LocalizedString = getLocalizedString0(3)

  /**
   * Back to Simple Calorie Equation
   */
  public val backToSimpleCaloric: LocalizedString = getLocalizedString0(4)

  /**
   * Basic equation
   */
  public val baseOfCaloricEquation: LocalizedString = getLocalizedString0(5)

  /**
   * Simple caloric equation
   */
  public val basicCaloricEquation: LocalizedString = getLocalizedString0(6)

  /**
   * Basic patient information
   */
  public val basicPatientInfo: LocalizedString = getLocalizedString0(7)

  /**
   * Continue
   */
  public val basicPatientInfoContinue: LocalizedString = getLocalizedString0(8)

  /**
   * Date of birth
   */
  public val birthday: LocalizedString = getLocalizedString0(9)

  /**
   * kg/m2
   */
  public val bmiUnit: LocalizedString = getLocalizedString0(10)

  /**
   * BMI value
   */
  public val bmiValue: LocalizedString = getLocalizedString0(11)

  /**
   * Cancel
   */
  public val consentCookiesNegative: LocalizedString = getLocalizedString0(12)

  /**
   * I agree
   */
  public val consentCookiesPositive: LocalizedString = getLocalizedString0(13)

  /**
   * Our website uses cookies to help improve it. By using our website, you agree to our use of
   * cookies and to our privacy statement.
   */
  public val consentCookiesText: LocalizedString = getLocalizedString0(14)

  /**
   * Cookies policy
   */
  public val consentCookiesTitle: LocalizedString = getLocalizedString0(15)

  /**
   * I am a healthcare professional
   */
  public val countryPickerCheckBoxCondition: LocalizedString = getLocalizedString0(16)

  /**
   * general conditions for using the application.
   */
  public val countryPickerCondition: LocalizedString = getLocalizedString0(17)

  /**
   * By clicking continue, you agree and confirm
   */
  public val countryPickerConditionMessage: LocalizedString = getLocalizedString0(18)

  /**
   * Continue
   */
  public val countryPickerContinue: LocalizedString = getLocalizedString0(19)

  /**
   * Select the country of activity please
   */
  public val countryPickerTitle: LocalizedString = getLocalizedString0(20)

  /**
   * Day
   */
  public val day: LocalizedString = getLocalizedString0(21)

  /**
   * Exit
   */
  public val finish: LocalizedString = getLocalizedString0(22)

  /**
   * Female
   */
  public val genderFemale: LocalizedString = getLocalizedString0(23)

  /**
   * Male
   */
  public val genderMale: LocalizedString = getLocalizedString0(24)

  /**
   * -
   */
  public val generatePdf: LocalizedString = getLocalizedString0(25)

  /**
   * Generate protocol
   */
  public val generateProtocol: LocalizedString = getLocalizedString0(26)

  /**
   * GLIM
   */
  public val glim: LocalizedString = getLocalizedString0(27)

  /**
   * >20 (0b)
   */
  public val glimBmiInfoAnswer1: LocalizedString = getLocalizedString0(28)

  /**
   * TRANSLATE=
   */
  public val glimBmiInfoAnswer2: LocalizedString = getLocalizedString0(29)

  /**
   * TRANSLATE=
   */
  public val glimBmiInfoAnswer3: LocalizedString = getLocalizedString0(30)

  /**
   * BMI (kg/m2)
   */
  public val glimBmiInfoQuestion: LocalizedString = getLocalizedString0(31)

  /**
   * TRANSLATE=
   */
  public val glimEthologyCriteriaLabel: LocalizedString = getLocalizedString0(32)

  /**
   * TRANSLATE=
   */
  public val glimEthologyQuestion1: LocalizedString = getLocalizedString0(33)

  /**
   * TRANSLATE=
   */
  public val glimEthologyQuestion2: LocalizedString = getLocalizedString0(34)

  /**
   * TRANSLATE=
   */
  public val glimEthologyQuestion3: LocalizedString = getLocalizedString0(35)

  /**
   * TRANSLATE=
   */
  public val glimEthologyScore: LocalizedString = getLocalizedString0(36)

  /**
   * TRANSLATE=
   */
  public val glimFFMI: LocalizedString = getLocalizedString0(37)

  /**
   * TRANSLATE=
   */
  public val glimFFMIHint: LocalizedString = getLocalizedString0(38)

  /**
   * TRANSLATE=
   */
  public val glimHighRisk: LocalizedString = getLocalizedString0(39)

  /**
   * TRANSLATE=
   */
  public val glimMidRisk: LocalizedString = getLocalizedString0(40)

  /**
   * TRANSLATE=
   */
  public val glimNoRisk: LocalizedString = getLocalizedString0(41)

  /**
   * TRANSLATE=Fenotypová kritéria
   */
  public val glimPhenotypicCriteriaLabel: LocalizedString = getLocalizedString0(42)

  /**
   * TRANSLATE=
   */
  public val glimPhenotypicQuestion1: LocalizedString = getLocalizedString0(43)

  /**
   * TRANSLATE=
   */
  public val glimPhenotypicQuestion1Hint: LocalizedString = getLocalizedString0(44)

  /**
   * TRANSLATE=
   */
  public val glimPhenotypicQuestion2: LocalizedString = getLocalizedString0(45)

  /**
   * TRANSLATE=
   */
  public val glimPhenotypicQuestion2Hint: LocalizedString = getLocalizedString0(46)

  /**
   * TRANSLATE=
   */
  public val glimPhenotypicQuestion2Subtitle: LocalizedString = getLocalizedString0(47)

  /**
   * TRANSLATE=
   */
  public val glimPhenotypicScore: LocalizedString = getLocalizedString0(48)

  /**
   * TRANSLATE=
   */
  public val glimResultInfoBoxTitle: LocalizedString = getLocalizedString0(49)

  /**
   * TRANSLATE=
   */
  public val glimScoreTitle: LocalizedString = getLocalizedString0(50)

  /**
   * TRANSLATE=Metoda GLIM
   */
  public val glimTitle: LocalizedString = getLocalizedString0(51)

  /**
   * Height
   */
  public val height: LocalizedString = getLocalizedString0(52)

  /**
   * cm
   */
  public val heightUnit: LocalizedString = getLocalizedString0(53)

  /**
   * High risk
   */
  public val levelRiskHigh: LocalizedString = getLocalizedString0(54)

  /**
   * Low risk
   */
  public val levelRiskLow: LocalizedString = getLocalizedString0(55)

  /**
   * Moderate risk
   */
  public val levelRiskMedium: LocalizedString = getLocalizedString0(56)

  /**
   * No risk
   */
  public val levelRiskNo: LocalizedString = getLocalizedString0(57)

  /**
   * Compendium
   */
  public val mainMenuCompendium: LocalizedString = getLocalizedString0(58)

  /**
   * © Abbott 2024
   */
  public val mainMenuCopyright: LocalizedString = getLocalizedString0(59)

  /**
   * Screening
   */
  public val mainMenuScreeningCard: LocalizedString = getLocalizedString0(60)

  /**
   * Search product
   */
  public val mainMenuSearchCard: LocalizedString = getLocalizedString0(61)

  /**
   * Setting
   */
  public val mainMenuSettings: LocalizedString = getLocalizedString0(62)

  /**
   * General conditions
   */
  public val mainMenuTerms: LocalizedString = getLocalizedString0(63)

  /**
   * Month
   */
  public val month: LocalizedString = getLocalizedString0(64)

  /**
   * 'MUST'
   */
  public val must: LocalizedString = getLocalizedString0(65)

  /**
   * Acute illness
   */
  public val mustAcuteDisease: LocalizedString = getLocalizedString0(66)

  /**
   * Acute disease score
   */
  public val mustAcuteDiseaseScore: LocalizedString = getLocalizedString0(67)

  /**
   * During illness,
   */
  public val mustAcuteDiseaseText: LocalizedString = getLocalizedString0(68)

  /**
   * is it likely that the patient will have no nutritional intake longer than 5 days?
   */
  public val mustAcuteDiseaseText2: LocalizedString = getLocalizedString0(69)

  /**
   * Continue
   */
  public val mustContinue: LocalizedString = getLocalizedString0(70)

  /**
   * during the past 3-6 months
   */
  public val mustNormalWeightText: LocalizedString = getLocalizedString0(71)

  /**
   * Normal body weight
   */
  public val mustNormalWeightTitle: LocalizedString = getLocalizedString0(72)

  /**
   * Recommended patient treatment
   */
  public val mustRecommendedTreatmentTitle: LocalizedString = getLocalizedString0(73)

  /**
   * Malnutrition risk according to 'MUST'
   */
  public val mustRiskTitle: LocalizedString = getLocalizedString0(74)

  /**
   * Weight loss score
   */
  public val mustScoreWeightLoss: LocalizedString = getLocalizedString0(75)

  /**
   * 'MUST'  method
   */
  public val mustTitle: LocalizedString = getLocalizedString0(76)

  /**
   * – Contact the nutrition team, initiate nutritional support or implement local guidelines*
   *
   *     – Set goals, improve and increase overall nutritional intake
   *
   *     – Monitor and review the care plan
   *
   *     In the hospital – weekly care
   *
   *     In a residential care home – monthly
   *
   *     In the home care setting – monthly
   *
   *     * Unless this is harmful or no benefit is expected from nutritional support, e.g. immediate
   * death.
   */
  public val mustTreatmentStepsHigh: LocalizedString = getLocalizedString0(77)

  /**
   * – Routine clinical care
   *
   *     Repeat screening:
   *
   *     In the hospital – weekly
   *
   *     In a residential care home – monthly
   *
   *     In the home care setting – annually for special groups, e.g. &gt; 75 years
   */
  public val mustTreatmentStepsLow: LocalizedString = getLocalizedString0(78)

  /**
   * – Record food intake during for 3 days
   *
   *    – If appropriate – slight concern, repeat the screening
   *
   *     In the hospital – weekly
   *
   *     In a residential care home – at least monthly
   *
   *     In the home care setting – at least every 2–3 months
   *
   *     If this is inappropriate – clinical concern, follow local guidelines, set goals, improve
   * and increase overall nutritional intake, regularly follow and review the care plan.
   */
  public val mustTreatmentStepsMid: LocalizedString = getLocalizedString0(79)

  /**
   * Unintentional weight loss
   */
  public val mustWeightLostTitle: LocalizedString = getLocalizedString0(80)

  /**
   * Additional recommended actions
   */
  public val nextActions: LocalizedString = getLocalizedString0(81)

  /**
   * NRS
   */
  public val nrs: LocalizedString = getLocalizedString0(82)

  /**
   * Is the patient &gt;70 years old?
   */
  public val nrsAgeHint: LocalizedString = getLocalizedString0(83)

  /**
   * No
   */
  public val nrsAgePatientNegative: LocalizedString = getLocalizedString0(84)

  /**
   * Yes
   */
  public val nrsAgePatientPositive: LocalizedString = getLocalizedString0(85)

  /**
   * E.g. complicated fracture (femoral neck fracture, femoral fracture, …) or chronic disease with
   * acute complications
   */
  public val nrsChornicDiseaseFractureText: LocalizedString = getLocalizedString0(86)

  /**
   * Chronic diseases and fractures
   */
  public val nrsChronicDiseaseFractureTitle: LocalizedString = getLocalizedString0(87)

  /**
   * Food intake during the past week as the percent fraction of the normal daily amount?
   */
  public val nrsFoodIntakeQuestion: LocalizedString = getLocalizedString0(88)

  /**
   * E.g. head injury, bone marrow transplant (APACHE > 10)
   */
  public val nrsJipText: LocalizedString = getLocalizedString0(89)

  /**
   * Patient at the ICU
   */
  public val nrsJipTitle: LocalizedString = getLocalizedString0(90)

  /**
   * E.g. major abdominal surgery, stroke, pneumonia, haematological cancer
   */
  public val nrsLargeOperationsText: LocalizedString = getLocalizedString0(91)

  /**
   * Major surgery and other health problems
   */
  public val nrsLargeOperationsTitle: LocalizedString = getLocalizedString0(92)

  /**
   * Weight loss
   */
  public val nrsLoss: LocalizedString = getLocalizedString0(93)

  /**
   * Minor disease
   */
  public val nrsMinorIllness: LocalizedString = getLocalizedString0(94)

  /**
   * Patient’s age
   */
  public val nrsPatientAge: LocalizedString = getLocalizedString0(95)

  /**
   * Is the patient > 70 years old?
   */
  public val nrsPatientAgeQuestion: LocalizedString = getLocalizedString0(96)

  /**
   * Patient’s age score
   */
  public val nrsPatientAgeScore: LocalizedString = getLocalizedString0(97)

  /**
   * Malnutrition risk according to NRS
   */
  public val nrsRiskTitle: LocalizedString = getLocalizedString0(98)

  /**
   * Nutritional status score
   */
  public val nrsScoreNutritionalStatus: LocalizedString = getLocalizedString0(99)

  /**
   * Acute health score
   */
  public val nrsSeverityDiseaseScore: LocalizedString = getLocalizedString0(100)

  /**
   * Disease severity
   */
  public val nrsSeverityDiseaseTitle: LocalizedString = getLocalizedString0(101)

  /**
   * NRS method
   */
  public val nrsTitle: LocalizedString = getLocalizedString0(102)

  /**
   * A nutritional plan identifies the optimal food composition and administration route based on
   * the patient’s observed nutritional status and demands and subsequently monitors the success of
   * this nutritional intervention.
   */
  public val nrsTreatmentSteps: LocalizedString = getLocalizedString0(103)

  /**
   * 1 month
   */
  public val nrsWeightLoss1Month: LocalizedString = getLocalizedString0(104)

  /**
   * 2 months
   */
  public val nrsWeightLoss2Months: LocalizedString = getLocalizedString0(105)

  /**
   * 3 months
   */
  public val nrsWeightLoss3Months: LocalizedString = getLocalizedString0(106)

  /**
   * Period during which the weight loss occurred?
   */
  public val nrsWeightLossQuestion: LocalizedString = getLocalizedString0(107)

  /**
   * Nutritional status
   */
  public val nrsWeightLossTitle: LocalizedString = getLocalizedString0(108)

  /**
   * Physical activity
   */
  public val physicalActivity: LocalizedString = getLocalizedString0(109)

  /**
   * -TRANSLATE=bedrest
   */
  public val physicalActivityBedrestPhysical: LocalizedString = getLocalizedString0(110)

  /**
   * TRANSLATE=ContinousMovement
   */
  public val physicalActivityContinousMovement: LocalizedString = getLocalizedString0(111)

  /**
   * -TRANSLATE=concious
   */
  public val physicalActivityLyingStillConcious: LocalizedString = getLocalizedString0(112)

  /**
   * TRANSLATE=sedated
   */
  public val physicalActivityLyingStillSedated: LocalizedString = getLocalizedString0(113)

  /**
   * TRANSLATE=MobilisingFrequently
   */
  public val physicalActivityMobilisingFrequently: LocalizedString = getLocalizedString0(114)

  /**
   * TRANSLATE=MobilisingOccasionally
   */
  public val physicalActivityMobilisingOccasionally: LocalizedString = getLocalizedString0(115)

  /**
   * TRANSLATE=MobilisingRegular
   */
  public val physicalActivityMobilisingRegular: LocalizedString = getLocalizedString0(116)

  /**
   * No physical activity
   */
  public val physicalActivityNo: LocalizedString = getLocalizedString0(117)

  /**
   * Select physical activity
   */
  public val physicalActivitySelect: LocalizedString = getLocalizedString0(118)

  /**
   * -TRANSLATE=sittingBed
   */
  public val physicalActivitySittingBed: LocalizedString = getLocalizedString0(119)

  /**
   * Print result
   */
  public val printResult: LocalizedString = getLocalizedString0(120)

  /**
   * Packaging
   */
  public val productDetailBtnPackage: LocalizedString = getLocalizedString0(121)

  /**
   * 100
   */
  public val productDetailBtnPer100: LocalizedString = getLocalizedString0(122)

  /**
   * Product description
   */
  public val productDetailDescriptionHeader: LocalizedString = getLocalizedString0(123)

  /**
   * Nutritional information
   */
  public val productDetailNutritionHeader: LocalizedString = getLocalizedString0(124)

  /**
   * Pack size
   */
  public val productDetailPackageSize: LocalizedString = getLocalizedString0(125)

  /**
   * Proteins in a packing unit
   */
  public val productDetailProteinsPack: LocalizedString = getLocalizedString0(126)

  /**
   * Product detail
   */
  public val productDetailTitle: LocalizedString = getLocalizedString0(127)

  /**
   * Caloric density
   */
  public val productFilterCaloricDensity: LocalizedString = getLocalizedString0(128)

  /**
   * Diabetes
   */
  public val productFilterDiabetes: LocalizedString = getLocalizedString0(129)

  /**
   * Fibre
   */
  public val productFilterFiber: LocalizedString = getLocalizedString0(130)

  /**
   * Inflammatory disease
   */
  public val productFilterInflammatoryIllness: LocalizedString = getLocalizedString0(131)

  /**
   * Proteins
   */
  public val productFilterProtein: LocalizedString = getLocalizedString0(132)

  /**
   * Filter products
   */
  public val productFilterTitle: LocalizedString = getLocalizedString0(133)

  /**
   * Density
   */
  public val productListItemDensity: LocalizedString = getLocalizedString0(134)

  /**
   * EPA
   */
  public val productListItemEpa: LocalizedString = getLocalizedString0(135)

  /**
   * Packaging
   */
  public val productListItemPackage: LocalizedString = getLocalizedString0(136)

  /**
   * Proteins in a packing unit
   */
  public val productListItemProteins: LocalizedString = getLocalizedString0(137)

  /**
   * Suitable products
   */
  public val productListTitle: LocalizedString = getLocalizedString0(138)

  /**
   * Search product
   */
  public val productSearchTitle: LocalizedString = getLocalizedString0(139)

  /**
   * Results and products
   */
  public val result: LocalizedString = getLocalizedString0(140)

  /**
   * Date
   */
  public val resultDateLabel: LocalizedString = getLocalizedString0(141)

  /**
   * Total malnutrition risk
   */
  public val resultGeneric: LocalizedString = getLocalizedString0(142)

  /**
   * Resulting caloric equation
   */
  public val resultOfCaloricEquation: LocalizedString = getLocalizedString0(143)

  /**
   * Risk of sarcopenia according to SARC-F
   */
  public val resultSarc: LocalizedString = getLocalizedString0(144)

  /**
   * The results and recommendations are not binding and it is up to the doctor to decide on the
   * intervention taking into account the patient’s health status.
   */
  public val resultTerms: LocalizedString = getLocalizedString0(145)

  /**
   * Risk rate
   */
  public val risk: LocalizedString = getLocalizedString0(146)

  /**
   * SARC-F
   */
  public val sarcf: LocalizedString = getLocalizedString0(147)

  /**
   * TRANSLATE=Zpět na Výběr dotazníku
   */
  public val sarcfBack: LocalizedString = getLocalizedString0(148)

  /**
   * Continue
   */
  public val sarcfContinue: LocalizedString = getLocalizedString0(149)

  /**
   * Very/I can’t without help
   */
  public val sarcfFallsGettingUpCharHigh: LocalizedString = getLocalizedString0(150)

  /**
   * Not at all
   */
  public val sarcfFallsGettingUpCharLow: LocalizedString = getLocalizedString0(151)

  /**
   * A little
   */
  public val sarcfFallsGettingUpCharMid: LocalizedString = getLocalizedString0(152)

  /**
   * How difficult is it for you to move from the chair to the bed?
   */
  public val sarcfFallsGettingUpCharQuestion: LocalizedString = getLocalizedString0(153)

  /**
   * Getting up from the chair
   */
  public val sarcfFallsGettingUpCharTitle: LocalizedString = getLocalizedString0(154)

  /**
   * 4 or more times
   */
  public val sarcfFallsHigh: LocalizedString = getLocalizedString0(155)

  /**
   * At all
   */
  public val sarcfFallsLow: LocalizedString = getLocalizedString0(156)

  /**
   * 1–3x
   */
  public val sarcfFallsMid: LocalizedString = getLocalizedString0(157)

  /**
   * How many times have you fallen down in the last year?
   */
  public val sarcfFallsQuestion: LocalizedString = getLocalizedString0(158)

  /**
   * Falls
   */
  public val sarcfFallsTitle: LocalizedString = getLocalizedString0(159)

  /**
   * Very/I can’t without help
   */
  public val sarcfHelpWalkHigh: LocalizedString = getLocalizedString0(160)

  /**
   * Not at all
   */
  public val sarcfHelpWalkLow: LocalizedString = getLocalizedString0(161)

  /**
   * A little
   */
  public val sarcfHelpWalkMid: LocalizedString = getLocalizedString0(162)

  /**
   * How difficult is it for you to cross the room?
   */
  public val sarcfHelpWalkQuestion: LocalizedString = getLocalizedString0(163)

  /**
   * Help during walking
   */
  public val sarcfHelpWalkTitle: LocalizedString = getLocalizedString0(164)

  /**
   * Very/I can’t lift
   */
  public val sarcfPowerHigh: LocalizedString = getLocalizedString0(165)

  /**
   * Not at all
   */
  public val sarcfPowerLow: LocalizedString = getLocalizedString0(166)

  /**
   * A little
   */
  public val sarcfPowerMid: LocalizedString = getLocalizedString0(167)

  /**
   * How difficult is it for you to lift and carry a 4.5 kg load?
   */
  public val sarcfPowerQuestion: LocalizedString = getLocalizedString0(168)

  /**
   * Strength
   */
  public val sarcfPowerTitle: LocalizedString = getLocalizedString0(169)

  /**
   * Risk of sarcopenia according to SARC-F
   */
  public val sarcfScreeningRiskTitle: LocalizedString = getLocalizedString0(170)

  /**
   * SARC-F method
   */
  public val sarcfTitle: LocalizedString = getLocalizedString0(171)

  /**
   * Very/I can’t
   */
  public val sarcfWalkingStairsHigh: LocalizedString = getLocalizedString0(172)

  /**
   * Not at all
   */
  public val sarcfWalkingStairsLow: LocalizedString = getLocalizedString0(173)

  /**
   * A little
   */
  public val sarcfWalkingStairsMid: LocalizedString = getLocalizedString0(174)

  /**
   * How difficult is it for you to walk up 10 stairs?
   */
  public val sarcfWalkingStairsQuestion: LocalizedString = getLocalizedString0(175)

  /**
   * Walking upstairs
   */
  public val sarcfWalkingStairsTitle: LocalizedString = getLocalizedString0(176)

  /**
   * {0} score
   */
  public val scoreNrs: LocalizedStringFactory1 = getLocalizedStringFactory1(177)

  /**
   * Screening results
   */
  public val screeningResultTitle: LocalizedString = getLocalizedString0(178)

  /**
   * b
   */
  public val screeningScorePoints: LocalizedString = getLocalizedString0(179)

  /**
   * Method
   */
  public val screeningTypeMethodLabel: LocalizedString = getLocalizedString0(180)

  /**
   * Questionnaire selection
   */
  public val screeningTypeTitle: LocalizedString = getLocalizedString0(181)

  /**
   * Send email
   */
  public val sendEmail: LocalizedString = getLocalizedString0(182)

  /**
   * SNAQ 65+
   */
  public val snaq: LocalizedString = getLocalizedString0(183)

  /**
   * Continue
   */
  public val snaqContinue: LocalizedString = getLocalizedString0(184)

  /**
   * TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?
   */
  public val snaqQuestion1: LocalizedString = getLocalizedString0(185)

  /**
   * TRANSLATE=Bovenarmomtrek < 25 cm?
   */
  public val snaqQuestion2: LocalizedString = getLocalizedString0(186)

  /**
   * TRANSLATE=Verminderde eetlust afgelopen week?
   */
  public val snaqQuestion3: LocalizedString = getLocalizedString0(187)

  /**
   * TRANSLATE=15 treden trap op en aflopen zonder te rusten?
   */
  public val snaqQuestion4: LocalizedString = getLocalizedString0(188)

  /**
   * SNAQ RC
   */
  public val snaqRC: LocalizedString = getLocalizedString0(189)

  /**
   * TRANSLATE=Ander
   */
  public val snaqRCFormTitle2: LocalizedString = getLocalizedString0(190)

  /**
   * TRANSLATE=Ondervoed
   */
  public val snaqRCHighRisk: LocalizedString = getLocalizedString0(191)

  /**
   * <ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring
   * van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3
   * werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start
   * behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>
   */
  public val snaqRCHighRiskSuggestions: LocalizedString = getLocalizedString0(192)

  /**
   * TRANSLATE=Risico op ondervoeding
   */
  public val snaqRCMidRisk: LocalizedString = getLocalizedString0(193)

  /**
   * <ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt.
   * Brochure.</li><li>Globale monitoring van den inname.</li></ul>
   */
  public val snaqRCMidRiskSuggestions: LocalizedString = getLocalizedString0(194)

  /**
   * TRANSLATE=Niet ondervoed
   */
  public val snaqRCNoRisk: LocalizedString = getLocalizedString0(195)

  /**
   * <ul><li>geen actie</li></ul>
   */
  public val snaqRCNoRiskSuggestions: LocalizedString = getLocalizedString0(196)

  /**
   * TRANSLATE=Meer dan 6 kg in de laatste 6 maanden
   */
  public val snaqRCQuestion1: LocalizedString = getLocalizedString0(197)

  /**
   * TRANSLATE=Meer dan 3 kg in de afgelopen maand
   */
  public val snaqRCQuestion2: LocalizedString = getLocalizedString0(198)

  /**
   * TRANSLATE=Hebt u hulp van een ander nodig bij het eten?
   */
  public val snaqRCQuestion3: LocalizedString = getLocalizedString0(199)

  /**
   * TRANSLATE=Had u de afgelopen maand een verminderde eetlust?
   */
  public val snaqRCQuestion4: LocalizedString = getLocalizedString0(200)

  /**
   * TRANSLATE=Aanbevolen behandeling van de patiënt
   */
  public val snaqRCRecommendedTreatmentTitle: LocalizedString = getLocalizedString0(201)

  /**
   * TRANSLATE=Risico op ondervoeding volgens SNAQ RC
   */
  public val snaqRCScoreTitle: LocalizedString = getLocalizedString0(202)

  /**
   * TRANSLATE=SNAQ RC methode
   */
  public val snaqRCTitle: LocalizedString = getLocalizedString0(203)

  /**
   * TRANSLATE=Bent u onbedoeld afgevallen?
   */
  public val snaqRcFormTitle1: LocalizedString = getLocalizedString0(204)

  /**
   * TRANSLATE=Risico op ondervoeding volgens SNAQ 65+
   */
  public val snaqScoreTitle: LocalizedString = getLocalizedString0(205)

  /**
   * TRANSLATE=SNAQ 65+ methode
   */
  public val snaqTitle: LocalizedString = getLocalizedString0(206)

  /**
   * Stress factor
   */
  public val stressFactor: LocalizedString = getLocalizedString0(207)

  /**
   * Brain injury - acute (ventilation, sedation)
   */
  public val stressFactorBrainInjuryAcute: LocalizedString = getLocalizedString0(208)

  /**
   * Brain injury - recovering
   */
  public val stressFactorBrainInjuryRecovery: LocalizedString = getLocalizedString0(209)

  /**
   * COPD
   */
  public val stressFactorCOPD: LocalizedString = getLocalizedString0(210)

  /**
   * Select stress factor
   */
  public val stressFactorChoose: LocalizedString = getLocalizedString0(211)

  /**
   * Continue
   */
  public val stressFactorContinue: LocalizedString = getLocalizedString0(212)

  /**
   * Haemorrhagic stroke
   */
  public val stressFactorHemorrhagicStroke: LocalizedString = getLocalizedString0(213)

  /**
   * High stress factor
   */
  public val stressFactorHigh: LocalizedString = getLocalizedString0(214)

  /**
   * Infection
   */
  public val stressFactorInfection: LocalizedString = getLocalizedString0(215)

  /**
   * IBD
   */
  public val stressFactorInflammatoryBowelDisease: LocalizedString = getLocalizedString0(216)

  /**
   * ICU - septic condition
   */
  public val stressFactorIntensiveCareSeptic: LocalizedString = getLocalizedString0(217)

  /**
   * ICU - on ventilation
   */
  public val stressFactorIntensiveCareVentilated: LocalizedString = getLocalizedString0(218)

  /**
   * Ischaemic stroke
   */
  public val stressFactorIschemicStroke: LocalizedString = getLocalizedString0(219)

  /**
   * Leukaemia
   */
  public val stressFactorLeukaemia: LocalizedString = getLocalizedString0(220)

  /**
   * Low stress factor
   */
  public val stressFactorLow: LocalizedString = getLocalizedString0(221)

  /**
   * Lymphoma
   */
  public val stressFactorLymphoma: LocalizedString = getLocalizedString0(222)

  /**
   * Medium stress factor
   */
  public val stressFactorMid: LocalizedString = getLocalizedString0(223)

  /**
   * No stress factor
   */
  public val stressFactorNo: LocalizedString = getLocalizedString0(224)

  /**
   * Acute pancreatitis
   */
  public val stressFactorPancreatitisAcute: LocalizedString = getLocalizedString0(225)

  /**
   * Chronic pancreatitis
   */
  public val stressFactorPancreatitisChronic: LocalizedString = getLocalizedString0(226)

  /**
   * Pancreatitis - Sepsis/Abscess
   */
  public val stressFactorPancreatitisSepsisAbscess: LocalizedString = getLocalizedString0(227)

  /**
   * Solid tumour
   */
  public val stressFactorSolidTumours: LocalizedString = getLocalizedString0(228)

  /**
   * Surgery - complicated
   */
  public val stressFactorSurgeryComplicated: LocalizedString = getLocalizedString0(229)

  /**
   * Surgery - non-complicated
   */
  public val stressFactorSurgeryUncomplicated: LocalizedString = getLocalizedString0(230)

  /**
   * Transplantation
   */
  public val stressFactorTransplantation: LocalizedString = getLocalizedString0(231)

  /**
   * Continue
   */
  public val termsContinue: LocalizedString = getLocalizedString0(232)

  /**
   * Body weight
   */
  public val weight: LocalizedString = getLocalizedString0(233)

  /**
   * kg
   */
  public val weightUnit: LocalizedString = getLocalizedString0(234)

  /**
   * Year
   */
  public val year: LocalizedString = getLocalizedString0(235)

  init {
    registerTranslation(Translation_cs)
    registerTranslation(Translation_sk)
    registerTranslation(Translation_el)
    registerTranslation(Translation_en)
    registerTranslation(Translation_hr)
    registerTranslation(Translation_nl)
    registerTranslation(Translation_sl)
    registerTranslation(Translation_de__rCH)
    registerTranslation(Translation_fr__rCH)
    registerTranslation(Translation_it__rCH)
  }
}

/**
 * Translation of message bundle 'Translation' for locale 'cs'. Generated by i18n4k.
 */
private object Translation_cs : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Zpět na úvodní stránku",
      "Zpět na výběr dotazníku",
      "Zpět na vyhledávání produktů",
      "Zpět",
      "Zpět na jednoduchá kalorická rovnice",
      "Základ rovnice",
      "Jednoduchá kalorická rovnice (Harris-Benedict)",
      "Základní údaje pacienta",
      "Pokračovat",
      "datum narození",
      "kg/m2",
      "Hodnota BMI",
      "Zrušit",
      "Souhlasím",
      "Naše webové stránky používají ke svému vylepšení soubory cookie. Používáním našich webových stránek souhlasíte s používáním souborů cookie a s naším prohlášením o ochraně osobních údajů.",
      "Cookies policy",
      "Jsem profesionál v oblasti zdravotní péče",
      "všeobecné podmínky k používání aplikace.",
      "Kliknutím na pokračovat souhlasíte a potvrzujete",
      "Pokračovat",
      "Vyberte prosím zemi působnosti",
      "Den",
      "Ukončit",
      "Žena",
      "Muž",
      "-",
      "Generovat protokol",
      "GLIM",
      ">20 (0b)",
      "<20 při věku <70 let (1b)",
      "<22 při věku >70 let (1b)",
      "BMI pacienta(kg/m2)",
      "Etologická kritéria",
      "Měl pacient v posledním týdnu snížený nutriční příjem?",
      "Má pacient akutní onemocnění, zranění nebo chronické zánětlivé onemocnění?",
      "Má pacient chronickou gastrointestinální malabsorpci?",
      "Skóre etologických kritérií",
      "FFMI",
      "FFMI pacienta",
      "Pacient je malnutriční",
      "Pacient je v riziku malnutrice",
      "Bez rizika malnutrice",
      "Fenotypová kritéria",
      "Je přítomné náhodné snížení BMI (body mass index)?",
      "(> 5% v posledních 6 měsících)",
      "Snížený index netukové tělesné hmoty (FFMI)?",
      "<15kg/m² u ženy, <17kg/m² u muže",
      " Vyplňte dle bia váhy",
      "Skóre fenotypových kritérií",
      "Riziko malnutrice podle GLIM",
      "Riziko malnutrice podle GLIM",
      "Metoda GLIM",
      "Výška",
      "cm",
      "Vysoké riziko",
      "Nízké riziko",
      "Střední riziko",
      "Žádné riziko",
      "Kompendium",
      "© Abbott 2024",
      "Screening",
      "Vyhledat produkt",
      "Nastavení",
      "Všeobecné podmínky",
      "Měsíc",
      "'MUST'",
      "Akutní nemoc",
      "Skóre akutní nemoci",
      "V případě nemoci, je pravděpodobnost,",
      "že pacient nebude mít nutriční příjem > 5 dní?",
      "Pokračovat",
      "v minulých 3–6 měsících",
      "Běžná hmotnost",
      "Doporučené zacházení s pacientem",
      "Riziko malnutrice podle 'MUST'",
      "Skóre úbytku hmotnosti",
      "Metoda 'MUST'",
      "<ul><li>Kontaktujte nutriční tým, iniciujte nutriční podporu nebo implementujte místní guidelines*</li><li>Stanovte cíle, zlepšujte a zvyšujte celkový nutriční příjem</li><li>Monitorujte a prozkoumejte plán péče<ul><li>V nemocnici – týdenní péče</li><li>V domově sociální péče – měsíčně</li><li>V domácí péči – měsíčně</li></ul></li></ul><br>* Není-li to škodlivé nebo se neočekává žádný užitek z nutriční podpory, například bezprostřední smrt.",
      "<ul><li>Rutinní klinická péče - Opakujte screening:<ul><li>V nemocnici – týdně</li><li>V domově sociální péče – měsíčně</li><li>V domácí péči – ročně pro speciální skupiny, například &gt; 75 let</li></ul></li></ul>",
      "<ul><li>Zdokumentujte příjem potravy po dobu 3 dnů</li><li>Je-li to vhodné – lehké obavy, opakujte screening<ul><li>V nemocnici – týdně</li><li>V domově sociální péče – nejméně měsíčně</li><li>V domácí péči – nejméně každé 2–3 měsíce</li></ul></li><li>Je-li to nepřiměřené – klinická obava, dodržujte lokální guidelines, stanovte cíle, zlepšujte a zvyšujte celkový nutriční příjem, pravidelně monitorujte a prozkoumejte plán péče.</li></ul>",
      "Neúmyslný úbytek hmotnosti",
      "Další doporučené akce",
      "NRS",
      "Je pacient starší než 70 let?",
      "Ne",
      "Ano",
      "Např. komplikovaná zlomenina (krčku stehenní kosti atd.) nebo chronická onemocnění s akutními komplikacemi",
      "Chronická onemocnění a zlomeniny",
      "Příjem stravy za poslední týden v procentech oproti standardnímu dennímu množství?",
      "Např. poranění hlavy, transplantace kostní dřeně (APACHE > 10)",
      "Pacient na oddělení JIP",
      "Např. větší operace v oblasti břicha, mozková příhoda, pneumónie, hematologické nádorové onemocnění",
      "Větší operace a další potíže",
      "Úbytek",
      "Menší onemocnění",
      "Věk pacienta",
      "Je pacient starší než 70 let?",
      "Skóre věku pacienta",
      "Riziko malnutrice podle NRS",
      "Skóre nutričního stavu",
      "Skóre aktuálního zdravotního stavu",
      "Závažnost onemocnění",
      "Metoda NRS",
      "Nutriční plán na základě hodnocení stavu výživy a odhadu nároků pacienta identifikuje optimální cestu podávání a složení výživy a následně monitorujte úspěšnost této nutriční intervence.",
      "1 měsíc",
      "2 měsíce",
      "3 měsíce",
      "Za jak dlouho nastal úbytek hmotnosti?",
      "Nutriční stav",
      "Fyzická aktivita",
      "Klid na lůžku (pohyb kolem lůžka)",
      "Nepřetržitý pohyb/pomalá chůze",
      "Nehybně ležící, při vědomí",
      "Ležící, v sedaci nebo umělém spánku",
      "Mobilní- častý pohyb po oddělení",
      "Mobilní- příležitostný pohyb po oddělení",
      "Mobilní + pravidelná intenzivní fyzioterapie",
      "Žádná fyzická aktivita",
      "Vyberte fyzickou aktivitu",
      "Sezení na posteli po delší dobu",
      "Vytisknout výsledek",
      "Balení",
      "100",
      "Popis produktu",
      "Nutriční informace",
      "Velikost balení",
      "Proteinů v balení",
      "Detail produktu",
      "Kalorická hustota",
      "Diabetes",
      "Vláknina",
      "Zánětlivé onemocnění",
      "Proteiny",
      "Filtrovat produkty",
      "Hustota",
      "EPA",
      "Balení",
      "Proteinů v balení",
      "Vhodné produkty",
      "Vyhledat produkt",
      "Výsledky",
      "Datum",
      "Celkové riziko malnutrice",
      "Výsledná kalorická rovnice",
      "Celkové riziko sarkopenie",
      "Výsledky a doporučení nejsou závazná a intervence je vždy na rozhodnutí lékaře vzhledem k individuálnímu stavu pacienta.",
      "riziko",
      "SARC-F",
      "Zpět na Výběr dotazníku",
      "Pokračovat",
      "Velmi/ bez pomoci nemohu",
      "Vůbec ne",
      "Trochu",
      "Jak namáhavé je pro Vás přemístění se ze židle nebo postele?",
      "Zvedání se ze židle",
      "4 a více krát",
      "Vůbec",
      "1–3x",
      "Kolikrát jste za poslední rok upadl/a?",
      "Pády",
      "Velmi/ bez pomoci nemohu",
      "Vůbec ne",
      "Trochu",
      "Jak namáhavé je pro Vás přejít místnost?",
      "Pomoc při chůzi",
      "Velmi/ neuzvednu",
      "Vůbec ne",
      "Trochu",
      "Jak namáhavé je pro Vás uzvednout a nést 4.5kg zátěž?",
      "Síla",
      "Celkové riziko sarkopenie",
      "Metoda SARC-F",
      "Velmi/ nevyjdu",
      "Vůbec ne",
      "Trochu",
      "Jak namáhavé je pro Vás vyjít 10 schodů?",
      "Chůze do schodů",
      "{0} skóre",
      "Výsledky screeningu",
      "b",
      "Metoda",
      "Výběr dotazníku",
      "Odeslat na email",
      "SNAQ 65+",
      "Pokračovat",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=SNAQ 65+ methode",
      "Vyberte stresový faktor",
      "Poranění mozku - akutní (ventilace, sedace)",
      "Poranění mozku- zotavující se",
      "CHOPN",
      "Vyberte stresový faktor",
      "Pokračovat",
      "Hemoragická cévní mozková příhoda",
      "Vysoký stresový faktor",
      "Infekce",
      "IBD",
      "JIP - septický stav",
      "JIP - na ventilaci",
      "Ischemická mozková příhoda",
      "Leukémie",
      "Nízký stresový faktor",
      "Lymfom",
      "Střední stresový faktor",
      "Žádný stresový faktor",
      "Akutní pankreatitida",
      "Chronická pankreatitida",
      "Pankreatitida - sepse/abces",
      "Pevný nádor",
      "Operace - komplikovaná",
      "Operace - nekomplikovaná",
      "Transplantace",
      "Pokračovat",
      "Hmotnost",
      "kg",
      "Rok")

  public override val locale: Locale = Locale("cs")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'sk'. Generated by i18n4k.
 */
private object Translation_sk : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Späť na úvodnú stránku",
      "Späť na výber dotazníka",
      "Späť na vyhľadávanie produktov",
      "Späť",
      "Späť na jednoduchá kalorická rovnica",
      "Základ rovnice",
      "Jednoduchá kalorická rovnica",
      "Základné údaje pacienta",
      "Pokračovat",
      "Dátum narodenia",
      "kg/m2",
      "Hodnota BMI",
      "Zrušiť",
      "Súhlasím",
      "Naše webové stránky používajú na svoje vylepšenie súbory cookie. Používaním našich webových stránok súhlasíte s používaním súborov cookie as naším vyhlásením o ochrane osobných údajov.",
      "Cookies policy",
      "Som profesionál v oblasti zdravotníctva",
      "všeobecné podmienky na používanie programu.",
      "Kliknutím na pokračovať súhlasíte a potvrdzujete",
      "Pokračovať",
      "Vyberte štát pôsobnosti",
      "Deň",
      "Ukončiť",
      "Žena",
      "Muž",
      "Generovať PDF výsledok",
      "Generovať protokol",
      "GLIM",
      ">20 (0b)",
      "<20 pri veku <70 let (1b)",
      "<22 pri veku >70 let (1b)",
      "BMI pacienta(kg/m2)",
      "Etologické kritériá",
      "Mal pacient v poslednom týždni znížený nutričný príjem?",
      "Má pacient akútne ochorenie, zranenie alebo chronické zápalové ochorenie?",
      "Má pacient chronickú gastrointestinálnu malabsorpciu?",
      "Skóre etologických kritérií",
      "FFMI",
      "FFMI pacienta",
      "Pacient je malnutričný",
      "Pacient je v riziku malnutrície",
      "Bez rizika malnutrície",
      "Fenotypové kritériá",
      """
      |Je prítomné náhodné zníženie BMI\
      |(body mass index)?
      """.trimMargin(),
      "(> 5% v posledných 6 mesiacoch)",
      "Znížený index netukovej telesnej hmoty (FFMI)?",
      "<15kg/m² u ženy, <17kg/m² u muža",
      "Vyplňte podľa bia váhy",
      "Skóre fenotypových kritérií",
      "Riziko malnutrície podľa GLIM",
      "Riziko malnutrície podľa GLIM",
      "Metóda GLIM",
      "Výška",
      "cm",
      "Vysoké riziko",
      "Nízke riziko",
      "Stredné riziko",
      "Žiadne riziko",
      "Kompendium",
      "© Abbott 2024",
      "Screening",
      "Vyhľadať produkt",
      "Nastavenia",
      "Všeobecné podmienky",
      "Mesiac",
      "'MUST'",
      "Akútne ochorenie",
      "Skóre akútného ochorenia",
      "V prípade ochorenia,",
      "je pravdepodobnosť, že pacient nebude mať nutričný príjem > 5 dní?",
      "Pokračovať",
      "v minulých 3–6 mesiacov",
      "bežná hmotnosť",
      "Odporúčané zaobchádzanie s pacientom",
      "Riziko malnutrície podľa 'MUST'",
      "Skóre straty hmotnosti",
      "Metóda 'MUST'",
      "<ul><li>Obráťte sa na nutričný tím, iniciujte nutričnú podporu alebo implementujte miestne guidelines*</li><li>Stanovte ciele, zlepšujte a zvyšujte celkový nutričný príjem</li><li>Monitorujte a preskúmajte plán starostlivosti<ul><li>V nemocnici - týždenná starostlivosť</li><li>V domove sociálnej starostlivosti - mesačne</li><li>V domácej starostlivosti - mesačne</li></ul></li></ul><br>* Pokiaľ to nie je škodlivé alebo sa neočakáva žiadny úžitok z nutričnej podpory, napr. bezprostredná smrť.</ul>",
      "<ul><li>Rutinná klinická starostlivosť - Opakujte skríning:<ul><li>V nemocnici - týždenne</li><li>V domove sociálnej starostlivosti - mesačne</li><li>V domácej starostlivosti - ročne pre špeciálne skupiny, napr.&gt; 75 rokov</li></ul></li></ul>",
      "<ul><li>Zdokumentujte príjem potravy po dobu 3 dní</li><li>Ak je to vhodné – ľahké obavy, opakujte skríning<ul><li>V nemocnici – týždenne</li><li>V domove sociálnej starostlivosti – najmenej mesačne</li><li>V domácej starostlivosti – najmenej každé 2 – 3 mesiace</li></ul></li><li>Ak je to neprimerané - klinická obava, dodržiavajte lokálné guidelines, stanovte ciele, zlepšujte a zvyšujte celkový nutričný príjem, pravidelne monitorujte a preskúmajte plán starostlivosti.</li></ul>",
      "Neúmyselná strata hmotnosti",
      "Ďalšie odporúčané akcie",
      "NRS",
      "Je pacient starší ako 70 rokov?",
      "Nie",
      "Áno",
      "Npr. komplikovaná zlomenina (krčku stehennej kosti atd.) alebo chronické ochorenie s akútnymi komplikáciami",
      "Chronické ochorenie a zlomeniny",
      "Príjem stravy za posledný týždeň v percentách oproti štandardnému dennému množstvu?",
      "Npr. poranenie hlavy, transplantácia kostnej drene (APACHE > 10)",
      "Pacient na oddelení JIS",
      "Npr. Väčšie operácie v oblasti brucha, mozgová príhoda, pneumónia, hematologické nádorové ochorenie",
      "Väčšie operácie a ďalšie problémy",
      "Strata",
      "Menšie ochorenia",
      "Vek pacienta",
      "Je pacient starší ako 70 rokov?",
      "Skóre veku pacienta",
      "Riziko malnutrície podľa NRS",
      "Skóre nutričného stavu",
      "Skóre aktuálného zdravotného stavu",
      "Závažnosť ochorenia",
      "Metóda NRS",
      "Nutričný plán na základe hodnotenia stavu výživy a odhadov nárokov pacienta identifikuje optimálnu cestu podávania a zloženia výživy a následne monitroruje úspešnosť tejto nutričnej intervencie.",
      "1 mesiac",
      "2 mesiace",
      "3 mesiace",
      "Za ako dlho nastala strata hmotnosti?",
      "Nutričný stav",
      "Fyzická aktivita",
      "Pokoj na lôžku (pohyb okolo lôžka)",
      "Nepretržitý pohyb / pomalá chôdza",
      "Nehybne ležiaci, pri vedomí",
      "Ležiaci, v sedácii alebo umelom spánku",
      "Mobilný- častý pohyb po oddelení",
      "Mobilný- príležitostný pohyb po oddelení",
      "Mobilný + pravidelná intenzívna fyzioterapia",
      "Žiadna fyzická aktivita",
      "Vyberte fyzickú aktivitu",
      "Sedenie na posteli po dlhšiu dobu",
      "Vytlačiť výsledok",
      "Balenie",
      "100",
      "Popis produktu",
      "Nutričné informácie",
      "Veľkosť balenia",
      "Proteíny v balení",
      "Detail produktu",
      "Kalorická hustota",
      "Diabetes",
      "Vláknina",
      "Zápalové ochorenia",
      "Proteíny",
      "Filtrovať produkt",
      "Hustota",
      "EPA",
      "Balenie",
      "Proteín v balení",
      "Vhodné produkty",
      "Vyhľadať produkt",
      "Výsledky",
      "Dátum",
      "Celkové riziko malnutrície",
      "Výsledná kalorická rovnica",
      "Celkové riziko sarkopénie",
      "Výsledky a doporučenia nie sú záväzné a intervencia je vždy na rozhodnutí lekára vzhľadom k individuálnemu stavu pacienta.",
      "riziko",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Pokračovať",
      "Veľmi/ bez pomoci nemôžem",
      "Vôbec nie",
      "Trochu",
      "Ako namáhavé je pre Vás premiestnenie sa zo stoličky alebo postele?",
      "Zdvíhanie sa zo stoličky",
      "4 a viac krát",
      "Vôbec",
      "1–3x",
      "Koľkokrát ste za posledný rok spadol/spadla?",
      "Pády",
      "Veľmi/ bez pomoci nemôžem",
      "Vôbec nie",
      "Trochu",
      "Ako namáhavé je pre Vás prejsť miestnosť?",
      "Pomoc pri chôdzi",
      "Veľmi/ Nezdvihnem",
      "Vôbec nie",
      "Trochu",
      "Ako namáhavé je pre Vás zdvihnúť a niesť 4.5kg záťaž?",
      "Sila",
      "Celkové riziko sarkopénie",
      "Metóda SARC-F",
      "Veľmi/ Nevýjdem",
      "Vôbec nie",
      "Trochu",
      "Ako namáhavé je pre Vás vyjsť 10 schodov?",
      "Chôdza do schodov",
      "{0} skóre",
      "Výsledky skríningu",
      "b",
      "Metóda",
      "Výber dotazníku",
      "Odoslať e-mail",
      "SNAQ 65+",
      "Pokračovať",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=SNAQ 65+ methode",
      "Stresový faktor",
      "Poranenie mozgu - akútne (ventilácia, sedácia)",
      "Poranenie mozgu – zotavujúci sa",
      "CHOCHP",
      "Vyberte stresový faktor",
      "Pokračovať",
      "Hemoragická cievna mozgová príhoda",
      "Vysoký stresový faktor",
      "Infekcia",
      "IBD",
      "JIS - septický stav",
      "JIS - na ventilácii",
      "Ischemická mozgová príhoda",
      "Leukémia",
      "Nízky stresový faktor",
      "Lymfóm",
      "Stredný stresový faktor",
      "Žiaden stresový faktor",
      "Akútna pankreatitída",
      "Chronická pankreatitída",
      "Pankreatitída – sepsa/absces",
      "Pevný nádor",
      "Operácia - komplikovaná",
      "Operácia - nekomplikovaná",
      "Transplantácia",
      "Pokračovať",
      "Hmotnosť",
      "kg",
      "Rok")

  public override val locale: Locale = Locale("sk")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'el'. Generated by i18n4k.
 */
private object Translation_el : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Επιστροφή στην κύρια σελίδα",
      "Επιστροφή στην επιλογή ερωτηματολογίων",
      "Επιστροφή στην Αναζήτηση προϊόντων",
      "πίσω",
      "Επιστροφή στην απλή εξίσωση θερμίδων",
      "Η βάση της εξίσωσης",
      "Απλή θερμιδική εξίσωση",
      "Βασικά δεδομένα ασθενούς",
      "Συνέχεια",
      "Ημερομηνία γέννησης",
      "kg/m2",
      "Τιμή του BMI",
      "Ματαίωση",
      "συμφωνώ",
      "Ο ιστότοπός μας χρησιμοποιεί cookies για τη βελτίωσή του. Χρησιμοποιώντας τον ιστότοπό μας, συμφωνείτε με τη χρήση των cookies και τη δήλωση απορρήτου.",
      "Cookies policy",
      "Είμαι επαγγελματίας στον τομέα της υγείας",
      "γενικοί όροι για τη χρήση της εφαρμογής.",
      "Κάνοντας κλικ στο Συνέχεια, συμφωνείτε και επιβεβαιώνετε",
      "Συνέχεια",
      "Παρακαλούμε επιλέξτε χώρα",
      "Ημέρα",
      "Τερματισμός",
      "Γυναίκα",
      "Ανδρας",
      "-",
      "Δημιουργήστε ένα αρχείο καταγραφής",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Ύψος",
      "cm",
      "Υψηλός κίνδυνος",
      "Χαμηλός κίνδυνος",
      "Μεσαίος κίνδυνος",
      "Κανένας κίνδυνος",
      "Επιτομή",
      "© Abbott 2024",
      "Screening",
      "Αναζήτηση προϊόντος",
      "Ρυθμίσεις",
      "Γενικοί όροι",
      "Μήνας",
      "'MUST'",
      "Οξεία ασθένεια",
      "Βαθμολογία οξείας ασθένεια",
      "Σε περίπτωση ασθένειας,",
      "είναι πιθανό ο ασθενής να μην έχει διατροφική πρόσληψη > 5 ημέρες;",
      "Συνέχεια",
      "τους τελευταίους 3–6 μήνες",
      "Φυσιολογικό βάρος",
      "Συνιστώμενη θεραπεία του ασθενούς",
      "Κίνδυνος υποσιτισμού 'MUST'",
      "Βαθμολογία απώλειας βάρους",
      "Μέθοδος 'MUST'",
      """
      |– Επικοινωνήστε με την ομάδα διατροφής, ξεκινήστε διατροφική υποστήριξη ή εφαρμόστε τοπικές οδηγίες *
      |
      |    – Θέστε στόχους, βελτιώστε και αυξήστε τη συνολική διατροφική πρόσληψη   – Παρακολούθηση και επανεξέταση του προγράμματος περίθαλψης
      |
      |    Στο νοσοκομείο - εβδομαδιαία φροντίδα
      |
      |    Σε ίδρυμα κοινωνικής μέριμνας - μηνιαία
      |
      |    Στην κατ \'οίκον φροντίδα - μηνιαία
      |
      |    * Εφόσον δεν είναι επιβλαβή ή δεν αναμένεται όφελος από τη διατροφική υποστήριξη, όπως για παράδειγμα ο άμεσος θάνατος.
      """.trimMargin(),
      """
      |– Τακτική κλινική φροντίδα
      |
      |    Επανάληψη screening:
      |
      |    Στο νοσοκομείο - εβδομαδιαία
      |
      |    Σε ίδρυμα κοινωνικής μέριμνας – μηνιαίως
      |
      |    Στην κατ \'οίκον φροντίδα - ετησίως για ειδικές ομάδες, για παράδειγμα &gt; 75 ετών
      """.trimMargin(),
      """
      |– Καταγράψτε την πρόσληψη τροφής για το χρονικό διάστημα 3 ημερών
      |
      |    – Εφόσον είναι ικανοποιητικό - ελαφριά ανησυχία, επαναλάβετε το sceening
      |
      | Στο νοσοκομείο - εβδομαδιαία
      |
      |    Σε ίδρυμα κοινωνικής μέριμνας - τουλάχιστον μηνιαίως
      |
      |    Εφόσον δεν είναι ικανοποιητικό - κλινική ανησυχία, ακολουθήστε τις τοπικές οδηγίες, θέστε στόχους, βελτιώστε και αυξήστε τη συνολική διατροφική πρόσληψη, παρακολουθείτε συστηματικά και ελέγχετε το πρόγραμμα περίθαλψης.
      """.trimMargin(),
      "Ακούσια απώλεια βάρους",
      "Άλλες προτεινόμενες ενέργειες",
      "NRS",
      "Η ηλικία του ασθενούς είναι άνω των 70 ετών;",
      "Οχι",
      "Ναί",
      "Π.χ. περίπλοκο κάταγμα (αυχένα, μηριαίο οστό, κλπ) ή χρόνιες ασθένειες με οξείες επιπλοκές",
      "Χρόνιες ασθένειες και κατάγματα",
      "Ποσοστό πρόσληψης τροφής για την τελευταία εβδομάδα σε σύγκριση με το κανονική ημερήσια ποσότητα;",
      "Π.χ. τραυματισμός στο κεφάλι, μεταμόσχευση μυελού των οστών (APACHE > 10)",
      "Ασθενής στη ΜΕΘ",
      "Π.χ. σημαντική χειρουργική επέμβαση στην κοιλιακή χώρα, εγκεφαλικό επεισόδιο, πνευμονία, αιματολογικός καρκίνος",
      "Σημαντικές χειρουργικές επεμβάσεις και άλλα προβλήματα",
      "Μείωση",
      "Ασήμαντες ασθένειες",
      "Η ηλικία του ασθενούς",
      "Η ηλικία του ασθενούς είναι άνω των 70 ετών;",
      "Βαθμολογία ηλικίας του ασθενού",
      "Κίνδυνος υποσιτισμού σύμφωνα με το NRS",
      "Διατροφική βαθμολογία",
      "Βαθμολογία της τρέχουσας κατάστασης υγείας",
      "Σοβαρότητα της νόσου",
      "Μέθοδος NRS",
      "Το πρόγραμμα διατροφής σύμφωνα με την αξιολόγηση της διατροφικής κατάστασης και την εκτίμηση των αναγκών του ασθενούς, προσδιορίζει τη βέλτιστη οδό χορήγησης και σύνθεσης της διατροφής και στη συνέχεια παρακολουθεί την επιτυχία αυτής της διατροφικής παρέμβασης.",
      "1 μήνας",
      "2 μήνες",
      "3 μήνες",
      "Πόσος καιρός απαιτήθηκε για να χάσετε βάρος?",
      "Διατροφική κατάσταση",
      "Somatikí drastiriótita",
      "-TRANSLATE=bedrest",
      "TRANSLATE=ContinousMovement",
      "-TRANSLATE=concious",
      "TRANSLATE=sedated",
      "TRANSLATE=MobilisingFrequently",
      "TRANSLATE=MobilisingOccasionally",
      "TRANSLATE=MobilisingRegular",
      "Chorís somatikí drastiriótita",
      "Epiléxte fysikí drastiriótita",
      "-TRANSLATE=sittingBed",
      "Εκτυπώστε το αποτέλεσμα",
      "Συσκευασία",
      "100",
      "Περιγραφή προϊόντος",
      "Διατροφικές πληροφορίες",
      "Μέγεθος συσκευασίας",
      "Πρωτεΐνες σε συσκευασία",
      "Λεπτομέρεια προϊόντος",
      "Θερμιδική πυκνότητα",
      "Διαβήτης",
      "Ίνα",
      "Φλεγμονώδης νόσος",
      "Πρωτεΐνες",
      "Φιλτράρετε τα προϊόντα",
      "Πυκνότητα",
      "EPA",
      "Συσκευασία",
      "Πρωτεΐνες στη συσκευασία",
      "Κατάλληλα προϊόντα",
      "Αναζήτηση προϊόντος",
      "Αποτελέσματα και προϊόντα",
      "Ημερομηνία",
      "Συνολικός κίνδυνος υποσιτισμού",
      "Προκύπτουσα θερμιδική εξίσωση",
      "Κίνδυνος σαρκοπενίας σύμφωνα με το SARC-F",
      "Τα αποτελέσματα και οι συστάσεις δεν είναι δεσμευτικές και η παρέμβαση είναι πάντα στη διακριτική ευχέρεια του ιατρού λόγω της ατομικής κατάστασης του ασθενούς.",
      "Επίπεδο κινδύνου",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Συνέχεια",
      "Πολύ/ δεν μπορώ χωρίς βοήθεια",
      "Καθόλου",
      "Λίγο",
      "Πόσο επίπονο σας είναι να μετακινείστε από την καρέκλα ή από το κρεβάτι?",
      "Σήκωμα από την καρέκλα",
      "4 ή περισσότερες φορές",
      "Καθόλου",
      "1–3x",
      "Πόσες φορές έχετε πέσει τον τελευταίο χρόνο?",
      "Πτώσεις",
      "Πολύ/ δεν μπορώ χωρίς βοήθεια",
      "Καθόλου",
      "Λίγο",
      "Πόσο επίπονο σας είναι να μετακινείστε σε άλλον χώρο?",
      "Βοήθεια κατά τη βάδιση",
      "Πολύ/ δεν μπορώ να το σηκώσω",
      "Καθόλου",
      "Λίγο",
      "Πόσο επίπονο σας είναι να σηκώνετε και να μεταφέρετε φορτίο 4.5 kg?",
      "Δύναμη",
      "Κίνδυνος σαρκοπενίας σύμφωνα με το SARC-F",
      "Μέθοδος SARC-F",
      "Πολύ/ δεν μπορώ να τα ανέβω",
      "Καθόλου",
      "Λίγο",
      "Πόσο επίπονο σας είναι να ανεβαίνετε 10 σκαλoπάτια?",
      "Ανέβασμα σκάλας",
      "{0} βαθμολογία4",
      "Αποτελέσματα διαλογής",
      "b",
      "Μέθοδος",
      "Επιλογή ερωτηματολογίου",
      "Αποστολή σε email",
      "SNAQ 65+",
      "Συνέχεια",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=SNAQ 65+ methode",
      "Παράγοντας στρες",
      "Εγκεφαλική βλάβη - οξεία (αερισμός, καταστολή)",
      "Εγκεφαλική βλάβη - ανάρρωση",
      "ΧΑΠ",
      "Επιλέξτε έναν παράγοντα άγχους",
      "Συνέχεια",
      "Αιμορραγικό εγκεφαλικό επεισόδιο",
      "Υψηλός παράγοντας άγχους",
      "Λοίμωξη",
      "IBD",
      "ΜΕΘ – σηπτική κατάσταση",
      "ΜΕΘ – με εξαερισμό",
      "Ισχαιμικό εγκεφαλικό επεισόδιο",
      "Λευχαιμία",
      "Χαμηλός παράγοντας άγχους",
      "Λέμφωμα",
      "Μέτριος παράγοντας άγχους",
      "Κανένας παράγοντας άγχους",
      "Οξεία παγκρεατίτιδα",
      "Χρόνια παγκρεατίτιδα",
      "Παγκρεατίτιδα – Sepse/Abces",
      "Συμπαγής όγκος",
      "Χειρουργική επέμβαση – με επιπλοκές",
      "Χειρουργική επέμβαση – χωρίς επιπλοκές",
      "Μεταμόσχευση",
      "Συνέχεια",
      "Βάρος",
      "kg",
      "Έτος")

  public override val locale: Locale = Locale("el")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'en'. Generated by i18n4k.
 */
private object Translation_en : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Back to main page",
      "Back to Questionnaire Selection",
      "Back to Product Search",
      "Back",
      "Back to Simple Calorie Equation",
      "Basic equation",
      "Simple caloric equation",
      "Basic patient information",
      "Continue",
      "Date of birth",
      "kg/m2",
      "BMI value",
      "Cancel",
      "I agree",
      "Our website uses cookies to help improve it. By using our website, you agree to our use of cookies and to our privacy statement.",
      "Cookies policy",
      "I am a healthcare professional",
      "general conditions for using the application.",
      "By clicking continue, you agree and confirm",
      "Continue",
      "Select the country of activity please",
      "Day",
      "Exit",
      "Female",
      "Male",
      "-",
      "Generate protocol",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Height",
      "cm",
      "High risk",
      "Low risk",
      "Moderate risk",
      "No risk",
      "Compendium",
      "© Abbott 2024",
      "Screening",
      "Search product",
      "Setting",
      "General conditions",
      "Month",
      "'MUST'",
      "Acute illness",
      "Acute disease score",
      "During illness,",
      "is it likely that the patient will have no nutritional intake longer than 5 days?",
      "Continue",
      "during the past 3-6 months",
      "Normal body weight",
      "Recommended patient treatment",
      "Malnutrition risk according to 'MUST'",
      "Weight loss score",
      "'MUST'  method",
      """
      |– Contact the nutrition team, initiate nutritional support or implement local guidelines*
      |
      |    – Set goals, improve and increase overall nutritional intake
      |
      |    – Monitor and review the care plan
      |
      |    In the hospital – weekly care
      |
      |    In a residential care home – monthly
      |
      |    In the home care setting – monthly
      |
      |    * Unless this is harmful or no benefit is expected from nutritional support, e.g. immediate death.
      """.trimMargin(),
      """
      |– Routine clinical care
      |
      |    Repeat screening:
      |
      |    In the hospital – weekly
      |
      |    In a residential care home – monthly
      |
      |    In the home care setting – annually for special groups, e.g. &gt; 75 years
      """.trimMargin(),
      """
      |– Record food intake during for 3 days
      |
      |   – If appropriate – slight concern, repeat the screening
      |
      |    In the hospital – weekly
      |
      |    In a residential care home – at least monthly
      |
      |    In the home care setting – at least every 2–3 months
      |
      |    If this is inappropriate – clinical concern, follow local guidelines, set goals, improve and increase overall nutritional intake, regularly follow and review the care plan.
      """.trimMargin(),
      "Unintentional weight loss",
      "Additional recommended actions",
      "NRS",
      "Is the patient &gt;70 years old?",
      "No",
      "Yes",
      "E.g. complicated fracture (femoral neck fracture, femoral fracture, …) or chronic disease with acute complications",
      "Chronic diseases and fractures",
      "Food intake during the past week as the percent fraction of the normal daily amount?",
      "E.g. head injury, bone marrow transplant (APACHE > 10)",
      "Patient at the ICU",
      "E.g. major abdominal surgery, stroke, pneumonia, haematological cancer",
      "Major surgery and other health problems",
      "Weight loss",
      "Minor disease",
      "Patient’s age",
      "Is the patient > 70 years old?",
      "Patient’s age score",
      "Malnutrition risk according to NRS",
      "Nutritional status score",
      "Acute health score",
      "Disease severity",
      "NRS method",
      "A nutritional plan identifies the optimal food composition and administration route based on the patient’s observed nutritional status and demands and subsequently monitors the success of this nutritional intervention.",
      "1 month",
      "2 months",
      "3 months",
      "Period during which the weight loss occurred?",
      "Nutritional status",
      "Physical activity",
      "-TRANSLATE=bedrest",
      "TRANSLATE=ContinousMovement",
      "-TRANSLATE=concious",
      "TRANSLATE=sedated",
      "TRANSLATE=MobilisingFrequently",
      "TRANSLATE=MobilisingOccasionally",
      "TRANSLATE=MobilisingRegular",
      "No physical activity",
      "Select physical activity",
      "-TRANSLATE=sittingBed",
      "Print result",
      "Packaging",
      "100",
      "Product description",
      "Nutritional information",
      "Pack size",
      "Proteins in a packing unit",
      "Product detail",
      "Caloric density",
      "Diabetes",
      "Fibre",
      "Inflammatory disease",
      "Proteins",
      "Filter products",
      "Density",
      "EPA",
      "Packaging",
      "Proteins in a packing unit",
      "Suitable products",
      "Search product",
      "Results and products",
      "Date",
      "Total malnutrition risk",
      "Resulting caloric equation",
      "Risk of sarcopenia according to SARC-F",
      "The results and recommendations are not binding and it is up to the doctor to decide on the intervention taking into account the patient’s health status.",
      "Risk rate",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Continue",
      "Very/I can’t without help",
      "Not at all",
      "A little",
      "How difficult is it for you to move from the chair to the bed?",
      "Getting up from the chair",
      "4 or more times",
      "At all",
      "1–3x",
      "How many times have you fallen down in the last year?",
      "Falls",
      "Very/I can’t without help",
      "Not at all",
      "A little",
      "How difficult is it for you to cross the room?",
      "Help during walking",
      "Very/I can’t lift",
      "Not at all",
      "A little",
      "How difficult is it for you to lift and carry a 4.5 kg load?",
      "Strength",
      "Risk of sarcopenia according to SARC-F",
      "SARC-F method",
      "Very/I can’t",
      "Not at all",
      "A little",
      "How difficult is it for you to walk up 10 stairs?",
      "Walking upstairs",
      "{0} score",
      "Screening results",
      "b",
      "Method",
      "Questionnaire selection",
      "Send email",
      "SNAQ 65+",
      "Continue",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=SNAQ 65+ methode",
      "Stress factor",
      "Brain injury - acute (ventilation, sedation)",
      "Brain injury - recovering",
      "COPD",
      "Select stress factor",
      "Continue",
      "Haemorrhagic stroke",
      "High stress factor",
      "Infection",
      "IBD",
      "ICU - septic condition",
      "ICU - on ventilation",
      "Ischaemic stroke",
      "Leukaemia",
      "Low stress factor",
      "Lymphoma",
      "Medium stress factor",
      "No stress factor",
      "Acute pancreatitis",
      "Chronic pancreatitis",
      "Pancreatitis - Sepsis/Abscess",
      "Solid tumour",
      "Surgery - complicated",
      "Surgery - non-complicated",
      "Transplantation",
      "Continue",
      "Body weight",
      "kg",
      "Year")

  public override val locale: Locale = Locale("en")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'hr'. Generated by i18n4k.
 */
private object Translation_hr : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Povratak na glavnu stranicu",
      "Povratak na odabir upitnika",
      "Povratak na Pretraživanje proizvoda",
      "Povratak ",
      "Povratak na jednostavnu jednadžbu kalorija",
      "Osnova jednadžbe",
      "Jednostavna jednadžba unosa kalorija",
      "Osnovni podaci o pacijentu",
      "Pokračovat",
      "Datum rođenja",
      "kg/m2",
      "Vrijednost BMI",
      "Otkazati",
      "slažem se",
      "Naša web stranica koristi kolačiće za poboljšanje. Korištenjem naše web stranice pristajete na korištenje kolačića i našu izjavu o privatnosti.",
      "Cookies policy",
      "Profesionalac sam u području zdravstvene skrbi",
      "opći uvjeti korištenja aplikacije.",
      "Klikom na nastavak pristajete i potvrđujete",
      "Nastavak",
      "Molimo odaberite zemlju u kojoj djelujete",
      "Dan",
      "Završite",
      "Žena",
      "Muškarac",
      "-",
      "Izradite protokol",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Visina",
      "cm",
      "Visoki rizik",
      "Nizak rizik",
      "Srednji rizik",
      "Nikakav rizik",
      "Priručnik",
      "© Abbott 2024",
      "Screening",
      "Potražite proizvod",
      "Postavke",
      "Opći uvjeti",
      "Mjesec",
      "'MUST'",
      "Akutna bolest",
      "Score akutne bolesti",
      "U slučaju bolesti,",
      "postoji li vjerojatnost da pacijent neće imati prehrambeni unos > 5 dana?",
      "Nastavak",
      "u posljednjih 3-6 mjeseci",
      "uobičajena težina",
      "Preporučeni tretman pacijenta",
      "Rizik od pothranjenosti prema 'MUST'",
      "Score gubitka težine",
      "Metoda 'MUST'-Malnutrition Universal Screening Tool",
      """
      |– Obratite se nutricionističkom timu, pokrenite nutricionističku podršku ili implementirajte lokalne smjernice*
      |
      |– Postavite ciljeve, poboljšajte i povećajte ukupni prehrambeni unos
      |
      |    – Nadgledajte i proučite plan njege
      |
      |U bolnici – tjedno
      |
      |    U domu socijalne skrbi – mjesečno
      |
      |   U kućnoj njezi – mjesečno
      |
      |    * Ako nije štetno ili se ne očekuje nikakva korist od nutricionističke podrške, na primjer, ako je neizbježan smrtni ishod.
      """.trimMargin(),
      """
      |– Rutinska klinička njega
      |
      |    Ponovite screening:
      |
      |    U bolnici – tjedno
      |
      |    U domu socijalne skrbi – mjesečno
      |
      |U kućnoj njezi – godišnje za posebne skupine, npr. &gt; 75 godina
      """.trimMargin(),
      """
      |>– Dokumentirajte unos hrane tijekom 3 dana
      |
      |    – Ako je primjeren – u slučaju lagane zabrinutosti, ponovite screening
      |
      |U bolnici – tjedno
      |
      |    U domu socijalne skrbi – najmanje mjesečno
      |
      |    U kućnoj njezi – najmanje svaka 2–3 mjeseca
      |
      |    Ako je neprimjeren – klinička zabrinutost, slijedite lokalne smjernice, postavite ciljeve, poboljšajte i povećajte ukupni prehrambeni unos, redovito nadgledajte i proučite plan njege.
      """.trimMargin(),
      "Nenamjerni gubitak težine",
      "Drugi preporučeni koraci",
      "NRS",
      "Je li pacijent stariji od 70 godina?",
      "Ne",
      "Da",
      "Npr. komplicirana fraktura (vrata, bedrene kosti itd.) ili kronične bolesti s akutnim komplikacijama",
      "Kronične bolesti i frakture",
      "Unos hrane za posljednji tjedan u postocima, u odnosu na standardnu dnevnu količinu?",
      "Npr. ozljeda glave, transplantacija koštane srži (APACHE > 10)",
      "Pacijent na odjelu JIL",
      "Npr. veće operacije u području abdomena, moždani udar, pneumonija, hematološka maligna bolest",
      "Veće operacije i druge poteškoće",
      "Gubitak",
      "Lakša bolest",
      "Dob pacijenta",
      "Je li pacijent stariji od 70 godina?",
      "Score dobi pacijenta",
      "Rizik od pothranjenosti prema NRS",
      "Score nutritivnog statusa",
      "Score aktualnog zdravstvenog stanja",
      "Težina bolesti",
      "Metoda NRS-Nutritional Risk Screening",
      "Plan prehrane, zasnovan na procjeni nutritivnog statusa i procjeni potreba pacijenta, identificira optimalan način primjene i sastav prehrane, a zatim prati uspjeh ove intervencije.",
      "1 mjesec",
      "2 mjeseca",
      "3 mjeseca",
      "Za koliko je vremena došlo do gubitka težine?",
      "Nutritivni status",
      "Tjelesna aktivnost",
      "-TRANSLATE=bedrest",
      "TRANSLATE=ContinousMovement",
      "-TRANSLATE=concious",
      "TRANSLATE=sedated",
      "TRANSLATE=MobilisingFrequently",
      "TRANSLATE=MobilisingOccasionally",
      "TRANSLATE=MobilisingRegular",
      "Nema fizičke aktivnosti",
      "Odaberite tjelesnu aktivnost",
      "-TRANSLATE=sittingBed",
      "Ispišite rezultat",
      "Pakiranje",
      "100",
      "Opis proizvoda",
      "Nutritivni podaci",
      "Veličina pakiranja",
      "Sadržaj proteina u pakiranju",
      "Detalj proizvoda",
      "Kalorijska gustoća",
      "Dijabetes",
      "Vlakna",
      "Upalna bolest",
      "Proteini",
      "Filtar proizvoda",
      "Gustoća",
      "EPA",
      "Pakiranje",
      "Sadržaj proteina u pakiranju",
      "Prikladni proizvodi",
      "Potražite proizvod",
      "Rezultati i proizvodi",
      "Datum",
      "Ukupan rizik od pothranjenosti",
      "Dobivena kalorijska jednadžba",
      "Rizik od sarkopenije prema SARC-F",
      "Rezultati i preporuke nisu obvezujuće i intervencija uvijek ovisi o odluci liječnika s obzirom na individualno stanje pacijenta.",
      "Mjera rizika",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Nastavak",
      "Veoma/ ne mogu bez pomoći",
      "Nimalo",
      "Malo",
      "Koliko Vam je naporno ustati sa stolice ili iz kreveta?",
      "Ustajanje sa stolice",
      "4 i više puta",
      "Nijednom",
      "1–3x",
      "Koliko puta ste pali u posljednjih godinu dana?",
      "Padovi",
      "Veoma/ ne mogu bez pomoći",
      "Nimalo",
      "Malo",
      "Koliko Vam je naporno prijeći prostoriju?",
      "Pomoć pri hodanju",
      "Veoma/ ne mogu podići",
      "Nimalo",
      "Malo",
      "Koliko Vam je naporno podići i nositi teret od 4.5 kg?",
      "Snaga",
      "Rizik od sarkopenije prema SARC-F",
      "Metoda SARC-F",
      "Veoma/ ne mogu se popeti",
      "Nimalo",
      "Malo",
      "Koliko Vam je naporno popeti se uz 10 stepenica?",
      "Hodanje po stepenicama",
      "{0} score",
      "Rezultati screeninga",
      "b",
      "Metoda",
      "Odabir upitnika",
      "Pošaljite na e-mail",
      "SNAQ 65+",
      "Nastavak",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=SNAQ 65+ methode",
      "Faktor stresa",
      "Ozljeda mozga - akutna (ventilirana, sedirana)",
      "Ozljeda mozga - oporavak",
      "KOPB",
      "Odaberite faktor stresa",
      "Nastavak",
      "Hemoragijski moždani udar",
      "Visoki faktor stresa",
      "Infekcija",
      "Upalna bolest crijeva (IBD)",
      "Intenzivna skrb (JIL) - septično stanje",
      "Intenzivna skrb (JIL) - s mehaničkom ventilacijom",
      "Ishemijski moždani udar",
      "Leukemija",
      "Niski faktor stresa",
      "Limfom",
      "Srednji faktor stresa",
      "Nikakav faktor stresa",
      "Akutni Pankreatitis",
      "Kronični pankreatitis",
      "Pankreatitis - Sepsa/Apsces",
      "Solidni tumor",
      "Operacija - komplicirana",
      "Operacija - nekomplicirana",
      "Transplantacija",
      "Nastavak",
      "Težina",
      "kg",
      "Godina")

  public override val locale: Locale = Locale("hr")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'nl'. Generated by i18n4k.
 */
private object Translation_nl : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Terug naar de hoofdpagina",
      "Terug naar vragenlijstselectie",
      "Terug naar Product zoeken",
      "Terug",
      "Terug naar de eenvoudige calorievergelijking",
      "De basis van de vergelijking",
      "Eenvoudige calorische vergelijking",
      "Basisgegevens van de patiënt",
      "Doorgaan",
      "Geboortedatum",
      "kg/m2",
      "BMI waarde",
      "Annuleren",
      "ik ga akkoord",
      "Onze website maakt gebruik van cookies om de website te verbeteren. Door gebruik te maken van onze website ga je akkoord met het plaatsen van cookies en onze privacyverklaring.",
      "Cookies policy",
      "Ik ben een gezondheidszorgprofessional.",
      "algemene voorwaarden voor het gebruik van de applicatie.",
      "Door op doorgaan te klikken, gaat u akkoord en bevestigt u",
      "Doorgaan",
      "Selecteer het land van toepassing",
      "Dag",
      "Beëindigen",
      "Vrouw",
      "Man",
      "-",
      "Logboek genereren",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Lengte",
      "cm",
      "Hoog risico",
      "Laag risico",
      "Gemiddeld risico",
      "Geen risico",
      "Compendium",
      "© Abbott 2024",
      "Begin screening",
      "Zoeken naar een product",
      "Instellingen",
      "Algemene voorwaarden",
      "Maand",
      "'MUST'",
      "Acute ziekte",
      "Score van de acute ziekte",
      "Is de patiënt",
      "ernstig ziek en (kans op) > 5 dagen geen voedselinname?",
      "Doorgaan",
      "in de afgelopen 3–6 maanden",
      "normaal gewicht",
      "Aanbevolen behandeling van de patiënt",
      "Risico op ondervoeding volgens 'MUST'",
      "Gewichtsverlies score",
      "'MUST' methode",
      "<ul><li>Neem contact op met het voedingsteam, start voedingsondersteuning of implementeer lokale richtlijnen*</li><li>Stel doelen, verbeter en verhoog de algehele voedingsinname</li><li>Controleer en bekijk het zorgplan<ul><li>In het ziekenhuis - wekelijkse zorg</li><li>In een verzorgingstehuis – maandelijks</li><li>Thuiszorg – maandelijks</li></ul></li></ul><br>* Als het niet schadelijk is of als er geen voordeel wordt verwacht van voedingsondersteuning, bijv. een onmiddellijke dood.",
      "<ul><li>Routinematige klinische zorg - Herhaal screening bij lichte bezorgdheid:<ul><li>In het ziekenhuis – wekelijks</li><li>In een verzorgingstehuis – maandelijks</li><li>In de thuiszorg - jaarlijks voor speciale groepen, bijvoorbeeld &gt; 75 jaar</li></ul></li></ul>",
      "<ul><li>Documenteer de voedselinname gedurende 3 dagen</li><li>Indien van toepassing – lichte bezorgdheid, herhaal de screening<ul><li>In het ziekenhuis – wekelijks</li><li>In een verzorgingstehuis – ten minste maandelijks</li><li>In de thuiszorg – ten minste elke 2–3 maanden</li></ul></li><li>Volg het zorgplan bij onevenredigheid of klinische bezorgheid.</li></ul>",
      "Onbedoeld gewichtsverlies",
      "Andere aanbevolen acties",
      "NRS",
      "Is de patiënt ouder dan 70 jaar?",
      "Nee",
      "Ja",
      "Bijv. een gecompliceerde fractuur (hals, dijbeen, enz.) of chronische ziekten met acute complicaties",
      "Chronische ziekten en fracturen",
      "Wat was de voedselinname van vorige week als percentage in vergelijking met de dagelijkse standaardhoeveelheid?",
      "Bijv. hoofdletsel, beenmergtransplantatie (APACHE > 10)",
      "Patiënt op de IC-afdeling",
      "Bijv. een grote buikoperatie, beroerte, longontsteking, hematologische kanker",
      "Grotere operaties en andere problemen",
      "Verlies",
      "Milde ziekte",
      "Leeftijd van de patiënt",
      "Is de patiënt ouder dan 70 jaar?",
      "Leeftijdsscore van de patiënt",
      "Risico op ondervoeding volgens NRS",
      "Voedingswaardescore",
      "Score van huidige gezondheidstoestand",
      "Ernst van de ziekte",
      "NRS methode",
      "Het voedingsplan, gebaseerd op een beoordeling van de toestand van de voeding en een schatting van de eisen van de patiënt, identificeert de optimale route van toediening en samenstelling van voeding en bewaakt vervolgens het succes van deze voedingsinterventie.",
      "1 maand",
      "2 maanden",
      "3 maanden",
      "Hoe lang duurde het voordat gewichtsverlies heeft plaatsgevonden?",
      "Voedingstoestand",
      "Fysieke activiteit",
      "Bedrust(beweging rond het bed)",
      "Continu mobiel/ langzaam lopend",
      "Bedlegerig, bij kennis",
      "Bedlegerig, verdoofd of in slaap",
      "Frequent mobiel op afdeling",
      "Af en toe in beweging op afdeling",
      "Mobiel + regelmatig intensieve fysiotherapie",
      "Geen fysieke activiteit",
      "Selecteer fysieke activiteit",
      "Opzitten uit bed voor langere perioden",
      "Resultaat printen",
      "Verpakking",
      "100",
      "Productbeschrijving",
      "Voedingsinformatie",
      "Verpakkingsgrootte",
      "Eiwitten in de verpakking",
      "Productdetail",
      "Calorische dichtheid",
      "Diabetes",
      "Vezel",
      "Ontstekingsziekte",
      "Eiwitten",
      "Producten filtreren",
      "Dichtheid",
      "EPA",
      "Verpakking",
      "Eiwitten in de verpakking",
      "Geschikte producten",
      "Zoeken naar een product",
      "Resultaten",
      "Datum",
      "Algemeen risico op sarcopenie",
      "Resulterende calorische vergelijking",
      "Risico op sarcopenie volgens SARC-F",
      "De resultaten en aanbevelingen zijn niet bindend. Interventie is afhankelijk van de beslissing van de dietist op basis van de individuele toestand van de patient.",
      "Risiconiveau",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Doorgaan",
      "veel, met hulpmiddel, of lukt niet",
      "geen",
      "enige",
      "Hoeveel moeite heeft u met het opstaan uit een stoel of bed?",
      "Opstaan uit een stoel",
      "Meer dan 4 keer",
      "Geen enkele keer",
      "1–3 keer",
      "Hoe vaak bent u gevallen in het afgelopen jaar?",
      "Vallen",
      "veel, met hulpmiddel, of lukt niet",
      "geen",
      "enige",
      "Hoeveel moeite heeft u met het lopen door een kamer?",
      "Hulp bij het lopen",
      "veel of lukt niet",
      "geen",
      "enige",
      "Hoeveel moeite heeft u met het optillen en dragen van 4.5 kilogram?",
      "Kracht",
      "Risico op sarcopenie volgens SARC-F",
      "SARC-F methode",
      "veel of lukt niet",
      "geen",
      "enige",
      "Hoeveel moeite heeft u met het omhoog lopen van 10 traptreden?",
      "Traplopen",
      "{0} score",
      "Resultaten van de screening",
      "b",
      "Methode",
      "Selecteer een vragenlijst",
      "Verzenden naar e-mail",
      "SNAQ 65+",
      "Doorgaan",
      "Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "Bovenarmomtrek < 25 cm?",
      "Verminderde eetlust afgelopen week?",
      "15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "Ander",
      "Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "Meer dan 6 kg in de laatste 6 maanden",
      "Meer dan 3 kg in de afgelopen maand",
      "Hebt u hulp van een ander nodig bij het eten?",
      "Had u de afgelopen maand een verminderde eetlust?",
      "Aanbevolen behandeling van de patiënt",
      "Risico op ondervoeding volgens SNAQ RC",
      "SNAQ RC methode",
      "Bent u onbedoeld afgevallen?",
      "Risico op ondervoeding volgens SNAQ 65+",
      "SNAQ 65+ methode",
      "Stressfactoren",
      "Hersenletsel - acuut (ventilatie, sedatie)",
      "Hersenletsel - herstellende",
      "COPD",
      "Selecteer een stressfactor",
      "Doorgaan",
      "Hemorragische beroerte",
      "Hoge stressfactor",
      "Infectie",
      "IBD",
      "IC - septische toestand",
      "IC - voor ventilatie",
      "Ischemische beroerte",
      "Leukemie",
      "Lage stressfactor",
      "Lymfoom",
      "Gemiddelde stressfactor",
      "Geen stressfactoren",
      "Acute pancreatitis",
      "Chronische pancreatitis",
      "Pancreatitis - Sepsis/Abces",
      "Vaste tumor",
      "Operatie - gecompliceerd",
      "Operatie - ongecompliceerd",
      "Transplantatie",
      "Doorgaan",
      "Gewicht",
      "kg",
      "Jaar")

  public override val locale: Locale = Locale("nl")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'sl'. Generated by i18n4k.
 */
private object Translation_sl : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Nazaj na glavno stran",
      "Nazaj na izbiro vprašalnika",
      "Nazaj na iskanje izdelkov",
      "Nazaj",
      "Nazaj na preprosto enačbo kalorij",
      "Osnova enačbe",
      "Preprosta kalorična enačba",
      "Osnovni podatki o bolniku",
      "NADALJUJ",
      "Datum rojstva",
      "kg/m2",
      "Vrednost ITM",
      "Prekliči",
      "strinjam se",
      "Naše spletno mesto uporablja piškotke za izboljšanje. Z uporabo naše spletne strani se strinjate z uporabo piškotkov in našo izjavo o zasebnosti.",
      "Cookies policy",
      "Sem zdravstveni delavec",
      "splošni pogoji za uporabo aplikacije.",
      "S klikom na nadaljevanje se strinjate in potrdite",
      "NADALJUJ",
      "Izberite državo",
      "Dan",
      "Zaključi",
      "Ženska",
      "Moški",
      "-",
      "Ustvari dnevnik",
      "GLIM",
      ">20 (0b)",
      "<20 če <70 let (1b)",
      "<22 če >70 let (1b)",
      "Bolniki BMI(kg/m2)",
      "Etiološki kriterij",
      "Ali je prisoten zmanjšan vnos hrane v zadnjem tednu?",
      "Ali je prisotna akutna bolezen ali poškodba ali kronična vnetna bolezen?",
      "Ali je prisotna kronična gastrointestinalna malabsorbcija?",
      "Ocena etioloških kriterijev",
      "FFMI",
      "Bolniki FFM",
      "Bolnik je podhranjen",
      "Pri bolniku obstaja tveganje za podhranjenost",
      "Ni tveganja za podhranjenost",
      "Fenotipski kriterij",
      "Ali je prisotna nenamerna izguba telesne mase (TM)?",
      "(> 5 % v zadnjih 6 mesecih)",
      "Znižana pusta (mišična) masa FFMI glej BIA",
      "<15kg/m² za ženske, <17kg/m² za moške",
      "glej BIA",
      "Ocena fenotipskih kriterijev",
      "Tveganje za podhranjenost glede na GLIM",
      "Tveganje za podhranjenost glede na GLIM",
      "Metoda GLIM",
      "Višina",
      "cm",
      "Visoko tveganje",
      "Nizko tveganje",
      "Zmerno tveganje",
      "Brez tveganja",
      "Kompendij",
      "© Abbott 2024",
      "Presejanje",
      "Poiščite izdelek",
      "Nastavitve",
      "Splošni pogoji",
      "Mesec",
      "'MUST'",
      "Akutna bolezen",
      "Ocena akutne bolezni",
      "Ali je v primeru bolezni verjetno,",
      "da bolnik ne bo jedel več kot 5 dni?",
      "NADALJUJ",
      "v zadnjih 3–6 mesecih",
      "telesna masa pred izgubo",
      "Priporočena obravnava bolnika",
      "Tveganje za podhranjenost po 'MUST'",
      "Ocena izgube teže",
      "Metoda 'MUST'",
      "<ul><li>Obrnite se na prehranski tim, uvedite prehransko podporo ali sledite nacionalnim smernicam*</li><li>Postavite cilje, povečajte in izboljšajte splošni prehranski vnos</li><li>Spremljajte in pregledujte načrt<ul><li>V bolnišnici – tedenska oskrba</li><li>V domu za ostarele – mesečna oskrba</li><li>V domači oskrbi – mesečna oskrba.</li></ul></li></ul><br>* Če to ni škodljivo ali če ne pričakujemo nobene koristi prehranske podpore, npr. skorajšnje smrti.",
      "<ul><li>Redna bolnišnična oskrba in ponovitve presejanja<ul><li>V bolnišnici – tedensko</li><li>V domu za ostarele – mesečno</li><li>V domači oskrbi – enkrat letno za posebne skupine, npr. > 75 let</li></ul></li></ul>",
      "<ul><li>Beležite prehranski vnos v obdobju treh dni.</li><li>Če je potrebno:<ul><li>Redna bolnišnična oskrba in ponovitve presejanja:</li><li>v bolnišnici – tedensko;</li><li>v domu za ostarele – mesečno;</li><li>v domači oskrbi – vsaj vsake 2-3 mesece</li></ul></li><li>Če je prehranski vnos nezadosten,sledite nacionalnim smernicam, postavite cilje, povečajte in izboljšajte splošni prehranski vnos ter spremljajte in pregledujte načrt oskrbe.</li></ul>",
      "Nenamerna izguba mase",
      "Druge možnosti",
      "NRS",
      "Ali je bolnik starejši od 70 let?",
      "Ne",
      "Ja",
      "Npr. Kompliciran zlom (vratu, stegnenice, itd.) ali kronične bolezni z akutnimi zapleti.",
      "Kronične bolezni in zlomi",
      "Vnos hrane prejšnji teden v odstotkih v primerjavi z običajno dnevno količino?",
      "Npr. poškodba glave, presaditev kostnega mozga (APACHE > 10)",
      "Bolnik na intenzivni terapiji",
      "Zahtevni kirurški poseg v predelu trebuha, možganska kap, pljučnica, krvni rak",
      "Zahtevni kirurški posegi in druge težave",
      "Izguba",
      "Manjše bolezni",
      "Bolnikova starost",
      "Ali je bolnik starejši od 70 let?",
      "Ocena starosti bolnika",
      "Tveganje za podhranjenost po NRS",
      "Ocena prehranskega stanja",
      "Ocena trenutnega zdravstvenega stanja",
      "Resnost bolezni",
      "Metoda NRS",
      "Prehranski načrt, ki temelji na oceni prehranskega stanja bolnika in oceni bolnikovih potreb, opredeljuje optimalen režim hranjenja in omogoča spremljanje in vrednotenje prehranske podpore.",
      "v 1 mesecu",
      "v 2 mesecih",
      "v 3 mesecih",
      "V kolikem času je prišlo do izgube mase?",
      "Prehransko stanje",
      "Telesna aktivnost",
      "Počiva v postelji (samostojno obračanje na postelji)",
      "Kontinuirano gibanje/počasna hoja",
      "Leži mirno, pri zavesti",
      "Leži mirno, umirjeno ali v spanju",
      "Pogosta mobilizacija na oddelku",
      "Občasna mobilizacija na oddelku",
      "Mobilizacija + redna intenzivna fizioterapija",
      "Brez telesne aktivnosti",
      "Izberite telesno aktivnost",
      "Dalj časa trajajoče sedenje izven postelje",
      "Natisni rezultat",
      "Pakiranje",
      "100",
      "Opis izdelka",
      "Informacija o hranilni vrednosti",
      "Velikost pakiranja",
      "Vsebnost beljakovin/pakiranje",
      "Podrobnosti o izdelku",
      "Energijska vrednost",
      "Diabetes",
      "Vlaknine",
      "Vnetna bolezen",
      "Beljakovine",
      "Filtrirajte izdelke",
      "Energijska vrednost",
      "EPA",
      "Pakiranje",
      "Vsebnost beljakovin",
      "Primerni izdelki",
      "Poiščite izdelek",
      "Rezultati in izdelki",
      "Datum",
      "Celotno tveganje za podhranjenost",
      "Posledična kalorična enačba",
      "Tveganje za sarkopenijo po SARC-F",
      "Rezultati in priporočila niso zavezujoči, poseg je vedno odvisen od izbire zdravnika glede na stanje posameznega bolnika.",
      "Stopnja tveganja",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "NADALJUJ",
      "Zelo/brez pomoči ne zmorem",
      "Brez težav",
      "Nekoliko",
      "Kako naporno je za Vas vstajanje iz stola ali postelje?",
      "Vstajanje iz stola",
      "4 in večkrat",
      "niti enkrat",
      "1–3x",
      "Kolikokrat ste v zadnjem letu padli?",
      "Padci",
      "Zelo/brez pomoči ne zmorem",
      "Brez težav",
      "Nekoliko",
      "Kako težko se premikate po sobi?",
      "Pomoč pri hoji",
      "Zelo/ne bi šlo",
      "Brez težav",
      "Nekoliko",
      "Kako težko bi dvignili in odnesli 4.5 kg breme?",
      "Moč",
      "Tveganje za sarkopenijo po SARC-F",
      "Metoda SARC-F",
      "Zelo/ne bi šlo",
      "Brez težav",
      "Nekoliko",
      "Kako težko se vzpnete po 10 stopnicah?",
      "Hoja po stopnicah",
      "{0} ocena",
      "Rezultati presejanja",
      "b",
      "Metoda",
      "Izbira vprašalnika",
      "Pošlji po e-pošti",
      "SNAQ 65+",
      "NADALJUJ",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "SNAQ 65+ methode",
      "Dejavniki tveganja za podhranjenost",
      "Poškodba možganov - akutna (mehanska ventilacija, sedacija)",
      "Poškodba možganov - okrevanje",
      "KOPB",
      "Brez dejavnikov tveganja",
      "NADALJUJ",
      "Hemoragična možganska kap",
      "Faktor visokega stresa",
      "Okužba",
      "KVČB",
      "INTENZIVNA TERAPIJA – septično stanje",
      "INTENZIVNA TERAPIJA – mehanska ventilacija",
      "Ishemična možganska kap",
      "Levkemija",
      "Faktor nizkega stresa",
      "Limfom",
      "Faktor srednjega stresa",
      "Brez dejavnikov tveganja",
      "Akutni pankreatitis",
      "Kronični pankreatitis",
      "Pankreatitis - sepsa / absces",
      "Solidni tumor",
      "Kirurški poseg - zapletena",
      "Kirurški poseg - enostaven",
      "Presaditev",
      "NADALJUJ",
      "Masa",
      "kg",
      "Leto")

  public override val locale: Locale = Locale("sl")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'de__rCH'. Generated by i18n4k.
 */
private object Translation_de__rCH : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Zurück zur Hauptseite",
      "Zurück zur Fragebogenauswahl",
      "Zurück zur Produktsuche",
      "Zurück zum Fragebogen",
      "Zurück zur einfachen Kaloriengleichung",
      "Grund-Energiebedarf",
      "Energiebedarf",
      "Grundlegende Daten des Patienten",
      "Fortfahren",
      "Geburtsdatum",
      "kg/m2",
      "BMI-Wert",
      "Absagen",
      "Ich stimme zu",
      "Unsere Website verwendet Cookies, um sie zu verbessern. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies und unserer Datenschutzerklärung zu.",
      "Cookies policy",
      "Ich bin im Bereich der gesundheitlichen Betreuung beruflich tätig.",
      "Allgemeine Bedingungen für die Nutzung der Anwendung.",
      "Wenn Sie auf Fortfahren klicken, stimmen Sie zu und bestätigen",
      "Fortfahren",
      "Wählen Sie bitte das Land des Wirkungsbereiches",
      "Tag",
      "Beenden",
      "Frau",
      "Mann",
      "-",
      "Protokoll generieren",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Körpergröße",
      "cm",
      "Hohes Risiko",
      "Geringes Risiko",
      "Mittleres Risiko",
      "Kein Risiko",
      "Kompendium",
      "© Abbott 2024 | CH-ABD-2100014",
      "Screening",
      "Produkt suchen",
      "Einstellungen",
      "Allgemeine Bedingungen",
      "Monat",
      "'MUST'",
      "Akute Krankheit",
      "Score der akuten Krankheit",
      "Besteht im Krankheitsfalle die Wahrscheinlichkeit,",
      "dass der Patient > 5 Tage keine Nahrung aufnimmt?",
      "Fortfahren",
      "in den vergangenen 3–6 Monaten",
      "übliches Gewicht",
      "Empfohlener Umgang mit dem Patienten",
      "Risiko der Mangelernährung gemäß 'MUST'",
      "Score der Gewichtsabnahme",
      "'MUST'",
      "<ul><li>Kontaktieren Sie das Ernährungsteam, initiieren Sie den Ernährungssupport oder implementieren Sie die lokalen Guidelines*</li><li>Legen Sie die Ziele fest, verbessern und erhöhen Sie die Gesamtnahrungsaufnahme</li><li>Überwachen und überprüfen Sie den Pflegeplan<ul><li>Im Krankenhaus – wöchentlich</li><li>Im Pflegeheim – monatlich</li><li>In häuslicher Pflege - monatlich</li></ul></li></ul><br>* Sofern dies förderlich ist und ein Nutzen aus dem Ernährungssupport erwartet wird.",
      "<ul><li>Kinische Routinepflege - Wiederholen Sie das Screening:<ul><li>Im Krankenhaus – wöchentlich</li><li>Im Pflegeheim – monatlich</li><li>In häuslicher Pflege – jährlich für spezielle Gruppen, z.B. > 75 Jahre</li></ul></li></ul>",
      "<ul><li>Dokumentieren Sie die Nahrungsaufnahme für die Dauer von 3 Tagen</li><li>Sofern geeignet – leichte Befürchtungen, wiederholen Sie das Screening<ul><li>Im Krankenhaus – wöchentlich</li><li>Im Pflegeheim – mindestens monatlich</li><li>In häuslicher Pflege – mindestens alle 2–3 Monate</li></ul></li><li>Sofern unangemessen – klinische Befürchtung, halten Sie die lokalen Richtlinien (Guidelines) ein, legen Sie die Ziele fest, verbessern und erhöhen Sie die Gesamtnahrungsaufnahme, überwachten und überprüfen Sie den Pflegeplan.</li></ul>",
      "Unbeabsichtigte Gewichtsabnahme",
      "Weitere empfohlene Maßnahmen",
      "NRS",
      "Ist der Patient älter als 70 Jahre?",
      "Nein",
      "Ja",
      "z.B. komplizierter Bruch (Schenkelhals, Schenkelknochen etc.) oder chronische Erkrankungen mit akuten Komplikationen",
      "Chronische Erkrankungen und Frakturen",
      "Nahrungsaufnahme in der letzten Woche in Prozent gegenüber der täglichen Standardmenge?",
      "z.B. Kopfverletzung, Knochenmarktransplantation (APACHE > 10)",
      "Patient auf der Intensivstation",
      "z.B. Größe Operationen in der Bauchgegend, Schlaganfall, Pneumonie, hämatologische Tumorerkrankung",
      "Größere Operationen und weitere Beschwerden",
      "Abnahme",
      "Geringfügige Erkrankung",
      "Alter des Patienten",
      "Ist der Patient älter als 70 Jahre?",
      "Score des Alters des Patienten",
      "Risiko der Mangelernährung gemäß NRS",
      "Score des Ernährungszustandes",
      "Score des aktuellen Gesundheitszustandes",
      "Relevanz der Erkrankung",
      "NRS",
      "Das NRS-Screening dient als Screening-Tool für die Einschätzung des Ernährungszustandes des Patienten und kann so auf ein mögliches Risiko einer Mangelernährung hinweisen. Zudem eignet sich dieses standartisierte Screeningverfahren nachfolgend für die Überwachung der angewendeten, personifizierten Ernährungsinterventionen.",
      "1 Monat",
      "2 Monate",
      "3 Monate",
      "Nach welchem Zeitraum trat die Gewichtsabnahme ein?",
      "Ernährungszustand",
      "Physische Aktivität",
      "Bettruhe (selbstständige Bewegung im Bett)",
      "Kontinuierliche Bewegung / langsames Gehen",
      "Liegend, bei Bewusstsein",
      "Liegend, sediert oder schlafend",
      "Regelmässige Bewegung, z.B. auf der Station",
      "Gelegentliche Bewegung, z.B. auf der Station",
      "Bewegung mit regelmässiger, intensiver Physiotherapie",
      "Keine körperliche Aktivität",
      "Wählen Sie körperliche Aktivität",
      "Sitzend, ausserhalb des Betts, während längerer Zeit",
      "Ergebnis ausdrucken",
      "Einheit",
      "100",
      "Produktbeschreibung",
      "Nährwertinformationen",
      "Einheit",
      "Protein pro Einheit",
      "Produktdetails",
      "Energiedichte",
      "Diabetes",
      "Ballaststoff",
      "Entzündliche Erkrankung",
      "Proteine",
      "Produkte filtern",
      "Energiedichte",
      "EPA",
      "Einheit",
      "Protein pro Einheit",
      "Geeignete Produkte",
      "Produkt suchen",
      "Ergebnisse und Produkte",
      "Datum",
      "Gesamtrisiko der Mangelernährung",
      "Gesamt-Energiebedarf",
      "Risiko für Sarkopenie laut SARC-F",
      "Die Ergebnisse und Empfehlungen sind nicht verbindlich, wobei eine Intervention stets auf der Grundlage der Entscheidung des Arztes im Hinblick auf den individuellen Zustand des Patienten erfolgt.",
      "Risikomaß",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Fortfahren",
      "Sehr/ohne Hilfe nicht möglich",
      "Gar nicht",
      "Ein wenig",
      "Wie schwierig ist für Sie das Aufstehen vom Stuhl oder Bett?",
      "Aufstehen vom Stuhl",
      "Mehr als 4 Mal",
      "Kein einziges Mal",
      "1–3 Mal",
      "Wie oft sind Sie im vergangenen Jahr gestürzt?",
      "Stürze",
      "Sehr/ohne Hilfe nicht möglich",
      "Gar nicht",
      "Ein wenig",
      "Wie schwierig ist für Sie das Durchqueren eines Raums?",
      "Hilfe beim Gehen",
      "Sehr/kann es nicht heben",
      "Gar nicht",
      "Ein wenig",
      "Wie schwierig ist für Sie das Heben und Tragen einer 4.5 kg schweren Last?",
      "Kraft",
      "Risiko für Sarkopenie laut SARC-F",
      "SARC-F",
      "Sehr/nicht möglich",
      "Gar nicht",
      "Ein wenig",
      "Wie schwierig ist für Sie das Steigen von 10 Treppenstufen?",
      "Treppensteigen",
      "{0} Score",
      "Ergebnisse des Screening",
      "b",
      "Methode",
      "Wahl des Screening Tools",
      "E-Mail absenden",
      "SNAQ 65+",
      "Fortfahren",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=TRANSLATE=SNAQ 65+ methode",
      "Stressfaktor",
      "Gehirnverletzung - akut",
      "Gehirnverletzung - genesend",
      "COPD",
      "Wählen Sie den Stressfaktor",
      "Fortfahren",
      "Hämorrhagischer Schlaganfall",
      "Hoher Stressfaktor",
      "Infektion",
      "IBD",
      "Intensivstation - septischer Zustand",
      "Intensivstation - zur Ventilation",
      "Ischämischer Schlaganfall",
      "Leukämie",
      "Geringer Stressfaktor",
      "Lymphom",
      "Mittlerer Stressfaktor",
      "Kein Stressfaktor",
      "Akute Pankreatitis",
      "Chronische Pankreatitis",
      "Pankreatitis - Sepsis/Abszess",
      "Solider Tumor",
      "Operation - kompliziert",
      "Operation - unkompliziert",
      "Transplantation",
      "Fortfahren",
      "Gewicht",
      "kg",
      "Jahr")

  public override val locale: Locale = Locale("de__rCH")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'fr__rCH'. Generated by i18n4k.
 */
private object Translation_fr__rCH : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Retour à la page d'accueil",
      "Retour à la sélection du questionnaire",
      "Retour à la recherche de produits",
      "Retour",
      "Retour à l'équation calorique simple",
      "Besoins énergétiques de base",
      "Besoins énergétiques",
      "Informations de base sur le patient",
      "Continuer",
      "Date de naissance",
      "kg/m2",
      "Valeur de l’IMC",
      "Annuler",
      "Je suis d'accord",
      "Notre site Web utilise des cookies pour l'améliorer. En utilisant notre site Web, vous acceptez l'utilisation de cookies et notre déclaration de confidentialité.",
      "Cookies policy",
      "Je suis un professionnel dans le domaine de soins médicaux",
      "Conditions générales",
      "",
      "Continuer",
      "Veuillez choisir le pays",
      "Jour",
      "Fermer",
      "Femme",
      "Homme",
      "-",
      "Générer le protocole",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Taille",
      "cm",
      "Risque élevé",
      "Faible risque",
      "Risque moyen",
      "Aucun risque",
      "Compendium",
      "© Abbott 2024 | CH-ABD-2100015",
      "Dépistage",
      "Chercher un produit",
      "Réglages",
      "Conditions générales",
      "Mois",
      "'MUST'",
      "Maladie aiguë",
      "Score de maladie aiguë",
      "En cas de maladie,",
      "est-il probable que le patient n’aura pas d’apport nutritionnel > 5 jours?",
      "Continuer",
      "au cours des 3 à 6 derniers mois",
      "poids habituel",
      "Traitement recommandé avec le patient",
      "Risque ne malnutrition d’après 'MUST'",
      "Score de la perte du poids",
      "'MUST'",
      "<ul><li>Contacter l’équipe de nutrition, initier un soutien nutritionnel ou appliquer les directives locales*</li><li>Fixer des objectifs, améliorer et augmenter l’apport nutritionnel total</li><li>Surveiller et vérifier le plan de soins<ul><li>À l’hôpital – hebdomadaire</li><li>Dans le centre d’aide sociale - mensuel</li><li>Soins à domicile – mensuel</li></ul></li></ul><br>* Sauf contre-indications et si un bénéfice est attendu du soutien nutritionnel</ul>",
      "<ul><li>Soins cliniques de routine - Répéter le dépistage:<ul><li>À l’hôpital – chaque semaine</li><li>Dans le centre d’aide sociale – chaque mois</li><li>En soins à domicile – chaque année pour les groupes spéciaux, par exemple > 75 ans</li></ul></li></ul>",
      "<ul><li>Documentez l’apport alimentaire pendant 3 jours</li><li>Le cas échéant – léger doute, répéter le dépistage<ul><li>À l’hôpital - chaque semaine</li><li>Dans le centre d’aide sociale – au moins une fois par mois</li><li>En soins à domicile – au moins tous les 2/3 mois</li></ul></li><li>Si inapproprié – problème clinique, suivre les directives locales, fixer des objectifs, améliorer et augmenter l’apport nutritionnel total, surveiller et vérifier régulièrement le plan de soins.</li></ul>",
      "Perte de poids non intentionnelle",
      "Autres actions conseillées",
      "NRS",
      "Le patient a-t-il plus de 70 ans?",
      "Non",
      "Oui",
      "Par exemple, fracture compliquée (col de fémur, fémur, etc.) ou maladies chroniques avec complications aiguës",
      "Maladies chroniques et fractures",
      "L’apport alimentaire de la semaine dernière exprimé en pourcentage comparé à la quantité quotidienne standard?",
      "Par exemple, traumatisme crânien, greffe de moelle osseuse (APACHE > 10)",
      "Patient dans le service de soins intensifs",
      "Par exemple, chirurgie abdominale majeure, accident vasculaire cérébral, pneumonie, hémopathie maligne",
      "Opérations majeures et autres problèmes",
      "Décroissance",
      "Trouble mineur",
      "Âge du patient",
      "Le patient a-t-il plus de 70 ans?",
      "Score d’âge du patient",
      "Risque ne malnutrition d’après NRS",
      "Score de l’état nutritionnel",
      "Score de l’état de santé actuel",
      "Gravité de la maladie",
      "NRS",
      "Le NRS sert d'outil de dépistage pour évaluer l'état nutritionnel du patient et peut ainsi indiquer un éventuel risque de malnutrition. En outre, cette procédure de dépistage standardisée permet ensuite de contrôler les interventions nutritionnelles personnalisées utilisées.",
      "1 mois",
      "2 mois",
      "3 mois",
      "La décroissance est apparue en combien de temps?",
      "État nutritionnel",
      "Activité physique",
      "Repos au lit (auto-mouvement dans le lit)",
      "Mobilisation continue / marche lente",
      "Couché, conscient",
      "Couché, sous sédation ou en sommeil artificiel",
      "Mobilisation régulière dans l’unité",
      "Mobilisation occasionnelle dans l’unité",
      "Mobilisation avec physiothérapie régulière et intensive",
      "Aucune activité physique",
      "Sélectionnez une activité physique",
      "Assis hors du lit pendant de longues périodes",
      "Imprimer le résultat",
      "Unité",
      "100",
      "Description du produit",
      "Informations nutritionnelles",
      "Unité",
      "Protéines par unité",
      "Détail du produit",
      "Densité calorique",
      "Diabète",
      "Fibres alimentaires",
      "Maladie inflammatoire",
      "Protéines",
      "Filtrer les produits",
      "Densité calorique",
      "EPA",
      "Unité",
      "Protéines par unité",
      "Produits adaptés",
      "Chercher un produit",
      "Résultats et produits",
      "Date",
      "Risque total de malnutrition",
      "Besoins énergétiques total",
      "Risque de sarcopénie selon le SARC-F",
      "Les résultats et les recommandations ne sont pas contraignants et l’intervention est toujours décidée par le médecin en fonction de l’état du patient.",
      "Niveau de risque",
      "SARC-F",
      "TRANSLATE=Zpět na Výběr dotazníku",
      "Continuer",
      "Très difficile/ impossible sans l’aide",
      "Pas du tout",
      "Un peu",
      "À quel point est-il difficile pour vous de vous lever d’une chaise ou d’un lit ?",
      "Se lever d’une chaise",
      "4 fois et plus",
      "Du tout",
      "1 à 3 fois",
      "Combien de fois avez-vous chuté au cours de l’année écoulée?",
      "Chutes",
      "Très difficile/ impossible sans l’aide",
      "Pas du tout",
      "Un peu",
      "À quel point est-il difficile pour vous de traverser une pièce ?",
      "Aide à la marche",
      "Très difficile/ je ne peux pas la lever",
      "Pas du tout",
      "Un peu",
      "À quel point est-il difficile pour vous de soulever et de porter une charge de 4.5 kg ?",
      "Force",
      "Risque de sarcopénie selon le SARC-F",
      "SARC-F",
      "Très difficile/ je ne peux pas les monter",
      "Pas du tout",
      "Un peu",
      "À quel point est-il difficile pour vous de monter 10 marches ?",
      "Monter un escalier",
      "{0} score",
      "Résultats du dépistage",
      "b",
      "Méthode",
      "Choix du questionnaire",
      "Envoyer par e-mail",
      "SNAQ 65+",
      "Continuer",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "TRANSLATE=SNAQ 65+ methode",
      "Facteur de stress",
      "Lésion cérébrale - aiguë",
      "Lésion cérébrale - récupération",
      "BPCO",
      "Choisissez le facteur de stress",
      "Continuer",
      "AVC hémorragique",
      "Facteur de stress élevé",
      "Infection",
      "IBD",
      "USI - état septique",
      "USI - patient ventilé",
      "AVC ischémique",
      "Leucémie",
      "Facteur de stress faible",
      "Lymphome",
      "Facteur de stress moyen",
      "Aucun facteur de stress",
      "Pancréatite aiguë",
      "Pancréatite chronique",
      "Pancréatite - Septicémie/Abcès",
      "Tumeur solide",
      "Chirurgie - compliquée",
      "Chirurgie - simple",
      "Transplantation",
      "Continuer",
      "Poids",
      "kg",
      "Année")

  public override val locale: Locale = Locale("fr__rCH")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}

/**
 * Translation of message bundle 'Translation' for locale 'it__rCH'. Generated by i18n4k.
 */
private object Translation_it__rCH : MessagesProvider {
  private val _data: Array<String?> = arrayOf(
      "Torna alla pagina principale",
      "Torna alla selezione del questionario",
      "Torna a Ricerca prodotto",
      "Torna",
      "Torna all'equazione calorica semplice",
      "Base dell'equazione",
      "Equazione calorica semplice",
      "Informazioni di base sul paziente",
      "Continua",
      "Data di nascita",
      "kg/m2",
      "Valore BMI",
      "Annulla",
      "sono d'accordo",
      "Il nostro sito Web utilizza i cookie per migliorarlo. Utilizzando il nostro sito Web, acconsenti all'uso dei cookie e alla nostra informativa sulla privacy.",
      "Cookies policy",
      "Sono un professionista nel settore dell’assistenza sanitaria",
      "condizioni generali per l'utilizzo dell'applicazione.",
      "Facendo clic su continua, accetti e confermi",
      "Continua",
      "Scegliere il Paese di competenza",
      "Giorno",
      "Termina",
      "Femmina",
      "Maschio",
      "-",
      "Genera un rapporto",
      "GLIM",
      ">20 (0b)",
      "TRANSLATE=",
      "TRANSLATE=",
      "BMI (kg/m2)",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Fenotypová kritéria",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=",
      "TRANSLATE=Metoda GLIM",
      "Altezza",
      "cm",
      "Rischio elevato",
      "Rischio basso",
      "Rischio medio",
      "Nessun rischio",
      "Compendio",
      "© Abbott 2024 | CH-ABD-2100016",
      "Screening",
      "Cerca un prodotto",
      "Impostazioni",
      "Condizioni generali",
      "Mese",
      "'MUST'",
      "Malattia acuta",
      "Punteggio della malattia acuta",
      "In caso di malattia,",
      "è probabile che il paziente non abbia un apporto nutrizionale > 5 giorni?",
      "Continua",
      "negli ultimi 3-6 mesi",
      "peso normale",
      "Trattamento consigliato del paziente",
      "Rischio di malnutrizione secondo 'MUST '",
      "Punteggio del calo ponderale",
      "Metodo 'MUST'",
      "<ul><li>Contattare il team nutrizionale, iniziare il supporto nutrizionale o implementare le linee guida locali*</li><li>Stabilire gli obiettivi, migliorare e aumentare l'apporto nutrizionale complessivo</li><li>Monitorare ed esaminare il programma di assistenza<ul><li>In ospedale: assistenza settimanale</li><li>In strutture di assistenza socio-sanitaria - una volta al mese</li><li>In caso di assistenza domiciliare - una volta al mese</li></ul></li></ul><br>* Se non è dannosa o se non si prevede alcun beneficio dal supporto nutrizionale, ad esempio morte immediata.",
      "<ul><li>Assistenza clinica di routine - Ripetere lo screening:<ul><li>In ospedale – una volta alla settimana</li><li>In strutture di assistenza socio-sanitaria - una volta al mese</li><li>In caso di assistenza domiciliare - una volta all'anno per gruppi speciali, ad esempio > 75 anni</li></ul></li></ul>",
      "<ul><li>Documentare l'assunzione di cibo per un periodo di 3 giorni</li><li>Se appropriato – lieve preoccupazione, ripetere lo screening<ul><li>In ospedale – una volta alla settimana</li><li>In strutture di assistenza socio–sanitaria – almeno una volta al mese</li><li>In caso di assistenza domiciliare – almeno ogni 2–3 mesi</li></ul></li><li>Se inappropriato - preoccupazione clinica, seguire le linee guida locali, stabilire gli obiettivi, migliorare e aumentare l'apporto nutrizionale complessivo, monitorare ed esaminare regolarmente il programma di assistenza.</li></ul>",
      "Calo ponderale involontario",
      "Altre azioni consigliate",
      "NRS",
      "Il paziente ha più di 70 anni?",
      "No",
      "Sì",
      "Ad esempio. frattura complicata (del collo del femore, del femore, ecc.) o malattie croniche con complicanze acute",
      "Malattie croniche e fratture",
      "Assunzione di cibo nel corso dell'ultima settimana espressa in percentuale rispetto alla quantità giornaliera standard?",
      "Ad esempio, ferite alla testa, trapianto di midollo osseo (APACHE > 10)",
      "Paziente nel reparto di terapia intensiva",
      "Ad esempio, operazione maggiore nella zona addominale, ictus, polmonite, neoplasia ematologica",
      "Operazioni maggiori e altri problemi",
      "Calo ponderale",
      "Malattie minori",
      "Età del paziente",
      "Il paziente ha più di 70 anni?",
      "Punteggio dell'età del paziente",
      "Rischio di malnutrizione secondo NRS",
      "Punteggio dello stato nutrizionale",
      "Punteggio dello stato di salute attuale",
      "Gravità della malattia",
      "Metodo NRS",
      "Il piano nutrizionale, basato sulla valutazione dello stato di nutrizione e sulla stima del fabbisogno del paziente, individua il metodo di somministrazione ottimale e la composizione della nutrizione e successivamente monitora il tasso di successo di questo intervento nutrizionale.",
      "1 mese",
      "2 mesi",
      "3 mesi",
      "In quanto tempo è avvenuto il calo ponderale?",
      "Stato nutrizionale",
      "Attività fisica",
      "Riposo a letto (automovimento nel letto)",
      "Mobilizzazione continua/camminata lenta",
      "Sdraiato, cosciente",
      "Sdraiati, sedati o in sonno artificiale",
      "Mobilitazione regolare nell'unità",
      "Mobilitazione occasionale nell'unità",
      "Mobilizzazione con fisioterapia regolare e intensiva",
      "Nessuna attività fisica",
      "Seleziona l'attività fisica",
      "Seduto fuori dal letto per lunghi periodi di tempo",
      "Stampa il risultato",
      "Confezione",
      "100",
      "Descrizione del prodotto",
      "Informazioni nutrizionali",
      "Grandezza della confezione",
      "Proteine nella confezione",
      "Dettagli del prodotto",
      "Densità calorica",
      "Diabetes",
      "Fibre",
      "Malattia infiammatoria",
      "Proteine",
      "Filtra i prodotti",
      "Densità",
      "EPA",
      "Confezione",
      "Proteine nella confezione",
      "Prodotti adatti",
      "Cerca un prodotto",
      "Risultati e prodotti",
      "Data",
      "Rischio complessivo di malnutrizione",
      "Equazione calorica risultante",
      "Rischio di sarcopenia secondo SARC-F",
      "I risultati e le raccomandazioni non sono vincolanti e l'intervento avviene sempre su decisione del medico in considerazione della condizione individuale del paziente.",
      "Livello di rischio",
      "SARC-F",
      "Torna alla selezione del questionario",
      "Continua",
      "Molto/ impossibile senza un aiuto",
      "Assolutamente no",
      "Un po’",
      "Quanto è per Lei difficoltoso spostarsi dalla sedia o dal letto?",
      "Alzarsi dalla sedia",
      "4 o più volte",
      "Mai",
      "1–3x",
      "Quante volte è caduto/a nell’ultimo anno?",
      "Cadute",
      "Molto/ impossibile senza un aiuto",
      "Assolutamente no",
      "Un po’",
      "Quanto è per Lei difficoltoso attraversare una stanza?",
      "Aiuto per camminare",
      "Molto/non riesco",
      "Assolutamente no",
      "Un po’",
      "Quanto è per Lei difficoltoso sollevare e portare un peso di 4.5 kg?",
      "Forza",
      "Rischio di sarcopenia secondo SARC-F",
      "Metodo SARC-F",
      "Molto/non riesco",
      "Assolutamente no",
      "Un po’",
      "Quanto è per Lei difficoltoso salire 10 gradini?",
      "Salire le scale",
      "{0} punteggio",
      "Risultati dello screening",
      "b",
      "Metodo",
      "Scelta del questionario",
      "Invia all'indirizzo e-mail",
      "SNAQ 65+",
      "Continua",
      "TRANSLATE=Onbedoeld gewichtsverlies afgelopen 6 maanden ≥ 4kg?",
      "TRANSLATE=Bovenarmomtrek < 25 cm?",
      "TRANSLATE=Verminderde eetlust afgelopen week?",
      "TRANSLATE=15 treden trap op en aflopen zonder te rusten?",
      "SNAQ RC",
      "TRANSLATE=Ander",
      "TRANSLATE=Ondervoed",
      "<ul><li>2-3x per dag tussentijdse verstrekking + verrijken hoofdmaaltijden + globale monitoring van de inname</li><li>Melden bij de arts voor het inschakelen van een diëtist</li><li>≤ 3 werkdagen na screening diëtist in consult</li><li>≤ 8 werkdagen na screening start behandeling</li><li>5 werkdagen na start van de behandeling evaluatie</li></ul>",
      "TRANSLATE=Risico op ondervoeding",
      "<ul><li>2-3x per dag tussentijdse verstrekking.</li><li>Motiviere, evt. Brochure.</li><li>Globale monitoring van den inname.</li></ul>",
      "TRANSLATE=Niet ondervoed",
      "<ul><li>geen actie</li></ul>",
      "TRANSLATE=Meer dan 6 kg in de laatste 6 maanden",
      "TRANSLATE=Meer dan 3 kg in de afgelopen maand",
      "TRANSLATE=Hebt u hulp van een ander nodig bij het eten?",
      "TRANSLATE=Had u de afgelopen maand een verminderde eetlust?",
      "TRANSLATE=Aanbevolen behandeling van de patiënt",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ RC",
      "TRANSLATE=SNAQ RC methode",
      "TRANSLATE=Bent u onbedoeld afgevallen?",
      "TRANSLATE=Risico op ondervoeding volgens SNAQ 65+",
      "SNAQ 65+ methode",
      "Fattore di stress",
      "Lesione cerebrale - acuta (ventilazione, sedazione)",
      "Lesione cerebrale - in fase di guarigione",
      "BPCO",
      "Scegliere un fattore di stress",
      "Continua",
      "Ictus emorragico",
      "Fattore di stress elevato",
      "Infezione",
      "IBD",
      "TI - stato settico",
      "TI - per la ventilazione",
      "Ictus ischemico",
      "Leucemia",
      "Fattore di stress basso",
      "Linfoma",
      "Fattore di stress medio",
      "Nessun fattore di stress",
      "Pancreatite acuta",
      "Pancreatite cronica",
      "Pancreatite - Sepsi/Ascesso",
      "Tumore solido",
      "Operazione - complicata",
      "Operazione – non complicata",
      "Trapianto",
      "Continua",
      "Peso",
      "kg",
      "Anno")

  public override val locale: Locale = Locale("it__rCH")

  public override val size: Int
    get() = _data.size

  public override fun `get`(index: Int): String? = _data[index]
}
