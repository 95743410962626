package data.screening.model.basicInfo

import de.comahe.i18n4k.strings.LocalizedString
import kotlinx.serialization.Serializable
import localization.Translation
import pages.common.components.selectComponent.ISelectable
import util.serializable.IntRangeSerializer


@Serializable
class PhysicalActivity(
    override val id: Int,
    @Serializable(with = IntRangeSerializer::class) override val range: IntRange,
    override val label: LocalizedString
) : IBasicInfo, ISelectable {

    companion object {

        val noPhysicalActivity =
            PhysicalActivity(id = 0, range = 0..100, label = Translation.physicalActivityNo)
        private val lyingStillSedatedPhysicalActivity =
            PhysicalActivity(
                id = 1,
                range = -10..10,
                label = Translation.physicalActivityLyingStillSedated
            )
        private val lyingStillConciousPhysicalActivity =
            PhysicalActivity(
                id = 2,
                range = 0..10,
                label = Translation.physicalActivityLyingStillConcious
            )
        private val bedrestPhysicalActivity =
            PhysicalActivity(
                id = 3,
                range = 15..20,
                label = Translation.physicalActivityBedrestPhysical
            )

        private val sittingBedPhysicalActivity =
            PhysicalActivity(
                id = 4,
                range = 10..30,
                label = Translation.physicalActivitySittingBed
            )

        private val mobilisingOccasionallyPhysicalActivity =
            PhysicalActivity(
                id = 5,
                range = 15..40,
                label = Translation.physicalActivityMobilisingOccasionally
            )

        private val mobilisingFrequentlyPhysicalActivity =
            PhysicalActivity(
                id = 6,
                range = 40..50,
                label = Translation.physicalActivityMobilisingFrequently
            )

        private val mobilisingRegularPhysicalActivity =
            PhysicalActivity(
                id = 7,
                range = 50..60,
                label = Translation.physicalActivityMobilisingRegular
            )

        private val continousMovementPhysicalActivity =
            PhysicalActivity(
                id = 8,
                range = 60..80,
                label = Translation.physicalActivityContinousMovement
            )

        val physicalActivities = listOf(
            noPhysicalActivity,
            lyingStillSedatedPhysicalActivity,
            lyingStillConciousPhysicalActivity,
            bedrestPhysicalActivity,
            sittingBedPhysicalActivity,
            mobilisingOccasionallyPhysicalActivity,
            mobilisingFrequentlyPhysicalActivity,
            mobilisingRegularPhysicalActivity,
            continousMovementPhysicalActivity
        )

    }

    override fun getInitValue(): Int {
        if (this == noPhysicalActivity) {
            return range.first
        }
        return range.last
    }

    override val subText: String?
        get() = getMultiplicationLabel()


}