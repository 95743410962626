package util.serializable

import data.products.model.Product
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object ProductsMapSerializer: KSerializer<Map<String, List<Product>>> {
    private val mapSerializer = MapSerializer(String.serializer(), ListSerializer(Product.serializer()))
    override val descriptor: SerialDescriptor = mapSerializer.descriptor
    override fun serialize(encoder: Encoder, value: Map<String, List<Product>>) {
        mapSerializer.serialize(encoder, value)
    }
    override fun deserialize(decoder: Decoder): Map<String, List<Product>> {
        return mapSerializer.deserialize(decoder)
    }
}