package pages.common.components.footer

import ThemeContext
import emotion.react.css
import localization.Translation
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.img

val FooterComponent = FC<FooterComponentProps> { props ->
    val theme by useContext(ThemeContext)
    Box {
        sx {
            footerHolder()
        }
        img {
            alt = "Abbot logo"
            src = "${MainPage.BASEPATH}/images/logo-abbott.svg"
        }
        Typography {
            +Translation.mainMenuCopyright.invoke(props.i18n4k.locale)
            variant = TypographyVariant.body1
            sx {
                footerTextCopyright(theme)
            }
        }
        Button {
            +Translation.mainMenuSettings.invoke(props.i18n4k.locale)
            variant = ButtonVariant.text
            onClick = {
                props.onSettingsClicked.invoke()
            }
            sx {
                footerTextButton()
            }
        }
        Button {
            +Translation.mainMenuTerms.invoke(props.i18n4k.locale)
            variant = ButtonVariant.text
            onClick = {
                props.onTermsClicked.invoke()
            }
            sx {
                footerTextButton()
            }
        }
    }
}
