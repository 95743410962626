package pages.screening.patientInfo

import ThemeContext
import csstype.Display
import csstype.FlexDirection
import csstype.Margin
import emotion.react.css
import localization.Translation
import mui.material.Box
import mui.material.Button
import mui.material.Container
import mui.system.sx
import org.koin.core.component.KoinComponent
import pages.common.secondaryButton
import pages.screening.patientInfo.component.PatientInfoPageProps
import pages.screening.patientInfo.component.basicInfo.BasicInfoComponent
import pages.screening.patientInfo.component.equationResult.EquationResultComponent
import pages.screening.patientInfo.component.stressFactor.StressFactorComponent
import react.*
import util.format

class PatientInfoPage : KoinComponent {
    private val stressFactorComponent = StressFactorComponent()

    val component = FC<PatientInfoPageProps> { props ->
        val theme by useContext(ThemeContext)

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
            }

            BasicInfoComponent {
                patientInfo = props.patientInfo
                i18n4k = props.i18n4k
                country = props.country
                onPatientInfoChanged = {
                    props.onPatientInfoChanged(it)
                }
            }

            stressFactorComponent.component {
                i18n4k = props.i18n4k
                patientInfo = props.patientInfo
                onPatientInfoChanged = {
                    props.onPatientInfoChanged(it)
                }
            }

            EquationResultComponent {
                i18n4k = props.i18n4k
                val bmr = props.patientInfo.getBMRTotal()
                result = if (bmr != null) "${bmr.format(0)} kcal" else "?"
            }

            // continue button
            Button {
                +Translation.stressFactorContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                disabled = !props.patientInfo.isFirstStepValid()
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }
    }
}
