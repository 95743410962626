package data.screening.model.nrs.complication

interface NrsComplication {
    val score: Int
    val selected: Boolean

    fun getComplicationScore(): Int {
        return if (selected) {
            score
        } else {
            0
        }
    }
}
