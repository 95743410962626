package util

import data.screening.model.RiskLevel
import data.screening.model.ScreeningType
import external.firebase.*
import external.firebase.analytics.Analytics
import external.firebase.analytics.CustomParams
import external.firebase.analytics.`T$18`
import external.firebase.analytics.set
import org.koin.core.component.KoinComponent
import kotlin.js.json

class FirebaseWrapper : KoinComponent {

    val firebaseRemoteConfig: RemoteConfig
    val firebaseStorage: Storage
    val firebaseAuth: Auth
    var firebaseAnalytics: Analytics? = null


    companion object {
        const val USER_PROPERTY_LANGUAGE_CODE = "language_code"

        const val EVENT_SCANNING_START = "scanning_start"
        const val EVENT_SCANNING_FINISH = "scanning_finish"

        const val PARAM_METHOD_NAME = "method_name"
        const val PARAM_RESULT = "result"


        const val SCREEN_NAME_COMPENDIUM = "Compendium"
        const val SCREEN_NAME_CONDITIONS = "Conditions"
        const val SCREEN_NAME_COUNTRY_SELECTOR = "CountrySelector"
        const val SCREEN_NAME_HOME = "Home"
        const val SCREEN_NAME_PRODUCT_DETAIL = "ProductDetail"
        const val SCREEN_NAME_PRODUCT_CATALOGUE = "ProductCatalogue"
        const val SCREEN_NAME_SCREENING_CALORIC_EQUATION = "ScreeningCaloricEquation"
        const val SCREEN_NAME_SCREENING_MUST_METHOD = "ScreeningMustMethod"
        const val SCREEN_NAME_SCREENING_NRS_METHOD = "ScreeningNrsMethod"
        const val SCREEN_NAME_SCREENING_RESULT = "ScreeningResult"
        const val SCREEN_NAME_SCREENING_SARC_METHOD = "ScreeningSarcMethod"
        const val SCREEN_NAME_SCREENING_SNAQ_METHOD = "ScreeningSnaqMethod"
        const val SCREEN_NAME_SCREENING_SNAQ_RC_METHOD = "ScreeningSnaqRCMethod"
        const val SCREEN_NAME_SCREENING_GLIM_METHOD = "ScreeningGlimMethod"
        const val SCREEN_NAME_SCREENING_METHOD_SELECTOR = "ScreeningMethodSelector"


    }

    private var firebaseApp: App
    private val firebaseOptions = FirebaseOptions(
        apiKey = "AIzaSyAqLfUOXr5iuMSg7SxDZ6MUDBJfsPD0uD0",
        authDomain = "abbott-screening-application.firebaseapp.com",
        databaseUrl = "https://abbott-screening-application.firebaseio.com",
        projectId = "abbott-screening-application",
        storageBucket = "abbott-screening-application.appspot.com",
        applicationId = "1:847264571841:web:30c6e7f4795cc6b4ab8f40",
        measurementId = "G-4W9334K92W",
        gcmSenderId = null
    )

    init {
        firebaseApp = firebase.initializeApp(
            firebaseOptions
        ).unsafeCast<App>()
        firebaseStorage = firebaseApp.storage("gs://abbott-screening-application.appspot.com")
        firebaseRemoteConfig = firebaseApp.remoteConfig()
        firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60 * 6
        firebaseAuth = firebaseApp.auth()
    }

    fun setupFirebaseAnalytics() {
        firebaseAnalytics = firebaseApp.analytics()
        firebaseAnalytics?.setAnalyticsCollectionEnabled(true)
        println("firebaseAnalytics enabled")
    }

    fun clearFirebaseAnalytics() {
        firebaseAnalytics?.setAnalyticsCollectionEnabled(false)
        firebaseAnalytics = null
    }


    fun setLanguageCode(languageCode: String) {
        val customParams = object : CustomParams {
        }.apply {
            set(USER_PROPERTY_LANGUAGE_CODE, languageCode)
        }
        console.log("user_property: $languageCode")
        firebaseAnalytics?.setUserProperties(properties = customParams)
    }

    fun logEvent(eventName: String, map: Map<String, String>) {
        firebaseAnalytics?.logEvent(eventName, json(*map.toList().toTypedArray()))
    }

    fun trackScanningStart(screeningType: ScreeningType) {
        logEvent(
            EVENT_SCANNING_START, mapOf(
                PARAM_METHOD_NAME to screeningType.name
            )
        )
    }

    fun trackScanningFinish(screeningType: ScreeningType, riskLevel: RiskLevel) {
        logEvent(
            EVENT_SCANNING_FINISH, mapOf(
                PARAM_METHOD_NAME to screeningType.name,
                PARAM_RESULT to riskLevel.name
            )
        )
    }

    fun logScreenView(screenName: String) {
        val event = object : `T$18` {
            override var app_name: String = "AbbottWeb"
            override var screen_name: Any = screenName

        }
        firebaseAnalytics?.setCurrentScreen(screenName)
        firebaseAnalytics?.logEvent("screen_view", event, null)
    }
}