package data.mainMenu.model

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

data class MainMenu(
    val id: Int,
    val text: LocalizedString,
    val icon: String
) {
    companion object {
        const val ID_SCREENING = 1
        const val ID_SEARCH = 2
        const val ID_COMPENDIUM = 3

        var all = listOf(
            MainMenu(ID_SCREENING, Translation.mainMenuScreeningCard, "assignment_turned_in"),
            MainMenu(ID_SEARCH, Translation.mainMenuSearchCard, "search"),
            MainMenu(ID_COMPENDIUM, Translation.mainMenuCompendium, "book")
        )

    }
}