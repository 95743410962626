package pages.common.components.labelValue

import csstype.*
import emotion.react.css
import mui.material.Box
import mui.material.Container
import mui.material.Typography
import mui.system.sx
import pages.common.normalFontSize
import react.*

val LabelValueComponent = FC<LabelValueComponentProps> { props ->
    Box {
        sx {
            paddingLeft = 0.px
            paddingRight = 0.px
            width = 100.pct
            display = Display.flex
            flexDirection = FlexDirection.row
        }
        Typography {
            +props.label
            sx {
                normalFontSize()
                if (props.bold == true) {
                    fontWeight = FontWeight.bold
                }
                if (props.subItem == true) {
                    marginLeft = 16.px
                }
                flexGrow = number(1.0)
                letterSpacing = 0.24.px
                lineHeight = 22.px
            }
        }

        if (props.subValue != null) {
            Typography {
                +(props.subValue ?: "")
                sx {
                    normalFontSize()
                    marginRight = 16.px
                    marginLeft = "auto".unsafeCast<MarginLeft>()
                }
            }
        }

        Typography {
            +props.value
            sx {
                normalFontSize()
                if(props.subValue == null) {
                    marginLeft = "auto".unsafeCast<MarginLeft>()
                }
                color = Color("#64CCC9")
                fontWeight = integer(500)
                letterSpacing = 0.09.px
                lineHeight = 19.px
            }
        }
    }
}
