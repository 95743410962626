package data.screening.model

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

enum class ScreeningType(var label: LocalizedString) {
    MUST(Translation.must),
    NRS(Translation.nrs),
    SNAQ(Translation.snaq),
    SNAQ_RC(Translation.snaqRC),
    SARCF(Translation.sarcf),
    GLIM(Translation.glim)
}