package pages.common.components.footer

import csstype.*
import mui.material.styles.Theme
import pages.common.normalFontSize


fun PropertiesBuilder.footerHolder() {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    marginBottom = 40.px
}

fun PropertiesBuilder.footerTextButton() {
    normalFontSize()
    textTransform = "none".unsafeCast<TextTransform>()
    fontWeight = integer(400)
    textDecoration = TextDecoration.underline
    color = Color("#5BC2E7")
}

fun PropertiesBuilder.footerTextCopyright(theme: Theme) {
    normalFontSize()
    flexGrow = number(1.0)
    color = theme.palette.primary.main
    marginLeft = 16.px
}
