package pages.mainMenu

import csstype.*
import mui.material.styles.Theme

fun PropertiesBuilder.mainBackgroundImage() {
    width = 100.pct
}

fun PropertiesBuilder.cardTextHolder(theme: Theme) {
    color = Color(theme.palette.background.paper)
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    paddingLeft = 24.px
    paddingRight = important(10.px)
    paddingBottom = 8.px
}

fun PropertiesBuilder.cardText() {
    fontSize = 20.px
    textTransform = "none".unsafeCast<TextTransform>()
    fontWeight = FontWeight.normal
}

fun PropertiesBuilder.cardActionArea() {
    height = Length.maxContent
    display = Display.flex
    flexDirection = FlexDirection.column
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.flexStart
}

fun PropertiesBuilder.cardHolder() {
    maxWidth = 1064.px
    width = important("auto".unsafeCast<Width>())
    margin = "-40px auto 0px auto".unsafeCast<Margin>()
    padding = important(0.px)
    display = Display.flex
    flexGrow = number(0.0)
    flexDirection = FlexDirection.row
    flexWrap = FlexWrap.wrap
    alignContent = AlignContent.baseline
}

fun PropertiesBuilder.cardHolderChildren() {
    width = 241.px
    backgroundColor = Color("#5BC2E7")
    marginLeft = 10.px
    marginRight = 10.px
    marginBottom = 20.px
    flexShrink = number(1.0)
    height = 202.px
}

fun PropertiesBuilder.mainHolder() {
    maxWidth = 1280.px
    margin = "0 auto 0 auto".unsafeCast<Margin>()
    padding = important(0.px)
    width = 100.pct
    minHeight = 100.pct
    display = Display.flex
    flexDirection = FlexDirection.column
}

fun PropertiesBuilder.pageBackground() {
    height = 100.pct
    width = 100.pct
    backgroundColor = Color("#004F71")
    maxWidth = important(100.pct)
    display = Display.flex
    padding = important(0.px)
}

fun PropertiesBuilder.menuIcon() {
    fontFamily = string("Material Icons Outlined")
    fontSize = 32.px
    backgroundColor = NamedColor.white
    color = Color("#5BC2E7")
    height = 60.px
    width = 60.px
    borderRadius = 30.px
    marginTop = 24.px
    marginLeft = 24.px
    textAlign = TextAlign.center
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}
