package data.products

import data.products.repository.IProductsRepository
import data.products.repository.ProductsRepository
import org.koin.dsl.module

val productsModule = module {
    single<IProductsRepository> {
        ProductsRepository(client = get())
    }
}