package data.screening.model.must

import csstype.Color
import data.screening.model.IScreeningScoreResult
import data.screening.model.RiskLevel
import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

class MustResult(
    override val riskLevel: RiskLevel,
    override val text: LocalizedString,
    override val icon: String,
    override val color: Color
): IScreeningScoreResult {

    companion object {
        private val scoreLow = MustResult(RiskLevel.LOW, Translation.levelRiskNo, "check_circle", Color("#64CCC9"))
        private val mediumScore = MustResult(RiskLevel.MEDIUM, Translation.levelRiskMedium, "visibility", Color("#F5B420"))
        private val scoreHigh = MustResult(RiskLevel.HIGH, Translation.levelRiskHigh, "error_outline", Color("#B3175A"))
        val results = listOf(scoreLow, mediumScore, scoreHigh)
    }

}