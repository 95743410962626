package data.screening.model.snaqrc

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

data class SnaqRCSection(
    val text: LocalizedString,
    val sectionNr: Int
) {
    companion object {
        val section1 = SnaqRCSection(Translation.snaqRcFormTitle1, 1)
        val section2 = SnaqRCSection(Translation.snaqRCFormTitle2, 2)
    }
}