package data.countyPicker

import data.countyPicker.repository.ICountryRepository
import data.countyPicker.repository.CountryRepository
import org.koin.dsl.module

val countryPickerModule = module {
    single<ICountryRepository> {
        CountryRepository()
    }
}