package pages.countryPicker

import ThemeContext
import csstype.*
import data.cookies.repository.ICookiesRepository
import localization.Translation
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.appLogo.NutriAppLogo
import pages.common.normalFontSize
import pages.common.secondaryButton
import pages.countryPicker.components.CountryPickerComponent
import react.*
import react.dom.svg.ReactSVG.svg
import util.FirebaseWrapper

class CountryPickerPage : KoinComponent {
    private val cookiesRepository: ICookiesRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<CountryPickerPageProps> { props ->
        val theme by useContext(ThemeContext)
        var isTermsChecked: Boolean by useState(false)

        // run effect only once
        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_COUNTRY_SELECTOR)
        }

        NutriAppLogo {}

        FormControl {
            Container {
                sx {
                    flexGrow = number(1.0)
                }
            }
            sx {
                margin = "0 auto 0 auto".unsafeCast<Margin>()
                textAlign = TextAlign.center
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
                height = 100.pct
            }

            Typography {
                +Translation.countryPickerTitle.invoke(props.i18n4k.locale)
                //   htmlFor = "country-picker"
                sx {
                    normalFontSize()
                    fontWeight = integer(500)
                    color = Color(theme.palette.background.paper)
                    marginBottom = 14.px
                }
            }

            CountryPickerComponent {
                componentId = "country-picker"
                countries = props.countries
                selectedCountry = props.selectedCountry
                handleSelectionChanged = {
                    props.onCountrySelected(it)
                }
            }

            Button {
                +Translation.countryPickerContinue.invoke(props.i18n4k.locale)
                variant = ButtonVariant.contained
                color = ButtonColor.primary
                disabled = !isTermsChecked || props.selectedCountry == null
                onClick = {
                    val country = props.selectedCountry ?: props.countries.firstOrNull()
                    if (country != null) {
                        cookiesRepository.setSelectedCountry(country)
                        cookiesRepository.setTermsAgreed(true)
                        props.onNavigateToNextPage()
                    }
                }
                sx {
                    marginTop = 40.px
                    secondaryButton(theme)
                }
            }


            FormControlLabel {
                sx {
                    color = Color(theme.palette.background.paper)
                    marginTop = 40.px
                }
                label = Typography.create {
                    +Translation.countryPickerCheckBoxCondition.invoke(props.i18n4k.locale)
                }
                control = Checkbox.create {
                    sx {
                        svg {
                            firstOfType {
                                color = NamedColor.white
                            }
                        }
                    }
                    required = true
                    checked = isTermsChecked
                    onChange = { _, value ->
                        isTermsChecked = value
                    }
                }
            }

            Container {
                sx {
                    flexGrow = number(1.0)
                    display = Display.flex
                    justifyContent = JustifyContent.center
                    flexDirection = FlexDirection.column
                }

                if (props.selectedCountry?.uiSettings?.allowCountryPickerConditionMessage == true) {
                    Typography {
                        +Translation.countryPickerConditionMessage.invoke(props.i18n4k.locale)
                        variant = TypographyVariant.body1
                        sx {
                            color = NamedColor.white // Color(theme.palette.background.paper)
                            normalFontSize()
                        }
                    }
                }

                Button {
                    +Translation.countryPickerCondition.invoke(props.i18n4k.locale)
                    variant = ButtonVariant.text
                    onClick = {
                        props.onNavigateToTermsPage()
                    }
                    sx {
                        normalFontSize()
                        textDecoration = TextDecoration.underline
                        textTransform = "none".unsafeCast<TextTransform>()
                        padding = 0.px
                        color = Color(theme.palette.background.paper)
                    }
                }
            }
        }
    }
}
