package data.screening

import data.screening.repository.IScreeningRepository
import data.screening.repository.ScreeningRepository
import org.koin.dsl.module

val screeningModule = module {
    single<IScreeningRepository> {
        ScreeningRepository(get())
    }
}