package pages.common.components.appLogo

import MainPage
import csstype.*
import emotion.react.css
import kotlinx.browser.window
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.img

private fun provideNutriAppLogo(): String {
    return if (window.devicePixelRatio > 1) "${MainPage.BASEPATH}/images/NutriAppHeader@2x.png" else "${MainPage.BASEPATH}/images/NutriAppHeader.png"
}

val NutriAppLogo = FC<NutriAppLogoProps> { props ->
    img {
        alt = "NutriApp Logo"
        src = provideNutriAppLogo()
        css {
            zIndex = integer(100)
            position = Position.fixed
            pointerEvents = "none".unsafeCast<PointerEvents>()
            maxWidth = if (props.isSmall) 205.px else 411.px
            width = if (props.isSmall) 16.vw else 32.1.vw
        }
    }
}
