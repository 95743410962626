package data.screening.model.basicInfo

import de.comahe.i18n4k.strings.LocalizedString
import kotlinx.serialization.Serializable
import localization.Translation
import util.serializable.IntRangeSerializer

@Serializable
class StressFactor(
    override val id: Int,
    @Serializable(with = IntRangeSerializer::class) override val range: IntRange,
    override val label: LocalizedString
) : IBasicInfo {

    companion object {

        val noStressFactor =
            StressFactor(id = 0, range = 0..100, Translation.stressFactorNo)

        private val brainInjuryAcute =
            StressFactor(
                id = 1,
                range = 30..30,
                Translation.stressFactorBrainInjuryAcute
            )

        private val brainInjuryRecovery =
            StressFactor(
                id = 2,
                range = 5..50,
                Translation.stressFactorBrainInjuryRecovery
            )

        private val hemorrhagicStroke =
            StressFactor(
                id = 3,
                range = 30..30,
                Translation.stressFactorHemorrhagicStroke
            )

        private val ischemicStroke =
            StressFactor(id = 4, range = 5..5, Translation.stressFactorIschemicStroke)

        private val copd = StressFactor(id = 5, range = 15..15, Translation.stressFactorCOPD)

        private val infection =
            StressFactor(id = 6, range = 25..40, Translation.stressFactorInfection)

        private val inflammatoryBowelDisease = StressFactor(
            id = 7,
            range = 25..40,
            Translation.stressFactorInflammatoryBowelDisease
        )

        private val intensiveCareVentilated = StressFactor(
            id = 8,
            range = 10..10,
            Translation.stressFactorIntensiveCareVentilated
        )

        private val intensiveCareSeptic =
            StressFactor(
                id = 9,
                range = 20..20,
                Translation.stressFactorIntensiveCareSeptic
            )

        private val leukaemia =
            StressFactor(id = 10, range = 25..25, Translation.stressFactorLeukaemia)

        private val lymphoma =
            StressFactor(id = 11, range = 25..25, Translation.stressFactorLymphoma)

        private val pancreatitisChronic =
            StressFactor(
                id = 12,
                range = 3..3,
                Translation.stressFactorPancreatitisChronic
            )

        private val pancreatitisAcute =
            StressFactor(
                id = 13,
                range = 10..10,
                Translation.stressFactorPancreatitisAcute
            )

        private val pancreatitisSepsis = StressFactor(
            id = 14,
            range = 20..20,
            Translation.stressFactorPancreatitisSepsisAbscess
        )

        private val solidTumours =
            StressFactor(id = 15, range = 20..20, Translation.stressFactorSolidTumours)

        private val transplantation =
            StressFactor(
                id = 16,
                range = 20..20,
                Translation.stressFactorTransplantation
            )

        private val surgeryUncomplicated =
            StressFactor(
                id = 17,
                range = 5..10,
                Translation.stressFactorSurgeryUncomplicated
            )

        private val surgeryComplicated =
            StressFactor(
                id = 18,
                range = 25..30,
                Translation.stressFactorSurgeryComplicated
            )

        val stressFactors = listOf(
            brainInjuryAcute,
            brainInjuryRecovery,
            hemorrhagicStroke,
            ischemicStroke,
            copd,
            infection,
            inflammatoryBowelDisease,
            intensiveCareVentilated,
            intensiveCareSeptic,
            leukaemia,
            lymphoma,
            pancreatitisChronic,
            pancreatitisAcute,
            pancreatitisSepsis,
            solidTumours,
            transplantation,
            surgeryUncomplicated,
            surgeryComplicated
        )


    }

    override fun getInitValue(): Int {
        return range.last
    }
}