package pages.common.components.checkBoxPickerItem

import csstype.*
import emotion.react.css
import mui.material.*
import mui.system.sx
import pages.common.itemSubtitle
import pages.common.normalFontSize
import react.*

val CheckBoxPickerItemComponent = FC<CheckBoxPickerItemComponentProps> { props ->
    Button {
        variant = ButtonVariant.text
        onClick = {
            props.onCheckChanged.invoke(props.isChecked != true)
        }
        sx {
            width = 100.pct
            textTransform = "none".unsafeCast<TextTransform>()
            alignItems = AlignItems.center
            flexDirection = FlexDirection.row
            display = Display.flex
        }
        if (props.isRadioButton == true) {
            Radio {
                checked = props.isChecked
                color = RadioColor.primary
            }
        } else {
            Checkbox {
                checked = props.isChecked == true
                color = CheckboxColor.primary
            }
        }
        Typography {
            +props.title
            sx {
                normalFontSize()
                textAlign = TextAlign.start
                flexGrow = number(1.0)
                flexShrink = number(1.0)
            }
        }
        if (!props.subtitle.isNullOrBlank()) {
            Typography {
                +(props.subtitle ?: "")
                sx {
                    textAlign = TextAlign.start
                    marginLeft = 8.px
                    itemSubtitle()
                }
            }
        }
    }
}
