package data.screening.model.nrs

import kotlinx.serialization.Serializable

@Serializable
enum class LastWeekNutrition {
    LOWEST, //0-24
    LOW, //25-50
    HIGH, //50-75
    HIGHEST // 75-100
}