package data.products.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductNutrient(

    @SerialName("id") var idNutrient: Long = 0,
    var name: String = "",
    var country: String = "",
    var idProduct: Long = 0,
    var value: String? = null,
    var unit: String? = null,
    var categoryItem: Boolean = false,
    var subItem: Boolean = false,
    @SerialName("value_per_pack") var valuePerPack: Double? = null,
    @SerialName("value_per_100") var valuePer100: Double? = null,
    @SerialName("additional") var additionNutrients: List<ProductNutrient>? = null
) {
    companion object {
        const val ID_PROTEIN = 3L
        const val ID_FIBER = 16L
    }

    fun getPackLabel(): String {
        return "$valuePerPack $unit"
    }
}