package pages.mainMenu.components

import ThemeContext
import csstype.integer
import csstype.pct
import csstype.px
import data.mainMenu.model.MainMenu
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import pages.mainMenu.*
import react.*

val MainMenuCardComponent = FC<MainMenuCardComponentProps> { props ->
    val theme by useContext(ThemeContext)
    Card {
        onClick = {
            props.onClick.invoke()
        }
        raised = true
        if (props.id == MainMenu.ID_SCREENING) {
            sx {
                cardHolderChildren()
                width = 502.px //LinearDimension("502px !important")
                backgroundColor = theme.palette.primary.main //Color("${theme.palette.primary.main} !important")
            }
        } else {
            sx {
                cardHolderChildren()
            }
        }
        CardActionArea {
            sx {
                cardActionArea()
                height = 100.pct
            }
            Icon {
                +props.icon
                sx {
                    menuIcon()
                }
            }
            Container {
                sx {
                    cardTextHolder(theme)
                }
                Typography {
                    +props.text
                    variant = TypographyVariant.button
                    sx {
                        cardText()
                        if (props.id == MainMenu.ID_SCREENING) {
                            fontSize = 30.px
                            fontWeight = integer(500)
                        }
                    }
                }
                Icon {
                    +"navigate_next"
                    sx {
                        fontSize = 37.px
                    }
                }
            }
        }
    }
}
