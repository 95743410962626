package pages.product

import data.cookies.repository.ICookiesRepository
import data.products.repository.IProductsRepository
import js.core.get
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import localization.Translation
import mui.material.Box
import mui.material.CircularProgress
import mui.material.Container
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.appToolbar.AppToolbarComponent
import pages.common.contentHolder
import pages.product.detail.ProductDetailPage
import pages.product.search.ProductSearchPage
import react.*
import react.router.useNavigate
import react.router.useParams

class ProductPage : KoinComponent {
    private val cookiesRepository: ICookiesRepository by inject()
    private val productsRepository: IProductsRepository by inject()

    private val productPage = ProductDetailPage()
    private val productSearchPage = ProductSearchPage()

    @OptIn(DelicateCoroutinesApi::class)
    val component = FC<ProductPageProps> { props ->

        val navigate = useNavigate()

        val selectedCountry = cookiesRepository.getSelectedCountry()
        var isLoading by useState(false)
        useEffect(selectedCountry) {
            isLoading = true
            GlobalScope.launch {
                cookiesRepository.getSelectedCountry()?.let { country ->
                    productsRepository.fetchRemoteConfig()
                    productsRepository.fetchProducts(country)
                }
                isLoading = false
            }
        }
        val productId = useParams()["id"]
        val pageType = if (productId == null) {
            ProductPageType.SEARCH
        } else {
            ProductPageType.DETAIL
        }
        val backText = if (productId == null) {
            props.backText
        } else {
            Translation.backToProductSearch.invoke(props.i18n4k.locale)
        }

        AppToolbarComponent {
            this.title = if (productId == null) {
                Translation.productSearchTitle.invoke(props.i18n4k.locale)
            } else {
                Translation.productDetailTitle.invoke(props.i18n4k.locale)
            }
            this.backText = backText
            this.onClicked = {
                when (pageType) {
                    ProductPageType.DETAIL -> navigate("${MainPage.PAGE_PRODUCTS}/")
                    ProductPageType.SEARCH -> props.onNavigateBack.invoke()
                }
            }
        }

        Box {
            sx {
                contentHolder()
            }

            if (isLoading) {
                CircularProgress { }
            } else {
                if (productId != null) {
                    productPage.component {
                        this.i18n4k = props.i18n4k
                        this.productId = productId.toLongOrNull() ?: 0
                    }
                } else {
                    productSearchPage.component {
                        this.i18n4k = props.i18n4k
                    }
                }
            }
        }
    }
}
