package pages.common

import csstype.*
import mui.material.styles.Theme


fun PropertiesBuilder.normalFontSize() {
    fontSize = 14.px
}

fun PropertiesBuilder.toolbar(theme: Theme) {
    position = Position.fixed
    display = Display.flex
    width = 100.pct
    zIndex = integer(10)
    fontSize = 24.px
    fontWeight = integer(500)
    color = Color(theme.palette.background.paper)
    backgroundColor = Color("#004F71")
    justifyContent = JustifyContent.center
}

fun PropertiesBuilder.backNavigationText() {
    normalFontSize()
    textTransform = "none".unsafeCast<TextTransform>()
    color = Color("#FFFFFF")
    textDecoration = TextDecoration.underline
}

fun PropertiesBuilder.itemSubtitle() {
    normalFontSize()
    whiteSpace = WhiteSpace.nowrap
    width = Length.fitContent
    color = Color("#C7C7C7")
}

fun PropertiesBuilder.caption(theme: Theme) {
    fontSize = 14.px
    fontWeight = integer(500)
    color = theme.palette.text.primary
}

fun PropertiesBuilder.cardTitle() {
    fontSize = 20.px
    fontWeight = integer(500)
    textAlign = TextAlign.center
    paddingTop = 24.px
    paddingBottom = 24.px
}

fun PropertiesBuilder.cardTitleDivider() {
    borderColor = Color("#E5F6FC")
}

fun PropertiesBuilder.itemSubTitle() {
    normalFontSize()
    whiteSpace = WhiteSpace.nowrap
    width = Length.fitContent
    color = Color("#C7C7C7")
}

fun PropertiesBuilder.stepSelected() {
    width = 6.px
    height = 6.px
    borderRadius = 3.px
    backgroundColor = NamedColor.white
}

fun PropertiesBuilder.step() {
    width = 4.px
    height = 4.px
    borderRadius = 2.px
    backgroundColor = NamedColor.white
    opacity = number(0.5)
}

fun PropertiesBuilder.secondaryButton(theme: Theme) {
    color = theme.palette.primary.main
    fontWeight = integer(500)
    fontSize = 14.px
    backgroundColor = NamedColor.white
    minWidth = 326.px
    minHeight = 55.px
    hover {
        backgroundColor = NamedColor.whitesmoke
    }
    focus {
        backgroundColor = NamedColor.whitesmoke
    }
}

fun PropertiesBuilder.card() {
    display = Display.flex
    flexDirection = FlexDirection.column
    paddingBottom = 24.px
    borderRadius = 8.px
}

fun PropertiesBuilder.contentHolder() {
    maxWidth = important(676.px)
    margin = "92px auto 32px auto".unsafeCast<Margin>()
    padding = 0.px
}

fun PropertiesBuilder.iconInCircle() {
    fontFamily = string("Material Icons Outlined")
    fontSize = 24.px
    backgroundColor = NamedColor.white
    height = 36.px
    width = 36.px
    borderRadius = 18.px
    textAlign = TextAlign.center
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

fun PropertiesBuilder.dashesDivider() {
    /*border = Border(
        width = 0.75.px,
        style = LineStyle.dashed,
        color = Color("#DEF3FA")
    )
    backgroundColor = NamedColor.transparent
    opacity = number(1.0)*/

    borderStyle = LineStyle.dashed
    borderColor = Color("#DEF3FA")
    borderWidth = 1.px
    backgroundColor = NamedColor.transparent
    letterSpacing = 3.px
}

fun PropertiesBuilder.buttonIcon() {
    position = Position.absolute
    right = 24.px
    fontFamily = string("Material Icons Outlined")
}

/*
* val backNavigationText = css {
        fontSize = 14.px
        textTransform = TextTransform.textDecoration = csstype.TextDecoration(setOf(TextDecorationLine.underline))
        color = Color(theme?.palette?.background?.paper ?: "#FFFFFF")
    }
* */

/*
object CommonStyles : CSS {




    val backNavigationText = css {
        fontSize = 14.px
        textTransform = TextTransform.textDecoration = csstype.TextDecoration(setOf(TextDecorationLine.underline))
        color = Color(theme?.palette?.background?.paper ?: "#FFFFFF")
    }

    val backNavigationIcon = css {
        fontSize = 18.px
        color = Color(theme?.palette?.background?.paper ?: "#FFFFFF")
    }




    val cardTitle = css {
        fontSize = 20.px
        fontWeight = FontWeight.w500
        textAlign = TextAlign.center
        paddingTop = 24.px
        paddingBottom = 24.px
    }

    val cardTitleDivider = css {
        backgroundColor = Color("#E5F6FC")
    }

    val dashedDivider = css {
        border = "0.75px dashed #DEF3FA"
        backgroundColor = Color.transparent
        borderStyle = BorderStyle.dashed
        opacity = 1
    }

    val secondaryButton = css {
        theme?.palette?.primary?.main?.let {
            color = Color(it)
        }
        fontWeight = FontWeight.w500
        fontSize = 14.px
        backgroundColor = Color.white
        minWidth = 326.px
        minHeight = 55.px
        hover {
            backgroundColor = Color.whiteSmoke
        }
        focus {
            backgroundColor = Color.whiteSmoke
        }
        firstChild {
            borderRadius = 4.px
        }
    }

    val contentHolder = css {
        maxWidth = 676.px
        margin = "92px auto 32px auto"
        padding = "0px"
    }

    val toolbar = css {
        position = Position.fixed
        width = 100.pct
        zIndex = 10
        fontSize = LinearDimension("24px !important")
        fontWeight = FontWeight.w500
        color = Color(theme?.palette?.background?.paper ?: "#FFFFFF")
        backgroundColor = Color("#004F71")
        justifyContent = JustifyContent.center
    }

    val dividerDashed = css {
        borderStyle = BorderStyle.dashed
        borderColor = Color("#DEF3FA")
        borderWidth = 1.px
        backgroundColor = Color.transparent
        letterSpacing = 3.px
    }


    val stepSelected = css {
        width = 6.px
        height = 6.px
        borderRadius = 3.px
        backgroundColor = Color.white
    }

    val step = css {
        width = 4.px
        height = 4.px
        borderRadius = 2.px
        backgroundColor = Color.white
        opacity = 0.5
    }

}*/