package pages.common.components.inputBox

import ThemeContext
import csstype.*
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import pages.common.caption
import pages.common.normalFontSize
import react.*

val InputBoxDropDownComponent = FC<InputBoxDropDownComponentProps> { props ->
    val theme by useContext(ThemeContext)
    var isOpen: Boolean by useState(false)

    Card {
        sx {
            maxWidth = 298.px
            minHeight = 130.px
            if (props.isFlexGrowEnabled == true) {
                flexGrow = number(1.0)
            }
        }
        // props.css
        CardContent {
            sx {
                padding = 16.px
                display = Display.flex
                minHeight = 130.px
                height = 100.pct
                flexDirection = FlexDirection.column
                alignItems = AlignItems.flexStart
                justifyContent = JustifyContent.spaceBetween
            }

            Typography {
                +(props.title ?: "")
                variant = TypographyVariant.caption
                sx {
                    caption(theme)
                }
            }

            Typography {
                +(props.subtitle ?: "")
                sx {
                    normalFontSize()
                    color = theme.palette.text.secondary
                    flexGrow = number(1.0)
                }
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    padding = 0.px
                    width = 100.pct
                    alignItems = AlignItems.center
                }

                Select {
                    value = props.value.unsafeCast<Nothing?>()
                    open = isOpen
                    variant = SelectVariant.standard
                    onChange = { el, _ ->
                        isOpen = false
                        props.inputChangeCallback.invoke(el.target.value)
                    }
                    onOpen = {
                        isOpen = true
                    }
                    onClose = {
                        isOpen = false
                    }
                    endAdornment =
                        if (props.value.isNotBlank() &&
                            props.value != props.hint &&
                            !props.unit.isNullOrBlank()
                        ) {
                            Fragment.create {
                                InputAdornment {
                                    position = InputAdornmentPosition.end
                                    Typography {
                                        +(props.unit ?: "")
                                        sx {
                                            fontSize = 24.px
                                            marginRight = 24.px
                                            color = theme.palette.primary.main
                                        }
                                    }
                                }
                            }
                        } else {
                            null
                        }
                    sx {
                        flexGrow = number(1.0)
                        width = 100.pct
                        color = theme.palette.primary.main
                        fontSize = 24.px
                        marginRight = 16.px
                    }

                    props.hint?.let {
                        MenuItem {
                            key = it
                            value = it
                            +it
                        }
                    }

                    props.dropDownValues.forEachIndexed { _, s ->
                        MenuItem {
                            key = s
                            value = s
                            +s
                        }
                    }
                }

                Icon {
                    if (props.value.isBlank()) {
                        +"add_box"
                    } else {
                        +"edit"
                    }
                    sx {
                        color = theme.palette.primary.main
                        fontSize = 24.px
                        fontFamily = string("Material Icons Outlined")
                    }
                }
            }
        }
    }
}