package pages.countryPicker.components

import csstype.*
import mui.material.styles.Theme

fun PropertiesBuilder.countryText() {
    flexGrow = number(1.0)
    marginLeft = 16.px
    textTransform = "none".unsafeCast<TextTransform>()
    fontWeight = FontWeight.normal
    fontStyle = FontStyle.normal
    lineHeight = 21.px
    letterSpacing = 0.15.px
    fontSize = 16.px
    opacity = number(1.0)
}
