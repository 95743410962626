package data.countyPicker.model

data class Country(
    val imageUrl: String,
    val label: String,
    val productsLanguageCode: String,
    val language: String,
    val languageCountry: String,
    val stressMapperCountry: String,
    val allowSnaq: Boolean = false,
    val allowGlim: Boolean = false,
    val allowCompendium: Boolean = true,
    val allowSearch: Boolean = true,
    val allowNrsSuggestion: Boolean = true,
    val allowSnaqRc: Boolean = false,
    val sendEmailLayout: Boolean = true,
    val methodSettings: MethodSettings = MethodSettings(),
    val uiSettings: CountryUiSettings = CountryUiSettings()
) {

    fun getLocale(): String {
        return "${language}_$languageCountry"
    }

    companion object {

        const val DEFAULT_COUNTRY_CODE = "CZ"
        const val DEFAULT_PRODUCTS_LANGUAGE_CODE = "cz"
        const val DEFAULT_STRESS_MAPPER_COUNTRY_CODE = "cz"

        const val COUNTRY_CODE_CZ = "CZ"
        const val COUNTRY_CODE_SK = "SK"

        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_IT = "ch_it"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_DE = "ch_de"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_FR = "ch_fr"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_CZ = "cz"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_GR = "gr"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_HR = "cro"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_NL = "nl"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_SI = "sl"
        const val COUNTRY_PRODUCTS_LANGUAGE_CODE_SK = "sk"

        val countrySUI_IT = Country(
            imageUrl = "",
            label = "Svizzera – Italiano",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_IT,
            language = "it__rCH",
            allowCompendium = false,
            languageCountry = "CH",
            stressMapperCountry = "ch"
        )

        val countrySUI_DE = Country(
            imageUrl = "",
            label = "Schweiz – Deutsch",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_DE,
            language = "de__rCH",
            allowCompendium = false,
            languageCountry = "CH",
            stressMapperCountry = "ch",
            uiSettings = CountryUiSettings(
                allowCountryPickerConditionMessage = true,
                showScorePoints = false
            ),
            methodSettings = MethodSettings(
                allowNrsGenerate = true,
                allowMustGenerate = true,
                allowSarcGenerate = true
            )
        )

        val countrySUI_FR = Country(
            imageUrl = "",
            label = "Suisse – Français",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_FR,
            language = "fr__rCH",
            allowCompendium = false,
            languageCountry = "CH",
            stressMapperCountry = "ch",
            uiSettings = CountryUiSettings(
                allowCountryPickerConditionMessage = false,
                showScorePoints = false
            ),
            methodSettings = MethodSettings(
                allowNrsGenerate = true,
                allowMustGenerate = true,
                allowSarcGenerate = true
            )
        )

        val countryCZ = Country(
            imageUrl = "",
            label = "Czech Republic",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_CZ,
            language = "cs",
            languageCountry = "CZ",
            allowGlim = true,
            sendEmailLayout = false,
            stressMapperCountry = "cz",
            methodSettings = MethodSettings(
                allowNrsGenerate = true,
                allowMustGenerate = true,
                allowSarcGenerate = true
            )
        )

        val countryGR = Country(
            imageUrl = "",
            label = "Greece",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_GR,
            allowCompendium = false,
            language = "el",
            languageCountry = "GR",
            stressMapperCountry = "gr"
        )

        val countryHR = Country(
            imageUrl = "",
            label = "Croatia",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_HR,
            allowCompendium = false,
            language = "hr",
            languageCountry = "HR",
            stressMapperCountry = "cro"
        )

        val countryNL = Country(
            imageUrl = "",
            label = "The Netherlands",
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_NL,
            language = "nl",
            languageCountry = "NL",
            stressMapperCountry = "nl",
            allowSearch = false,
            allowSnaq = true,
            allowSnaqRc = true,
            allowNrsSuggestion = true,
            allowCompendium = false,
            methodSettings = MethodSettings(
                allowNrsGenerate = true,
                allowMustGenerate = true,
                allowSarcGenerate = true
            )
        )

        val countrySI = Country(
            imageUrl = "",
            label = "Slovenia",
            allowGlim = true,
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_SI,
            language = "sl",
            languageCountry = "SI",
            allowCompendium = false,
            stressMapperCountry = "slo",
            methodSettings = MethodSettings(
                allowNrsGenerate = true,
                allowMustGenerate = true,
                allowSarcGenerate = true
            )
        )

        val countrySK = Country(
            imageUrl = "",
            label = "Slovakia",
            allowGlim = true,
            productsLanguageCode = COUNTRY_PRODUCTS_LANGUAGE_CODE_SK,
            language = "sk",
            languageCountry = "SK",
            stressMapperCountry = "sk",
            methodSettings = MethodSettings(
                allowNrsGenerate = true,
                allowMustGenerate = true,
                allowSarcGenerate = true
            )
        )

        fun getDefaultCountry(): Country {
            return countryCZ
        }

        //zabaleni (CZ, SK, NL, CH (DE), CH (FR), SLO)
        //            countryGR,countrySUI_IT,countryHR
        val countries by lazy {
            listOf(
                countryCZ,
                countrySK,
                countryNL,
                countrySUI_DE,
                countrySUI_FR,
                countrySUI_IT,
                countrySI
//                countryHR
            )
        }
    }
}