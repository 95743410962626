
package external.dateFns


@JsNonModule
@JsModule("date-fns/locale/cs")
private external val LocaleCsModule: dynamic
val localeCs = LocaleCsModule.default

@JsNonModule
@JsModule("date-fns/locale/el")
private external val LocaleElModule: dynamic
val localeEl = LocaleElModule.default

@JsNonModule
@JsModule("date-fns/locale/fr")
private external val LocaleFrRChModule: dynamic
val localeFrRCH = LocaleFrRChModule.default

@JsNonModule
@JsModule("date-fns/locale/it")
private external val LocaleItRChModule: dynamic
val localeItRCH = LocaleItRChModule.default

@JsNonModule
@JsModule("date-fns/locale/de")
private external val LocaleDeRChModule: dynamic
val localeDeRCH = LocaleDeRChModule.default

@JsNonModule
@JsModule("date-fns/locale/en-US")
private external val LocaleEnModule: dynamic
val localeEn = LocaleEnModule.default

@JsNonModule
@JsModule("date-fns/locale/hr")
private external val LocaleHrModule: dynamic
val localeHr = LocaleHrModule.default

@JsNonModule
@JsModule("date-fns/locale/nl")
private external val LocaleNlModule: dynamic
val localeNl = LocaleNlModule.default

@JsNonModule
@JsModule("date-fns/locale/sk")
private external val LocaleSkModule: dynamic
val localeSk = LocaleSkModule.default

@JsNonModule
@JsModule("date-fns/locale/sl")
private external val LocaleSlModule: dynamic
val localeSl = LocaleSlModule.default

external interface DateFnsLocale {
    var code: String?
        get() = definedExternally
        set(value) = definedExternally
    var formatDistance: ((args: Any) -> Any)?
        get() = definedExternally
        set(value) = definedExternally
    var formatRelative: ((args: Any) -> Any)?
        get() = definedExternally
        set(value) = definedExternally
   /* var localize: Localize?
        get() = definedExternally
        set(value) = definedExternally*/
    var formatLong: FormatLong?
        get() = definedExternally
        set(value) = definedExternally
    var match: Match?
        get() = definedExternally
        set(value) = definedExternally
    var options: Options?
        get() = definedExternally
        set(value) = definedExternally
}

external interface Localize {
    var ordinalNumber: (args: Any) -> Any
    var era: (args: Any) -> Any
    var quarter: (args: Any) -> Any
    var month: (args: Any) -> Any
    var day: (args: Any) -> Any
    var dayPeriod: (args: Any) -> Any
}

external interface FormatLong {
    var date: (args: Any) -> Any
    var time: (args: Any) -> Any
    var dateTime: (args: Any) -> Any
}

external interface Match {
    var ordinalNumber: (args: Any) -> Any
    var era: (args: Any) -> Any
    var quarter: (args: Any) -> Any
    var month: (args: Any) -> Any
    var day: (args: Any) -> Any
    var dayPeriod: (args: Any) -> Any
}

external interface Options {
    var weekStartsOn: Int
    var firstWeekContainsDate: Int
}