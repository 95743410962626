package pages.product.detail

import csstype.*
import data.cookies.repository.ICookiesRepository
import data.products.model.Product
import data.products.model.ProductCaloricDensity
import data.products.model.ProductDetailVariant
import data.products.repository.IProductsRepository
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import localization.Translation
import mui.material.*
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.card
import pages.common.components.buttonGroupPicker.ButtonGroupPickerComponent
import pages.common.components.labelValue.LabelValueComponent
import pages.common.dashesDivider
import pages.common.normalFontSize
import react.*
import react.dom.html.ReactHTML.img
import react.router.useParams
import util.FirebaseWrapper

class ProductDetailPage : KoinComponent {
    private val cookiesRepository: ICookiesRepository by inject()
    private val productsRepository: IProductsRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<ProductDetailPageProps> { props ->

        var product: Product? by useState(null)
        var productDetailVariant: ProductDetailVariant by useState(ProductDetailVariant.perPackage)
        val selectedCountry = cookiesRepository.getSelectedCountry()

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_PRODUCT_DETAIL)
        }
        useEffect(props.productId) {
            GlobalScope.launch {
                selectedCountry?.let {
                    product = productsRepository.getProductDetail(it, props.productId)
                }
            }
            window.scrollTo(0.0, 0.0)
        }

        if (product == null) {
            CircularProgress
        } else {
            Paper {
                sx {
                    card()
                    paddingLeft = 24.px //80.px
                    paddingRight = 24.px //80.px
                    paddingTop = 24.px
                    paddingBottom = 24.px
                }

                Box {
                    sx {
                        position = Position.relative
                        paddingLeft = 0.px
                        paddingRight = 0.px
                    }
                    Box {
                        sx {
                            width = 254.px
                            padding = 0.px
                            height = 254.px
                            display = Display.flex
                            alignItems = AlignItems.center
                            marginLeft = "auto".unsafeCast<MarginLeft>()
                            marginRight = "auto".unsafeCast<MarginRight>()
                        }

                        img {
                            src = product?.imageThumb
                            css {
                                productDetailImage()
                            }
                        }
                    }

                    if (product?.gluttenFree == true || product?.lactoseFree == true) {
                        Box {
                            sx {
                                productContainerSymbols()
                            }

                            if (product?.gluttenFree == true) {
                                img {
                                    alt = "Gluten free"
                                    src = "${MainPage.BASEPATH}/images/symbol_gluten_free.svg"
                                    css {
                                        productDetailSymbol()
                                    }
                                }
                            }

                            if (product?.lactoseFree == true) {
                                img {
                                    alt = "Lactose free"
                                    src = "${MainPage.BASEPATH}/images/symbol_lactose_free.svg"
                                    css {
                                        productDetailSymbol()
                                    }
                                }
                            }
                        }
                    }

                    val productEnergy = product?.energy
                    if (productEnergy != null && productEnergy.value != "0/0") {
                        Box {
                            sx {
                                productContainerEnergy()
                            }

                            Typography {
                                +(productEnergy.value ?: "")
                                sx {
                                    productEnergyValue()
                                }
                            }

                            Typography {
                                +(productEnergy.unit ?: "")
                                sx {
                                    productEnergyUnit()
                                }
                            }
                        }

                        Typography {
                            +(product?.name ?: "")
                            sx {
                                productDetailName()
                            }
                        }

                        if (!product?.flavorObjects.isNullOrEmpty()) {
                            Box {
                                sx {
                                    productFlavorContainer()
                                }
                                for (flavour in product?.flavorObjects.orEmpty()) {
                                    Typography {
                                        +flavour.toString()
                                        sx {
                                            whiteSpace = WhiteSpace.pre
                                            productFlavorItem()
                                        }
                                    }
                                }
                            }
                        }

                        Divider {
                            sx {
                                marginTop = 16.px
                                marginBottom = 16.px
                                dashesDivider()
                            }
                        }

                        LabelValueComponent {
                            label = Translation.productDetailPackageSize.invoke(props.i18n4k.locale)
                            value = product?.getPackageSizeLabel() ?: ""
                        }


                        val pEnergy = product?.energy
                        if (pEnergy != null && pEnergy.value != "0/0") {
                            Divider {
                                sx {
                                    marginTop = 16.px
                                    marginBottom = 16.px
                                    dashesDivider()
                                }
                            }

                            LabelValueComponent {
                                label = Translation.productFilterCaloricDensity.invoke(props.i18n4k.locale)
                                value = (ProductCaloricDensity.energyToCaloricDensity(
                                    energy = requireNotNull(product).energy,
                                    caloricValue = requireNotNull(product).caloricDensityValue,
                                    unit = requireNotNull(product).units
                                )).toString()
                            }
                        }

                        product?.getProteinInPack()?.let { protein ->
                            Divider {
                                sx {
                                    marginTop = 16.px
                                    marginBottom = 16.px
                                    dashesDivider()
                                }
                            }
                            LabelValueComponent {
                                label = Translation.productDetailProteinsPack.invoke(props.i18n4k.locale)
                                value = protein.getPackLabel()
                            }
                        }

                        Divider {
                            sx {
                                marginTop = 16.px
                                dashesDivider()
                            }
                        }

                        Typography {
                            +Translation.productDetailDescriptionHeader.invoke(props.i18n4k.locale)
                            sx {
                                productDetailHeader()
                            }
                        }

                        Typography {
                            +(product?.description ?: "")
                            sx {
                                productDetailItem()
                            }
                        }

                        Typography {
                            +Translation.productDetailNutritionHeader.invoke(props.i18n4k.locale)
                            sx {
                                productDetailHeader()
                            }
                        }

                        if (!product?.nutritionForFlavour.isNullOrBlank()) {
                            Typography {
                                +(product?.nutritionForFlavour ?: "")
                                sx {
                                    productDetailItem()
                                    marginTop = 2.px
                                }
                            }
                        }

                        Divider {
                            sx {
                                backgroundColor = NamedColor.transparent
                                height = 24.px
                            }
                        }

                        ButtonGroupPickerComponent {
                            i18n4k = props.i18n4k
                            selectedItem = productDetailVariant
                            items = listOf(
                                ProductDetailVariant.perPackage,
                                ProductDetailVariant.per100(unit = product?.units)

                            ).toTypedArray()
                            onSelectionChanged = {
                                productDetailVariant = it as ProductDetailVariant
                            }
                        }

                        val nutritions = product?.getNutritionsByVariant(productDetailVariant)
                        if (!nutritions.isNullOrEmpty()) {
                            List {
                                sx {
                                    width = 100.pct
                                }
                                nutritions.forEach { nutrition ->
                                    ListItem {
                                        sx {
                                            display = Display.flex
                                            width = 100.pct
                                        }
                                        LabelValueComponent {
                                            label = nutrition.name
                                            bold = nutrition.bold
                                            subItem = nutrition.subItem
                                            value = nutrition.label
                                        }
                                    }
                                    Divider {
                                        sx {
                                            marginTop = 4.px
                                            marginBottom = 4.px
                                            dashesDivider()
                                        }
                                    }
                                }
                            }
                        }

                        product?.notes?.forEach {
                            Typography {
                                +it
                                sx {
                                    normalFontSize()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
