package data.screening.model.glim

import csstype.Color
import data.screening.model.IScreeningScoreResult
import data.screening.model.RiskLevel
import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

class GlimResult(
    override val riskLevel: RiskLevel,
    override val text: LocalizedString,
    override val icon: String,
    override val color: Color
) : IScreeningScoreResult {

    companion object {
        private val scoreLow = GlimResult(RiskLevel.NO_RISK, Translation.glimNoRisk, "info", Color("#64CCC9"))
        private val mediumScore = GlimResult(RiskLevel.MEDIUM, Translation.glimMidRisk, "visibility", Color("#F5B420"))
        private val scoreHigh = GlimResult(RiskLevel.HIGH, Translation.glimHighRisk, "error_outline", Color("#B3175A"))
        val results = listOf(scoreLow, mediumScore, scoreHigh)
    }
}