package data.products.model

import kotlinx.serialization.Serializable

@Serializable
data class ProductFlavor(
    var idFlavor: Long = 0,
    var country: String = "",
    var idProduct: Long = 0,
    val name: String,
    val code: String?
) {
    override fun toString(): String {
        val sb = StringBuilder(name)
        if (!code.isNullOrEmpty()) {
            sb.append("\n")
            sb.append(code)
        }
        return sb.toString()
    }
}