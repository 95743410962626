package data.cookies

import data.cookies.repository.CookiesRepository
import data.cookies.repository.ICookiesRepository
import org.koin.dsl.module

val cookiesModule = module {
    single<ICookiesRepository> {
        CookiesRepository()
    }
}