import csstype.px
import emotion.react.css
import js.core.jso
import mui.material.CssBaseline
import mui.material.styles.Theme
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import pages.product.detail.productDetailImage
import react.*
import react.dom.html.ReactHTML

typealias ThemeState = StateInstance<Theme>

val ThemeContext = createContext<ThemeState>()

val ThemeModule = FC<PropsWithChildren> { props ->
    val state = useState(Themes.default)
    val (theme) = state

    ThemeContext.Provider(state) {
        ThemeProvider {
            this.theme = theme
            CssBaseline()
            +props.children
        }
    }
}

object Themes {
    val default = createTheme(


        jso {

            typography = jso {
                fontFamily = "Calibri,Roboto"
            }
            palette = jso {
                primary = jso {
                    main = "#009CDE"
                    contrastText = "#000000"
                }
                secondary = jso {
                    main = "#64CCC9"
                }
                background = jso {
                    paper = "#FFFFFF"
                }
            }
        }
    )
}