package data.screening.model.nrs

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

class NrsIllnessQuestion(val title: LocalizedString, val text: LocalizedString?) {

    companion object {
        val questions = listOf(
            NrsIllnessQuestion(Translation.nrsMinorIllness, null),
            NrsIllnessQuestion(Translation.nrsChronicDiseaseFractureTitle, Translation.nrsChornicDiseaseFractureText),
            NrsIllnessQuestion(Translation.nrsLargeOperationsTitle, Translation.nrsLargeOperationsText),
            NrsIllnessQuestion(Translation.nrsJipTitle, Translation.nrsJipText)
        )
    }

}