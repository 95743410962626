package data.products.model

data class ProductSearchFilter(
    val caloric: Double? = null,
    val proteins: Double? = null,
    val diabetes: Boolean? = false,
    val fiber: Boolean? = false
){
    companion object{
        fun createNewFilter() = ProductSearchFilter()
    }
}