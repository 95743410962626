package data.cookies.repository

import data.countyPicker.model.Country
import external.getCookie
import external.removeCookie
import external.setCookie
import kotlinx.browser.document
import kotlinx.serialization.json.Json
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject


class CookiesRepository : ICookiesRepository, KoinComponent {

    private val json: Json by inject()

    companion object {
        private const val KEY_SELECTED_COUNTRY = "selectedCountry"
        private const val KEY_TERMS_AGREED = "isTermsAgreed"
        private const val KEY_COOKIES_ACCEPTED = "isCookiesAccepted"
        private const val KEY_COOKIES_CONSENT = "cc_cookie"
    }

    override fun setSelectedCountry(country: Country) {
        setCookie(KEY_SELECTED_COUNTRY, country.language)
    }

    override fun getSelectedCountry(): Country? {
        val countryCode = getCookie(KEY_SELECTED_COUNTRY)?.toString() ?: return null
        return Country.countries.firstOrNull { it.language == countryCode }
    }

    override fun isTermsAgreed(): Boolean {
        return getCookie(KEY_TERMS_AGREED).toString().toBoolean()
    }

    override fun setTermsAgreed(isAgreed: Boolean) {
        setCookie(KEY_TERMS_AGREED, isAgreed)
    }

    override fun isCookiesAccepted(): Boolean {
        return getCookie(KEY_COOKIES_ACCEPTED).toString().toBoolean()
    }

    override fun setCookiesAccepted(isAccepted: Boolean) {
        setCookie(KEY_COOKIES_ACCEPTED, isAccepted)
    }

    override fun clearGaCookies() {
        val cookieArr = document.cookie.split(";")
        for (element in cookieArr) {
            val cookieName = element.split("=").first().trim()
            if (cookieName.startsWith("_ga")) {
                removeCookie(cookieName)
            }
        }

    }
}
