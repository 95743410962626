package pages.compendium

import ThemeContext
import csstype.*
import emotion.react.css
import external.ReactPdfPageComponent
import external.ReactPdfDocumentComponent
import localization.Translation
import mui.material.Button
import mui.material.Container
import mui.material.Divider
import mui.material.Paper
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.appLogo.NutriAppLogo
import pages.common.secondaryButton
import react.*
import util.FirebaseWrapper

class CompendiumPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()

    val component = FC<CompendiumPageProps> { props ->
        var numOfPages by useState(0)

        // run effect only once
        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_COMPENDIUM)
        }

        val theme by useContext(ThemeContext)
        NutriAppLogo {
            isSmall = true
        }

        Container {
            sx {
                height = 100.pct
                width = 100.pct
                maxWidth = important(100.pct)
                paddingTop = 24.px
                paddingBottom = 24.px
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }

            Paper {
                sx {
                    width = 100.pct
                    maxWidth = 100.pct
                    flexGrow = number(1.0)
                    flexShrink = number(1.0)
                    overflow = Overflow.scroll
                }
                ReactPdfDocumentComponent {
                    file =
                        "${MainPage.BASEPATH}/terms/compendium_${props.selectedCountry.productsLanguageCode}.pdf" // ${props.i18n4k.locale.country}
                    onLoadSuccess = {
                        numOfPages = it.numPages
                    }

                    for (i in 1..numOfPages) {
                        if (i > 1) {
                            Divider { }
                        }
                        ReactPdfPageComponent {
                            pageNumber = i
                            scale = 2.0
                        }
                    }
                }
            }

            Button {
                +Translation.termsContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateBack.invoke()
                }
                sx {
                    marginTop = 40.px
                    secondaryButton(theme)
                }
            }
        }
    }
}
