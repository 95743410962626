package external.firebase

@OptIn(ExperimentalJsExport::class)
@JsExport
data class FirebaseOptions(
    @JsName("apiKey") val apiKey: String,
    @JsName("appId") val applicationId: String,
    @JsName("databaseURL") val databaseUrl: String?,
    @JsName("messagingSenderId") val gcmSenderId: String?,
    @JsName("projectId") val projectId: String?,
    @JsName("storageBucket") val storageBucket: String?,
    @JsName("authDomain") val authDomain: String?,
    @JsName("measurementId") val measurementId: String?
)