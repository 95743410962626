package data.screening.model.snaq

import de.comahe.i18n4k.strings.LocalizedString
import localization.Translation

data class SnaqQuestion(val text: LocalizedString) {

    companion object {
        val questions = listOf(
            SnaqQuestion(Translation.snaqQuestion1),
            SnaqQuestion(Translation.snaqQuestion2),
            SnaqQuestion(Translation.snaqQuestion3),
            SnaqQuestion(Translation.snaqQuestion4)
        )
    }

}