package pages.screening.patientInfo.component.equationResult

import ThemeContext
import csstype.*
import emotion.react.css
import localization.Translation
import mui.material.*
import mui.system.sx
import pages.common.card
import pages.common.iconInCircle
import pages.common.normalFontSize
import react.*

val EquationResultComponent = FC<EquationResultComponentProps> { props ->
    val theme by useContext(ThemeContext)
    Paper {
        sx {
            card()
            marginTop = 24.px
            backgroundColor = Color("#64CCC9")
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
        }

        Typography {
            +Translation.resultOfCaloricEquation.invoke(props.i18n4k.locale)
            sx {
                normalFontSize()
                color = Color(theme.palette.background.paper)
                fontWeight = integer(500)
                marginTop = 24.px
                marginBottom = 24.px
            }
        }
        Divider {
            variant = DividerVariant.fullWidth
            sx {
                width = 100.pct
                opacity = number(0.15)
                borderColor = Color("#000000")
                borderStyle = LineStyle.dashed
                borderWidth = 1.px
                backgroundColor = NamedColor.transparent
                letterSpacing = 3.px
            }
        }

        Box {
            sx {
                marginTop = 24.px
                marginLeft = 0.px
                marginRight = 0.px
                marginBottom = 0.px
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
                justifyContent = JustifyContent.center
            }

            Icon {
                +"assignment"
                sx {
                    iconInCircle()
                    color = Color("#64CCC9")
                }
            }

            Typography {
                +props.result
                sx {
                    marginLeft = 16.px
                    fontSize = 20.px
                    color = Color(theme.palette.background.paper)
                    fontWeight = integer(500)
                }
            }
        }
    }
}
