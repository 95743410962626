package pages.countryPicker.components

import ThemeContext
import csstype.*
import external.ReactCountryFlagComponent
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.Union
import mui.system.sx
import pages.common.secondaryButton
import react.*
import web.html.HTMLButtonElement

val CountryPickerComponent = FC<CountryPickerComponentProps> { props ->
    val theme by useContext(ThemeContext)
    var buttonRef: HTMLButtonElement? by useState(null)

    Button {
        id = props.componentId
        variant = ButtonVariant.contained
        onClick = {
            buttonRef = it.currentTarget
        }
        sx {
            secondaryButton(theme)
        }

        Container {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
                // width = Length.in
            }

            ReactCountryFlagComponent {
                svg = true
                countryCode = props.selectedCountry?.languageCountry?.uppercase() ?: ""
                style = object {
                    var width = "1.5em"
                }
            }

            Typography {
                props.selectedCountry?.label?.let {
                    +it
                }
                variant = TypographyVariant.button
                paragraph = false
                sx {
                    countryText()
                    color = theme.palette.primary.contrastText
                }
            }

            Icon {
                +"arrow_drop_down"
                sx {
                    fontSize = 1.5.rem
                    marginLeft = 8.px
                    color = Color(theme.palette.background.default)
                }
            }
        }
    }

    Popover {
        open = buttonRef != null
        onClose = { _, _ ->
            buttonRef = null
        }
        anchorOrigin = object : PopoverOrigin {
            override var horizontal: Union = "center"
            override var vertical: Union = "bottom"
        }

        anchorEl = buttonRef
        transformOrigin = object : PopoverOrigin {
            override var horizontal: Union = "center"
            override var vertical: Union = "top"
        }

        sx {
            width = 326.px
        }
        List {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                padding = 0.px
                width = 326.px
            }
            props.countries.forEach { country ->
                ListItem {
                    onClick = {
                        props.handleSelectionChanged.invoke(country)
                        buttonRef = null
                    }
                    ReactCountryFlagComponent {
                        svg = true
                        countryCode = country.languageCountry.uppercase()
                        style = object {
                            var width = "1.5em"
                        }
                    }
                    ListItemText {
                        +country.label
                        sx {
                            countryText()
                            color = theme.palette.primary.contrastText
                        }
                    }
                    sx {
                        marginLeft = 0.px
                        hover {
                            backgroundColor = NamedColor.whitesmoke
                            cursor = Cursor.pointer
                        }
                    }
                }
            }
        }
    }
}
