package pages.screening.patientInfo.component.stressFactor

import ThemeContext
import csstype.px
import data.screening.model.basicInfo.PhysicalActivity
import data.screening.model.basicInfo.StressFactor
import data.screening.repository.IScreeningRepository
import localization.Translation
import mui.material.*
import mui.system.responsive
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.*
import pages.common.components.checkBoxPickerItem.CheckBoxPickerItemComponent
import pages.common.components.labelValue.LabelValueComponent
import pages.common.components.riskProgressBar.RiskProgressBarComponent
import react.*
import kotlin.math.max
import kotlin.math.min
import kotlin.math.roundToInt

class StressFactorComponent : KoinComponent {
    private val screeningRepository: IScreeningRepository by inject()

    val component = FC<StressFactorComponentProps> { props ->

        val theme by useContext(ThemeContext)

        var stressFactors: List<StressFactor> by useState(emptyList())
        var physicalActivities: List<PhysicalActivity> by useState(emptyList())

        useEffectOnce {
            stressFactors = screeningRepository.getStressFactors()
            physicalActivities = screeningRepository.getPhysicalActivities()
        }

        Paper {
            sx {
                card()
                marginTop = 24.px
            }

            // physical activity
            Typography {
                +Translation.physicalActivity.invoke(props.i18n4k.locale)
                sx {
                    cardTitle()
                }
            }
            Divider {
                sx {
                    cardTitleDivider()
                }
            }

            // content
            Paper {
                sx {
                    marginTop = 24.px
                    marginLeft = 24.px
                    marginRight = 24.px
                    marginBottom = 40.px
                }
                Typography {
                    +Translation.physicalActivitySelect.invoke(props.i18n4k.locale)
                    sx {
                        caption(theme)
                        marginLeft = 16.px
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }

                if (physicalActivities.isNotEmpty()) {
                    Grid {
                        container = true
                        spacing = responsive(1)
                        columns = responsive(2)
                        sx {
                            paddingLeft = 16.px
                            paddingRight = 16.px
                            paddingBottom = 16.px
                        }
                        physicalActivities.forEach { physicalActivity ->
                            Grid {
                                item = true
                                asDynamic().xs = 1
                                sx {
                                    height = 64.px
                                }
                                key = "physical-activity-list-${physicalActivity.id}"
                                CheckBoxPickerItemComponent {
                                    isRadioButton = true
                                    isChecked = (props.patientInfo.selectedPhysicalActivity
                                        ?: PhysicalActivity.noPhysicalActivity.id) == physicalActivity.id
                                    title = physicalActivity.label.toString(props.i18n4k.locale)
                                    subtitle = physicalActivity.subText
                                    onCheckChanged = { isChecked ->
                                        props.onPatientInfoChanged.invoke(
                                            props.patientInfo.copy(
                                                selectedPhysicalActivity = physicalActivity.id,
                                                physicalActivityPercents = physicalActivity.getInitValue()
                                            )
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }

            Box {
                sx {
                    height = 40.px
                }
            }

            // progress
            RiskProgressBarComponent {
                val activity =
                    physicalActivities.firstOrNull { it.id == props.patientInfo.selectedPhysicalActivity }
                        ?: PhysicalActivity.noPhysicalActivity
                val minValue =
                    if (props.patientInfo.selectedPhysicalActivity == null) 0 else activity.range.first
                val maxValue =
                    if (props.patientInfo.selectedPhysicalActivity == null) 100 else activity.range.last

                text = Translation.risk.toString(props.i18n4k.locale)
                value = props.patientInfo.physicalActivityPercents
                onValueChanged = {
                    props.onPatientInfoChanged(
                        props.patientInfo.copy(
                            physicalActivityPercents = max(min(maxValue, it), minValue)
                        )
                    )
                }
                showButtons = true
                max = maxValue
                min = minValue
            }

            Divider {
                variant = DividerVariant.fullWidth
                sx {
                    dashesDivider()
                    marginTop = 24.px
                }
            }

            // stress factor
            Typography {
                +Translation.stressFactor.invoke(props.i18n4k.locale)
                sx {
                    cardTitle()
                }
            }
            Divider {
                sx {
                    cardTitleDivider()
                }
            }

            // content
            Paper {
                sx {
                    marginTop = 24.px
                    marginLeft = 24.px
                    marginRight = 24.px
                    marginBottom = 40.px
                }
                Typography {
                    +Translation.stressFactorChoose.invoke(props.i18n4k.locale)
                    sx {
                        caption(theme)
                        marginLeft = 16.px
                        marginTop = 16.px
                        marginBottom = 16.px
                    }
                }
                if (stressFactors.isNotEmpty()) {
                    Grid {
                        container = true
                        columns = responsive(2)
                        spacing = responsive(1)
                        sx {
                            paddingLeft = 16.px
                            paddingRight = 16.px
                            paddingBottom = 16.px
                        }
                        stressFactors.forEach { stressFactor ->
                            Grid {
                                item = true
                                asDynamic().xs = 1
                                sx {
                                    height = 64.px
                                }
                                key = "stress-factor-list-${stressFactor.id}"
                                CheckBoxPickerItemComponent {
                                    this.isChecked =
                                        props.patientInfo.selectedStressFactors.contains(stressFactor.id)
                                    this.title = stressFactor.label.toString(props.i18n4k.locale)
                                    this.subtitle = stressFactor.getRangeText()
                                    this.onCheckChanged = { isChecked ->
                                        val patientInfo = props.patientInfo
                                        val list = patientInfo.selectedStressFactors.toMutableList()
                                        if (isChecked == true) {
                                            list.add(stressFactor.id)
                                        } else {
                                            list.remove(stressFactor.id)
                                        }

                                        val stressFactorsFiltered = stressFactors.filter { list.contains(it.id) }
                                        props.onPatientInfoChanged(
                                            props.patientInfo.copy(
                                                selectedStressFactors = list,
                                                stressPercents = stressFactorsFiltered.sumOf { it.range.last }
                                            )
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // progress
            RiskProgressBarComponent {
                showButtons = true
                text = Translation.risk.toString(props.i18n4k.locale)
                value = props.patientInfo.stressPercents
                onValueChanged = {
                    props.onPatientInfoChanged(
                        props.patientInfo.copy(
                            stressPercents = it
                        )
                    )
                }
            }

            Divider {
                sx {
                    dashesDivider()
                    marginTop = 16.px
                }
            }

            Box {
                sx {
                    marginTop = 24.px
                    marginLeft = 48.px
                    marginRight = 48.px
                }

                // base of calculation
                LabelValueComponent {
                    val text = if (props.patientInfo.getBRM() == null) {
                        "?"
                    } else {
                        "${props.patientInfo.getBRM()?.roundToInt()} kcal"
                    }

                    label = Translation.baseOfCaloricEquation.invoke(props.i18n4k.locale)
                    value = text
                }

                // physical activity
                LabelValueComponent {
                    val selActivity =
                        physicalActivities.firstOrNull { it.id == props.patientInfo.selectedPhysicalActivity }
                    val activity = selActivity ?: PhysicalActivity.noPhysicalActivity
                    val valueText = if (props.patientInfo.getBRM() == null) {
                        if (props.patientInfo.physicalActivityPercents == 0) {
                            "0 kcal"
                        } else {
                            "?"
                        }
                    } else {
                        "${((props.patientInfo.getBRM() ?: 0.0) * props.patientInfo.physicalActivityPercents / 100).roundToInt()} kcal"
                    }

                    val subValueText = if (props.patientInfo.physicalActivityPercents != 0) {
                        if (props.patientInfo.physicalActivityPercents >= 0) {
                            "(+${props.patientInfo.physicalActivityPercents}%)"
                        } else {
                            "(${props.patientInfo.physicalActivityPercents}%)"
                        }
                    } else {
                        null
                    }
                    subValue = subValueText
                    label = activity.label.invoke(props.i18n4k.locale)
                    value = valueText
                }

                // stress factor
                if (props.patientInfo.selectedStressFactors.isEmpty()) {
                    LabelValueComponent {
                        label = Translation.stressFactorNo.invoke(props.i18n4k.locale)
                        value =
                            "${((props.patientInfo.getBRMStress() ?: 0.0)).roundToInt()} kcal"
                        val subValueText = if (
                            props.patientInfo.stressPercents > 0) {
                            "+ ${props.patientInfo.stressPercents}%"
                        } else {
                            null
                        }
                        subValue = subValueText
                    }
                } else {
                    props.patientInfo.selectedStressFactors.forEachIndexed { index, stressFactorId ->
                        val stressFactor =
                            stressFactors.firstOrNull { it.id == stressFactorId } ?: return@forEachIndexed

                        LabelValueComponent {
                            key = "stress-factor-result-${stressFactor.id}"
                            val stressText =
                                if (index == 0) {
                                    if (props.patientInfo.getBRMStress() == null) {
                                        "?"
                                    } else {
                                        "${props.patientInfo.getBRMStress()?.roundToInt()} kcal"
                                    }
                                } else {
                                    ""
                                }

                            val subValueText = if (index == 0) {
                                if (props.patientInfo.stressPercents > 0) {
                                    "(+${props.patientInfo.stressPercents}%)"
                                } else {
                                    "(${props.patientInfo.stressPercents}%)"
                                }
                            } else {
                                null
                            }
                            subValue = subValueText
                            value = stressText
                            label = stressFactor.label.invoke(props.i18n4k.locale)
                        }
                    }
                }
            }
        }
    }
}
