package pages.common.components.labelValueInBox

import csstype.*
import emotion.react.css
import mui.material.Box
import mui.material.Container
import mui.material.Typography
import mui.system.sx
import pages.common.normalFontSize
import react.*

val LabelValueInBoxComponent = FC<LabelValueInBoxComponentProps> { props ->
    Box {
        sx {
            paddingLeft = 0.px
            paddingRight = 0.px
            display = Display.flex
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.spaceBetween
            alignItems = AlignItems.center
            marginLeft = 24.px
            marginRight = 24.px
        }
        Typography {
            +props.label
            sx {
                normalFontSize()
                flexGrow = number(1.0)
                fontWeight = integer(500)
                color = props.color
                letterSpacing = 0.24.px
                lineHeight = 22.px
            }
        }
        Box {
            sx {
                borderRadius = 4.px
                width = 32.px
                height = 32.px
                backgroundColor = props.color
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
            }
            Typography {
                +props.value
                sx {
                    normalFontSize()
                    color = NamedColor.white
                    letterSpacing = 0.09.px
                    lineHeight = 19.px
                }
            }
        }
    }
}
