package pages.common.components.inputBox

import ThemeContext
import csstype.*
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import pages.common.caption
import react.*
import web.html.HTMLInputElement

// TODO test simple input
val SimpleInputComponent = FC<SimpleInputComponentProps> { props ->
    val theme by useContext(ThemeContext)
    val inputRef = createRef<HTMLInputElement>()

    Card {
        onClick = {
            inputRef.current?.focus()
        }
        sx {
            minHeight = 130.px
            if (props.isFlexGrowEnabled == true) {
                flexGrow = number(1.0)
            }
        }

        Box {
            sx {
                padding = 16.px
                display = Display.flex
                minHeight = 130.px
                height = 100.pct
                flexDirection = FlexDirection.column
                alignItems = AlignItems.flexStart
                justifyContent = JustifyContent.spaceBetween
            }

            Typography {
                +(props.title ?: "")
                variant = TypographyVariant.caption
                sx {
                    caption(theme)
                }
            }

            Box {
                sx {
                    width = 100.pct
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    padding = 0.px
                    alignItems = AlignItems.center
                }

                Input {
                    value = props.value
                    required = true
                    fullWidth = true
                    type = "number"
                    this.inputRef = inputRef
                    placeholder = props.hint
                    disableUnderline = true
                    onChange = {
                        val newValue = (it.target as HTMLInputElement).value
                        props.inputChangeCallback.invoke(newValue)
                    }
                    endAdornment = if (props.value.isNotBlank() && !props.unit.isNullOrBlank()) {
                        InputAdornment.create {
                            sx {
                                flexGrow = number(0.0)
                            }
                            position = InputAdornmentPosition.end
                            Typography {
                                +(props.unit ?: "")
                                sx {
                                    color = theme.palette.primary.main
                                    fontSize = 24.px
                                }
                            }
                        }
                    } else {
                        null
                    }
                    sx {
                        color = theme.palette.primary.main
                        fontSize = 24.px
                        marginRight = 16.px
                        flexGrow = number(1.0)
                        textAlign = TextAlign.right
                        valid {
                            opacity = number(1.0)
                        }
                        invalid {
                            opacity = number(0.25)
                        }
                    }
                }
            }
        }
    }
}