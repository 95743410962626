package data.screening.model.nrs

import kotlinx.serialization.Serializable

/**
 * Information about the illness severity
 * part "Zavaznost onemocneni" from the PDF
 */
@Serializable
data class IllnessSeverity(
    /**
     * Calculate as 0
     */
    val isMinorIllness: Boolean,

    /**
     * Calculate as 1
     */
    val isChronicIllness: Boolean,

    /**
     * Calculate as 2
     */
    val isBiggerSurgery: Boolean,

    /**
     * Calculate as 3
     */
    val isIcu: Boolean
)
