package pages.screening.components

import csstype.*
import emotion.react.css
import mui.material.*
import mui.system.sx
import pages.common.card
import pages.common.iconInCircle
import pages.common.normalFontSize
import react.FC
import react.dom.html.ReactHTML.img

val ScreeningScoreComponent = FC<ScreeningScoreComponentProps> { props ->

    val score = props.results.firstOrNull { it.riskLevel == props.risklevel } ?: return@FC

    Paper {
        sx {
            card()
            width = 100.pct
            marginTop = 24.px
            display = Display.flex
            flexDirection = FlexDirection.column
            backgroundColor = score.color
        }

        Typography {
            +props.title.invoke(props.i18n4k.locale)
            sx {
                normalFontSize()
                color = NamedColor.white
                margin = 24.px
                fontWeight = integer(500)
            }
        }
        Divider { }
        Box {
            sx {
                padding = 24.px
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
                fontSize = 16.px
            }


            val imagePath = if (props.isPng == true) {
                when (score.icon) {
                    "visibility" -> {
                        "${MainPage.BASEPATH}/images/visibility_outline.png"
                    }
                    "error_outline" -> {
                        "${MainPage.BASEPATH}/images/error_outline.png"
                    }
                    "info" -> {
                        "${MainPage.BASEPATH}/images/info_outline.png"
                    }
                    "check_circle" -> {
                        "${MainPage.BASEPATH}/images/check_outline.png"
                    }
                    else -> null
                }
            } else {
                null
            }

            if (imagePath == null) {
                Icon {
                    +score.icon
                    sx {
                        iconInCircle()
                        color = score.color
                    }
                }
            } else {
                img {
                    alt = "Screening result icon"
                    src = imagePath
                    css {
                        iconInCircle()
                        padding = 4.px
                    }
                }
            }

            val scoreLabel = StringBuilder()
            scoreLabel.append(score.text.invoke(props.i18n4k.locale))
            if (props.showPoints == true) {
                scoreLabel.append("(${props.score}${props.pointsText.invoke(props.i18n4k.locale)})")
            }
            Typography {
                +scoreLabel.toString()
                sx {
                    fontWeight = integer(500)
                    marginLeft = 16.px
                    fontSize = 18.px
                    color = NamedColor.white
                }
            }
        }
    }
}
