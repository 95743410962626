package data.screening.model.snaq

import data.screening.common.PatientInfo
import data.screening.model.IScore
import data.screening.model.RiskLevel
import kotlinx.serialization.Serializable

@Serializable
data class SnaqScore(
    val lostWeightSixMonths: Boolean = false,
    val armCircumference: Boolean = false,
    val lowTasteLastWeek: Boolean = false,
    val fifteenSteps: Boolean = false
) : IScore {

    override fun getTotal(patientInfo: PatientInfo): Int {
        if (lostWeightSixMonths) {
            return 3
        }

        if (armCircumference) {
            return 3
        }

        if (!lowTasteLastWeek) {
            return 1
        }

        if (fifteenSteps) {
            return 1
        }

        return 2
    }

    override fun getRiskLevel(patientInfo: PatientInfo): RiskLevel {
        return when (getTotal(patientInfo)) {
            1 -> RiskLevel.NO_RISK
            2 -> RiskLevel.MEDIUM
            else -> RiskLevel.HIGH
        }
    }

    override fun isValid(): Boolean {
        return true
    }


}