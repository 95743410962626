package pages.terms

import ThemeContext
import csstype.*
import external.*
import localization.Translation
import mui.material.Button
import mui.material.Container
import mui.material.Divider
import mui.material.Paper
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.appLogo.NutriAppLogo
import pages.common.secondaryButton
import react.*
import util.FirebaseWrapper

class TermsPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()
    val component = FC<TermsPageProps> { props ->

        val theme by useContext(ThemeContext)
        var numOfPages by useState(0)

        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_CONDITIONS)
        }

        NutriAppLogo {}

        Container {
            sx {
                height = 100.pct
                paddingTop = 7.75.vh
                paddingBottom = 7.75.vh
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }

            Paper {
                sx {
                    maxWidth = 676.px
                    width = 100.pct
                    flexGrow = number(1.0)
                    overflow = Overflow.scroll
                }
                ReactPdfDocumentComponent {
                    file =
                        "${MainPage.BASEPATH}/terms/conditions_${props.selectedCountry.productsLanguageCode}.pdf" // ${props.i18n4k.locale.country}
                    onLoadSuccess = {
                        numOfPages = it.numPages
                    }

                    for (i in 1..numOfPages) {
                        if (i > 1) {
                            Divider { }
                        }

                        ReactPdfPageComponent {
                            pageNumber = i
                        }
                    }
                }
            }

            Button {
                +Translation.termsContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateToNextPage()
                }
                sx {
                    marginTop = 40.px
                    secondaryButton(theme)
                }
            }
        }
    }
}
