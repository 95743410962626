package data.screening.model.result.request

import kotlinx.serialization.Serializable

@Serializable
data class MustPdfSettings(
    val bmiScore: Int,
    val weightLossScore: Int,
    /**
     * Calculate as 2 points
     */
    val isAcuteIllness: Boolean
)