package data.screening.model.result.request

import kotlinx.serialization.Serializable

@Serializable
data class SkPdfSettings(
    val weighLoss: Boolean?,
    val isReducedFoodIntake: Boolean?,
    val pacientInfo: PacientInfoRequest
)
@Serializable
data class PacientInfoRequest(
    private val type: String = "WalkingBMI",
    val gender: String,
    val examination: Boolean?
)