package pages.product.detail

import csstype.*

fun PropertiesBuilder.productDetailImage() {
    display = Display.block
    maxWidth = 254.px
    maxHeight = 254.px
}

fun PropertiesBuilder.productContainerSymbols() {
    top = 0.px
    left = 0.px
    gap = 20.px
    display = Display.flex
    paddingLeft = 0.px
    paddingRight = 0.px
    flexDirection = FlexDirection.column
    position = Position.absolute
}

fun PropertiesBuilder.productDetailSymbol() {
    width = 38.px
    height = 38.px
}

fun PropertiesBuilder.productContainerEnergy() {
    top = 0.px
    right = 0.px
    position = Position.absolute
    height = 112.px
    width = 112.px
    borderRadius = 50.pct
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    background = "#64CCC9 0% 0% no-repeat padding-box".unsafeCast<Background>()
    flexDirection = FlexDirection.column
    display = Display.flex
}

fun PropertiesBuilder.productEnergyValue() {
    textAlign = TextAlign.center
    fontSize = 20.px
    letterSpacing = 0.px
    color = Color("#FFFFFF")
    opacity = number(1.0)
}

fun PropertiesBuilder.productEnergyUnit() {
    textAlign = TextAlign.center
    fontSize = 13.px
    letterSpacing = 0.px
    color = Color("#FFFFFF")
}

fun PropertiesBuilder.productDetailName() {
    textAlign = TextAlign.start
    color = Color("#000000DE")
    letterSpacing = 0.26.px
    fontWeight = integer(500)
    fontSize = 20.px
}

fun PropertiesBuilder.productFlavorContainer() {
    flexDirection = FlexDirection.row
    flexWrap = FlexWrap.wrap
    gap = 6.px
    marginTop = 20.px
    paddingLeft = 0.px
    paddingRight = 0.px
    display = Display.flex
}

fun PropertiesBuilder.productFlavorItem() {
    fontSize = 13.px
    height = 100.pct
    paddingTop = 8.px
    paddingBottom = 8.px
    justifyContent = JustifyContent.center
    textAlign = TextAlign.center
    paddingLeft = 16.px
    paddingRight = 16.px
    color = Color("#000000")
    background = "#E5F6FC 0% 0% no-repeat padding-box".unsafeCast<Background>()
    borderRadius = 50.px
    fontWeight = integer(400)
    opacity = number(1.0)
}

fun PropertiesBuilder.productDetailHeader() {
    color = Color("#000000DE")
    opacity = number(1.0)
    letterSpacing = 0.26.px
    marginTop = 44.px
    textAlign = TextAlign.left
    fontSize = 20.px
    fontWeight = integer(500)
}

fun PropertiesBuilder.productDetailItem() {
    textAlign = TextAlign.left
    color = Color("#000000")
    letterSpacing = 0.24.px
    fontSize = 14.px
    fontWeight = integer(400)
    opacity = number(1.0)
    marginTop = 22.px
}
