package data.screening.model.result.request

import data.screening.model.nrs.IllnessSeverity
import data.screening.model.nrs.StartScreening
import kotlinx.serialization.Serializable

@Serializable
data class NrsPdfSettings(
    val startScreening: StartScreening,
    val endScreening: EndScreening,
    val illnessSeverity: IllnessSeverity,
    /**
     * Is age above 70
     */
    val isAgeAboveLimit: Boolean
)



/**
 * End screening
 * part "Zaverecny screening" from the PDF
 */
@Serializable
data class EndScreening(
    val screeningScore: Int
)

