package data.screening.model.snaqrc

import data.screening.common.PatientInfo
import data.screening.model.IScore
import data.screening.model.RiskLevel
import kotlinx.serialization.Serializable

@Serializable
data class SnaqRCScore(
    val recklessWeightLoss6Kg: Boolean = false,
    val recklessWeightLoss3Kg: Boolean = false,
    val needsSomeoneElseToEat: Boolean = false,
    val hasReducedAppetite: Boolean = false
) : IScore {

    override fun getTotal(patientInfo: PatientInfo): Int {
        val bmi = patientInfo.getBMI() ?: 0.0
        val recklessWeightLoss6KgPoints = if (recklessWeightLoss6Kg) {
            3
        } else {
            1
        }
        val recklessWeightLoss3KgPoints = if (recklessWeightLoss3Kg) {
            3
        } else {
            1
        }

        val needsSomeoneElseToEatPoints = if (needsSomeoneElseToEat) {
            2
        } else {
            1
        }

        val hasReducedAppetitePoints = if (hasReducedAppetite) {
            2
        } else {
            1
        }

        val lossWeightPoints = maxOf(
            recklessWeightLoss6KgPoints,
            recklessWeightLoss3KgPoints,
            needsSomeoneElseToEatPoints,
            hasReducedAppetitePoints
        )

        val bmiPoints = when {
            bmi < 20.0 -> {
                3
            }
            bmi in 20.0..22.0 -> {
                2
            }
            else -> {
                1
            }
        }

        return when (bmiPoints + lossWeightPoints) {
            in 0..2 -> 1
            3 -> 2
            else -> 3
        }


    }

    override fun getRiskLevel(patientInfo: PatientInfo): RiskLevel {
        return when (getTotal(patientInfo)) {
            1 -> RiskLevel.NO_RISK
            2 -> RiskLevel.MEDIUM
            else -> RiskLevel.HIGH
        }
    }


    override fun isValid(): Boolean {
        return true
    }


}