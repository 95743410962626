package util.serializable

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.js.Date

object DateSerializer : KSerializer<Date?> {

    override val descriptor: SerialDescriptor
        get() = PrimitiveSerialDescriptor("kotlinx.serialization.DateSerializer", PrimitiveKind.DOUBLE)

    override fun deserialize(decoder: Decoder): Date {
        return Date(decoder.decodeDouble())
    }

    override fun serialize(encoder: Encoder, value: Date?) {
        value?.let {
            try {
                encoder.encodeDouble(it.getTime())
            } catch (_: Throwable) {}
        }
    }
}