package pages.product.search.list

import csstype.*
import emotion.react.css
import localization.Translation
import mui.material.*
import mui.system.responsive
import mui.system.sx
import pages.common.card
import pages.common.cardTitle
import react.*
import react.dom.html.ReactHTML.img

val ProductListComponent = FC<ProductListComponentProps> { props ->

    Paper {
        sx {
            card()
            backgroundColor = Color("#E5F6FC")
        }
        Typography {
            +Translation.productListTitle.invoke(props.i18n4k.locale)
            sx {
                cardTitle()
            }
        }
        Divider {
            sx {
                backgroundColor = Color("#CDE9F2")
            }
        }

        if (props.products.isNotEmpty()) {
            Grid {
                columns = responsive(2)
                spacing = responsive(2)
                container = true
                sx {
                    padding = 24.px
                }
                props.products.forEach {
                    Grid {
                        item = true
                        key = it.id.toString()
                        sx {
                           // width = 100.pct
                            height = 358.px
                        }
                        asDynamic().xs = 1
                        Card {
                            sx {
                               // width = 100.pct
                                height = 100.pct

                            }

                            CardActionArea {
                                Box {
                                    onClick = { _ ->
                                        props.onProductClick.invoke(it)
                                    }

                                    sx {
                                        padding = 16.px
                                        display = Display.flex
                                        height = 100.pct
                                        flexDirection = FlexDirection.column
                                        alignItems = AlignItems.flexStart
                                        justifyContent = JustifyContent.spaceBetween
                                    }

                                    Box {
                                        sx {
                                            width = 154.px
                                            padding = 0.px
                                            height = 154.px
                                            display = Display.flex
                                            alignItems = AlignItems.center
                                            marginLeft = "auto".unsafeCast<MarginLeft>()
                                            marginRight = "auto".unsafeCast<MarginRight>()
                                            marginTop = 30.px
                                        }

                                        img {
                                            src = it.imageThumb
                                            css {
                                                // TODO width heigh auto
                                                display = Display.block
                                                maxWidth = 154.px
                                                maxHeight = 154.px
                                            }
                                        }

                                    }


                                    if (it.gluttenFree || it.lactoseFree) {
                                        Box {
                                            sx {
                                                marginTop = (-26).px
                                                display = Display.flex
                                                gap = 4.px
                                            }

                                            if (it.gluttenFree) {
                                                img {
                                                    alt = "Gluten free"
                                                    src = "${MainPage.BASEPATH}/images/symbol_gluten_free.svg"
                                                }
                                            }

                                            if (it.lactoseFree) {
                                                img {
                                                    alt = "Lactose free"
                                                    src = "${MainPage.BASEPATH}/images/symbol_lactose_free.svg"
                                                }
                                            }
                                        }
                                    }

                                    Box {
                                        sx {
                                            marginTop = 12.px
                                            display = Display.flex
                                        }

                                        Typography {
                                            +it.name
                                            sx {
                                                width = 100.pct
                                                color = Color("#009CDE")
                                                opacity = number(1.0)
                                                letterSpacing = 0.11.px
                                                fontSize = 16.px
                                                fontWeight = integer(400)
                                            }
                                        }

                                        img {
                                            alt = "Arrow continue"
                                            src = "${MainPage.BASEPATH}/images/arrow_right_blue.svg"
                                        }
                                    }

                                    Box {
                                        sx {
                                            display = Display.flex
                                            marginLeft = 16.px
                                            marginRight = 16.px
                                            flexDirection = FlexDirection.column
                                            justifyContent = JustifyContent.spaceBetween

                                        }

                                        Typography {
                                            +"${Translation.productListItemPackage.invoke(props.i18n4k.locale)}: ${it.getPackageSizeLabel()}"
                                            sx {
                                                productTextLabel()
                                            }
                                        }

                                        Typography {
                                            +"${Translation.productListItemDensity.invoke(props.i18n4k.locale)}: ${it.productCaloricDensity.toString()}"
                                            sx {
                                                productTextLabel()
                                            }
                                        }

                                        Typography {
                                            +"${Translation.productListItemProteins.invoke(props.i18n4k.locale)}: ${
                                                it.getProteinInPack()?.getPackLabel()
                                            }"
                                            sx {
                                                productTextLabel()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}