package util.serializable

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.*
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.encoding.decodeStructure
import kotlinx.serialization.encoding.encodeStructure

object IntRangeSerializer : KSerializer<IntRange> {


    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("kotlinx.serialization.IntRangeSerializer") {
        element<Int>("from")
        element<Int>("to")
    }

    override fun deserialize(decoder: Decoder): IntRange {
        return decoder.decodeStructure(descriptor) {
            return@decodeStructure IntRange(
                this.decodeIntElement(descriptor, 0),
                this.decodeIntElement(descriptor, 1)
            )
        }
    }

    override fun serialize(encoder: Encoder, value: IntRange) {
        return encoder.encodeStructure(descriptor) {
            encodeIntElement(descriptor, 0, value.first)
            encodeIntElement(descriptor, 1, value.last)
        }
    }
}