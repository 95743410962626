package data.products.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import util.zeroIfNull

@Serializable
data class Product(
    var id: Long = 0,
    var country: String = "",
    var name: String = "",
    var quantity: Int = 0,
    @SerialName("is_lactose_free") var lactoseFree: Boolean = false,
    @SerialName("is_gluten_free") var gluttenFree: Boolean = false,
    var flavors: List<String>? = null,
    @SerialName("flavorObjects") var flavorObjects: List<ProductFlavor>? = null,
    var energy: ProductEnergy? = null,
    var productCaloricDensity: ProductCaloricDensity? = null,
    @SerialName("caloric_density") var caloricDensityValue: Double? = null,
    @SerialName("nutrition_table_header") var nutritionHeader: ProductNutritionHeader? = null,
    @SerialName("used_for") var usedFor: String? = null,
    @SerialName("imageUrl") var images: List<String>? = null,
    var imageThumb: String? = null,
    var description: String = "",
    var notes: List<String>? = null,
    var units: String = "",
    @SerialName("nutrition_for_flavor") var nutritionForFlavour: String = "",
    @SerialName("is_for_diabetic") var diabetic: Boolean = false,
    @SerialName("package_size") var packageSize: Long = 0,
    var nutrientsList: List<ProductNutrient>? = null,
    private var nutrients: List<ProductNutrient>? = null
) {


    fun getPackageSizeLabel(): String {
        return "$packageSize $units"
    }

    fun getEpaLabel(): String {
        var epaLabel = ""
        for (n in nutrientsList.orEmpty()) {
            if (n.name.contains("EPA")) {
                epaLabel = "${n.valuePerPack} ${n.unit}"
                break
            }
        }
        return epaLabel
    }

    fun mergeNutrients() {
        val list = ArrayList<ProductNutrient>()
        for (n in nutrients.orEmpty()) {
            val additionNutrions = n.additionNutrients.orEmpty()
            additionNutrions.forEach {
                it.subItem = true
            }
            n.categoryItem = additionNutrions.isNotEmpty()
            list.add(n)
            list.addAll(additionNutrions)
        }
        list.forEach {
            it.idProduct = id
            it.country = country
        }
        nutrientsList = list
    }

    fun getNutritionsByVariant(variant: ProductDetailVariant): List<ProductNutritionRow> {
        val listPerVariant = ArrayList<ProductNutritionRow>()
        for (n in nutrientsList.orEmpty()) {

            val parameterToCheck = when (variant.wholePack) {
                false -> n.valuePer100
                true -> n.valuePerPack
            }
            if (parameterToCheck != null) {
                val rowData = ProductNutritionRow(
                    name = n.name,
                    label = "$parameterToCheck ${n.unit}",
                    bold = n.categoryItem,
                    subItem = n.subItem
                )
                listPerVariant.add(rowData)
            }
        }
        return listPerVariant
    }

    fun getProteinValue(): Double? {
        val proteinPerPack =
            nutrientsList?.firstOrNull { productNutrient -> productNutrient.idNutrient == ProductNutrient.ID_PROTEIN }?.valuePerPack
        return if (proteinPerPack != null && proteinPerPack > 0) {
            proteinPerPack
        } else {
            null
        }
    }

    fun getProteinInPack(): ProductNutrient? {
        return nutrientsList?.firstOrNull { productNutrient -> productNutrient.idNutrient == ProductNutrient.ID_PROTEIN }
    }

    fun includesFiber(): Boolean {
        return nutrientsList?.firstOrNull { productNutrient -> productNutrient.idNutrient == ProductNutrient.ID_FIBER && productNutrient.valuePerPack.zeroIfNull() > 0 } != null
    }
}