package pages.common.components.riskProgressBar

import csstype.Color
import csstype.PropertiesBuilder
import csstype.integer
import csstype.px
import mui.material.styles.Theme

fun PropertiesBuilder.riskProgressBarControlButton(theme: Theme) {
    height = 64.px
    width = 64.px
    fontWeight = integer(500)
    fontSize = 21.px
    color = Color(theme.palette.background.paper)
}
