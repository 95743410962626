package util

import kotlin.js.Date
import kotlin.math.abs

fun Date.calculateAge(): Int {
    val ageDiffMs = Date.now() - this.getTime()
    val ageDate = Date(ageDiffMs)
    return abs(ageDate.getUTCFullYear() - 1970)
}



fun Date.toFormattedPickerString(): String {
    return "${getFullYear().toString().padStart(4,'0')}-${addLeadingZero(getMonth() + 1)}-${addLeadingZero(getDate())}"
}

fun Date.toFormattedString(): String {
    return "${addLeadingZero(getDate())}.${addLeadingZero(getMonth() + 1)}.${getFullYear().toString().padStart(4,'0')}"
}

private fun addLeadingZero(value: Int): String {
    return if (value < 10) "0$value" else "$value"
}


fun Date.getDayLong(): String {
    val day = getDay()
    return if (day < 10) "0$day" else "$day"
}

fun Date.getMonthLong(): String {
    val month = getMonth()
    return if (month < 10) "0$month" else "$month"
}