package pages.common.components.stepper

import csstype.*
import emotion.react.css
import mui.material.Box
import mui.material.Container
import mui.system.sx
import pages.common.step
import pages.common.stepSelected
import react.*

val StepperComponent = FC<StepperComponentProps> { props ->
    Box {
        sx {
            display = Display.flex
            marginTop = 24.px
            marginBottom = 24.px
            flexDirection = FlexDirection.row
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
        }
        for (i in 1..props.stepsCount) {
            Box {
                sx {
                    margin = 8.px
                    if (i == props.step) {
                        stepSelected()
                    } else {
                        step()
                    }
                }
            }
        }
    }
}
