package data.products.model

import kotlinx.serialization.Serializable

@Serializable
data class ProductNutritionRow(
    val name: String,
    val label: String,
    val bold : Boolean,
    val subItem: Boolean
)