package data.products.model

import de.comahe.i18n4k.Locale
import kotlinx.serialization.Serializable
import localization.Translation
import pages.common.components.buttonGroupPicker.IButtonGroupItem

@Serializable
data class ProductDetailVariant(val unit: String?, val wholePack: Boolean) :
    IButtonGroupItem {

    companion object {
        fun per100(unit: String?): ProductDetailVariant {
            return ProductDetailVariant(unit, false)
        }

        val perPackage = ProductDetailVariant(null, true)
    }

    override val iconName: String? = null

    override fun getLabel(locale: Locale): String {
        val label = when (wholePack) {
            true -> Translation.productDetailBtnPackage
            false -> Translation.productDetailBtnPer100
        }
        return "${label.invoke(locale)} ${unit.orEmpty()}"
    }

}