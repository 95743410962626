package pages.screening.patientInfo.component.basicInfo

import ThemeContext
import csstype.*
import data.countyPicker.model.Country
import data.screening.common.Gender
import external.dateFns.*
import localization.Translation
import mui.material.*
import mui.system.responsive
import mui.system.sx
import pages.common.*
import pages.common.components.buttonGroupPicker.ButtonGroupPickerComponent
import pages.common.components.inputBox.InputBoxComponent
import pages.common.components.labelValue.LabelValueComponent
import react.*
import util.format
import kotlin.js.Date

val BasicInfoComponent = FC<BasicInfoComponentProps> { props ->
    val theme by useContext(ThemeContext)

    Paper {
        sx {
            card()
        }
        Typography {
            +Translation.basicPatientInfo.invoke(props.i18n4k.locale)
            sx {
                cardTitle()
            }
        }
        Divider {
            sx {
                cardTitleDivider()
            }
        }

        Box {
            sx {
                marginTop = 24.px
                paddingLeft = 24.px
                paddingRight = 24.px
            }

            ButtonGroupPickerComponent {
                i18n4k = props.i18n4k
                selectedItem = props.patientInfo.gender
                items = Gender.genders.toTypedArray()
                onSelectionChanged = {
                    props.onPatientInfoChanged(
                        props.patientInfo.copy(
                            gender = it as Gender
                        )
                    )
                }
            }

            Typography {
                +Translation.birthday.invoke(props.i18n4k.locale)
                sx {
                    caption(theme)
                    marginTop = 24.px
                }
            }

            CustomLocalizationProvider {
                this.asDynamic().dateAdapter = {
                    DateFnsUtil(object : DateFnsUtilsOption {
                        override var locale: Any? = when (props.country) {
                            Country.countryCZ -> {
                                localeCs
                            }
                            Country.countryGR -> {
                                localeEl
                            }
                            Country.countryHR -> {
                                localeHr
                            }
                            Country.countryNL -> {
                                localeNl
                            }
                            Country.countrySI -> {
                                localeSl
                            }
                            Country.countrySK -> {
                                localeSk
                            }
                            Country.countrySUI_DE -> {
                                localeDeRCH
                            }
                            Country.countrySUI_FR -> {
                                localeFrRCH
                            }
                            Country.countrySUI_IT -> {
                                localeItRCH
                            }
                            else -> {
                                localeEn
                            }
                        }
                        override var formats: DateIOFormats<String>? = defaultDateFnsFormats
                    })
                }
                CustomDatePicker {
                    sx {
                        width = 100.pct

                    }
                    asDynamic().mask = "__.__.____"
                    asDynamic().className = "datePicker"
                    asDynamic().value = props.patientInfo.birthday
                    asDynamic().disableFuture = true
                    asDynamic().invalidDateMessage = {}
                    asDynamic().inputFormat = "dd.MM.yyyy"
                    asDynamic().helperText = null
                    asDynamic().onChange = { newValue: Date? ->
                        props.onPatientInfoChanged(
                            props.patientInfo.copy(
                                birthday = newValue
                            )
                        )
                    }
                    asDynamic().renderInput = { params: Props ->
                        TextField.create {
                            sx {
                                "svg"{
                                    color = theme.palette.primary.main
                                }
                                width = 100.pct
                            }
                            +params
                        }
                    }
                    asDynamic().placeholder =
                        "${Translation.day.invoke(props.i18n4k.locale)}/${Translation.month.invoke(props.i18n4k.locale)}/" +
                                Translation.year.invoke(props.i18n4k.locale)
                }
            }

            Grid {
                sx {
                    marginTop = 16.px
                }
                container = true
                columns = responsive(2)
                spacing = responsive(2)
                Grid {
                    item = true
                    asDynamic().xs = 1
                    InputBoxComponent {
                        hint = Translation.weightUnit.invoke(props.i18n4k.locale)
                        isFlexGrowEnabled = true
                        title = Translation.weight.invoke(props.i18n4k.locale)
                        value = props.patientInfo.weightKg?.toString() ?: ""
                        inputChangeCallback = {
                            props.onPatientInfoChanged(
                                props.patientInfo.copy(
                                    weightKg = it.toIntOrNull()
                                )
                            )
                        }
                        unit = Translation.weightUnit.invoke(props.i18n4k.locale)
                    }
                }
                Grid {
                    item = true
                    asDynamic().xs = 1
                    InputBoxComponent {
                        hint = Translation.heightUnit.invoke(props.i18n4k.locale)
                        isFlexGrowEnabled = true
                        title = Translation.height.invoke(props.i18n4k.locale)
                        value = props.patientInfo.heightCm?.toString() ?: ""
                        inputChangeCallback = {
                            props.onPatientInfoChanged(
                                props.patientInfo.copy(
                                    heightCm = it.toIntOrNull()
                                )
                            )
                        }
                        unit = Translation.heightUnit.invoke(props.i18n4k.locale)
                    }
                }
            }
        }

        Divider {
            variant = DividerVariant.fullWidth
            sx {
                dashesDivider()
                marginTop = 16.px
            }
        }

        Box {
            sx {
                marginTop = 24.px
                marginLeft = 48.px
                marginRight = 48.px
            }

            var bmiText = props.patientInfo.getBMI()?.format(2) ?: ""
            bmiText = if (bmiText.isNotBlank()) {
                "$bmiText ${Translation.bmiUnit}"
            } else {
                "?"
            }

            LabelValueComponent {
                label = Translation.bmiValue.toString(props.i18n4k.locale)
                value = bmiText
            }

            var caloriesText = props.patientInfo.getBRM()?.format(0) ?: ""
            caloriesText = if (caloriesText.isNotBlank()) {
                "$caloriesText kcal"
            } else {
                "?"
            }

            LabelValueComponent {
                label = Translation.basicCaloricEquation.toString(props.i18n4k.locale)
                value = caloriesText
            }
        }
    }
}
