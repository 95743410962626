import data.cookies.repository.ICookiesRepository
import data.countyPicker.model.Country
import data.countyPicker.model.Country.Companion.countryCZ
import data.mainMenu.model.MainMenu
import data.products.repository.IProductsRepository
import de.comahe.i18n4k.Locale
import de.comahe.i18n4k.config.I18n4kConfigImmutable
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import localization.Translation
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.compendium.CompendiumPage
import pages.countryPicker.CountryPickerPage
import pages.mainMenu.MainMenuPage
import pages.product.ProductPage
import pages.screening.ScreeningPage
import pages.terms.TermsPage
import react.*
import react.router.Route
import react.router.Routes
import react.router.useLocation
import react.router.useNavigate
import util.FirebaseWrapper

class MainPage : KoinComponent {

    companion object {
        const val BASEPATH = "" //  localhost: /apps/abbott
        const val PAGE_COUNTRY_PICKER = "/country"
        const val PAGE_CONDITIONS = "/conditions"
        const val PAGE_COMPENDIUM = "/compendium"
        const val PAGE_PRODUCTS = "/products"
        const val PAGE_SCREENING = "/screening"

        const val PAGE_COUNTRY_SUI_IT = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_IT}"
        const val PAGE_COUNTRY_SUI_DE = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_DE}"
        const val PAGE_COUNTRY_SUI_FR = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_SUI_FR}"
        const val PAGE_COUNTRY_CZ = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_CZ}"
        const val PAGE_COUNTRY_GR = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_GR}"
        const val PAGE_COUNTRY_HR = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_HR}"
        const val PAGE_COUNTRY_NL = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_NL}"
        const val PAGE_COUNTRY_SI = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_SI}"
        const val PAGE_COUNTRY_SK = "/${Country.COUNTRY_PRODUCTS_LANGUAGE_CODE_SK}"

        val pageCountries: List<String> = listOf(
            PAGE_COUNTRY_SUI_IT, PAGE_COUNTRY_SUI_DE, PAGE_COUNTRY_SUI_FR,
            PAGE_COUNTRY_CZ, PAGE_COUNTRY_GR, PAGE_COUNTRY_HR, PAGE_COUNTRY_NL, PAGE_COUNTRY_SI, PAGE_COUNTRY_SK
        )
    }

    private val cookiesRepository: ICookiesRepository by inject()
    private val productsRepository: IProductsRepository by inject()
    private val firebaseWrapper: FirebaseWrapper by inject()
    private fun loadLocale(country: Country?): I18n4kConfigImmutable {
        return I18n4kConfigImmutable().withLocale(
            Locale(
                country?.language ?: Country.getDefaultCountry().language
            )
        )
    }

    private fun getAcceptedCategories(): Array<String> {
        val cats = window.asDynamic().cc?.getUserPreferences()?.accepted_categories
        return (cats as? Array<String>)
            ?: emptyArray()
    }

    private fun changeConsentLanguage(language: String) {
        window.asDynamic().cc?.updateLanguage(language, true)
    }

    private fun checkCookiesConsent() {
        val enabledCategories = getAcceptedCategories()
        val selectedLanguageCode = cookiesRepository.getSelectedCountry()?.productsLanguageCode
        if (enabledCategories.contains("analytics")) {
            firebaseWrapper.setupFirebaseAnalytics()
            if (selectedLanguageCode != null) {
                firebaseWrapper.setLanguageCode(selectedLanguageCode)
            }
        } else {
            cookiesRepository.clearGaCookies()
            firebaseWrapper.clearFirebaseAnalytics()
        }
    }

    private val termsPage = TermsPage()
    private val countryPickerPage = CountryPickerPage()
    private val compendiumPage = CompendiumPage()
    private val productPage = ProductPage()
    private val screeningPage = ScreeningPage()
    private val mainMenuPage = MainMenuPage()

    @OptIn(DelicateCoroutinesApi::class)
    val component = FC<Props> {
        val location = useLocation()
        val navigate = useNavigate()

        var selectedCountry by useState(cookiesRepository.getSelectedCountry() ?: countryCZ)
        var isTermsAgreed by useState(cookiesRepository.isTermsAgreed())

        var cookiesConsent by useState(getAcceptedCategories())
        var i18n4k by useState(loadLocale(selectedCountry))

        useEffectOnce {
            GlobalScope.launch {
                checkCookiesConsent()
                productsRepository.authUser()
                productsRepository.fetchRemoteConfig()
            }
        }

        useEffect(selectedCountry) {
            changeConsentLanguage(selectedCountry.language)
            i18n4k = loadLocale(selectedCountry)
            GlobalScope.launch {
                productsRepository.fetchProducts(selectedCountry)
            }
            firebaseWrapper.setLanguageCode(selectedCountry.productsLanguageCode)
        }

        // reroute if path was found
        val routeSplit = location.hash.split("#!/")
        if (routeSplit.size > 1) {
            navigate(routeSplit[1])
        }

        val path = location.pathname.removeSuffix("/")
        if (pageCountries.contains(path)) {
            val productLanguageCodePath = path.replace("/", "")
            if (selectedCountry.productsLanguageCode != productLanguageCodePath || !isTermsAgreed) {
                Country.countries.firstOrNull { country ->
                    country.productsLanguageCode == location.pathname.replace(
                        "/",
                        ""
                    )
                }?.let { country ->
                    selectedCountry = country
                    isTermsAgreed = false
                    cookiesConsent = getAcceptedCategories()
                    checkCookiesConsent()
                }
            } else {
                navigate("/")
            }
        }


        if (!isTermsAgreed || cookiesConsent.isEmpty()) {
            Routes {
                Route {
                    this.path = PAGE_CONDITIONS
                    element = showTerms(
                        i18n4k = i18n4k,
                        selectedCountry = selectedCountry
                    )
                }
                Route {
                    this.path = "/*"
                    element = showCountryPicker(
                        i18n4k = i18n4k,
                        selectedCountry = selectedCountry,
                        onSelected = {
                            selectedCountry = it
                        },
                        onConfirm = {
                            isTermsAgreed = true
                            cookiesConsent = getAcceptedCategories()
                            checkCookiesConsent()
                        }
                    )
                }
            }

            return@FC
        }

        Routes {
            Route {
                this.path = "$PAGE_COUNTRY_PICKER/*"
                element = showCountryPicker(
                    i18n4k = i18n4k,
                    selectedCountry = selectedCountry,
                    onSelected = {
                        selectedCountry = it
                    },
                    onConfirm = {
                        isTermsAgreed = true
                        cookiesConsent = getAcceptedCategories()
                        checkCookiesConsent()
                    }
                )
            }

            Route {
                this.path = "$PAGE_CONDITIONS/*"
                element = showTerms(
                    i18n4k = i18n4k,
                    selectedCountry = selectedCountry
                )
            }

            Route {
                this.path = "$PAGE_COMPENDIUM/*"
                element = compendiumPage.component.create {
                    this.i18n4k = i18n4k
                    this.selectedCountry = requireNotNull(selectedCountry)
                    this.onNavigateBack = {
                        navigate("/")
                    }
                }
            }

            Route {
                this.path = "$PAGE_PRODUCTS/*"
                element = productPage.component.create {
                    this.i18n4k = i18n4k
                    this.backText = Translation.backToMainPage.invoke(i18n4k.locale)
                    this.onNavigateBack = {
                        navigate("/")
                    }
                }
            }

            Route {
                this.path = "$PAGE_PRODUCTS/:id"
                element = productPage.component.create {
                    this.i18n4k = i18n4k
                    this.backText = Translation.backToMainPage.invoke(i18n4k.locale)
                    this.onNavigateBack = {
                        navigate("/")
                    }
                }
            }

            Route {
                this.path = "$PAGE_SCREENING/*"
                element = screeningPage.component.create {
                    this.i18n4k = i18n4k
                    this.selectedCountry = selectedCountry
                    this.onNavigateBack = {
                        navigate("/")
                    }
                }
            }

            Route {
                this.path = "/"
                element = mainMenuPage.component.create {
                    this.i18n4k = i18n4k
                    this.selectedCountry = requireNotNull(selectedCountry)
                    this.onMenuClicked = {
                        when (it) {
                            MainMenu.ID_COMPENDIUM -> {
                                navigate("${PAGE_COMPENDIUM}/")
                            }

                            MainMenu.ID_SEARCH -> {
                                navigate("${PAGE_PRODUCTS}/")
                            }

                            MainMenu.ID_SCREENING -> {
                                navigate("${PAGE_SCREENING}/")
                            }
                        }
                    }
                    this.onSettingsClicked = {
                        isTermsAgreed = false
                        cookiesConsent = getAcceptedCategories()
                        checkCookiesConsent()
                        navigate("${PAGE_COUNTRY_PICKER}/")
                    }
                    this.onTermsClicked = {
                        navigate("${PAGE_CONDITIONS}/")
                    }
                }
            }
        }
    }

    private fun showCountryPicker(
        i18n4k: I18n4kConfigImmutable,
        selectedCountry: Country?,
        onSelected: (country: Country) -> Unit,
        onConfirm: () -> Unit
    ): ReactElement<*> {
        val navigate = useNavigate()
        return countryPickerPage.component.create {
            onCountrySelected = {
                onSelected(it)
            }
            this.selectedCountry = selectedCountry ?: Country.getDefaultCountry()
            this.countries = Country.countries
            this.i18n4k = i18n4k
            onNavigateToTermsPage = {
                navigate(PAGE_CONDITIONS)
            }
            onNavigateToNextPage = {
                onConfirm()
                navigate("/")
            }
        }
    }

    private fun showTerms(
        i18n4k: I18n4kConfigImmutable,
        selectedCountry: Country?
    ): ReactElement<*> {
        val navigate = useNavigate()
        return termsPage.component.create {
            this.i18n4k = i18n4k
            this.selectedCountry = selectedCountry ?: Country.getDefaultCountry()
            onNavigateToNextPage = {
                navigate("/")
            }
        }
    }


}
