package data.mainMenu

import data.mainMenu.repository.IMainMenuRepository
import data.mainMenu.repository.MainMenuRepository
import org.koin.dsl.module

val mainMenuModule = module {
    single<IMainMenuRepository> {
        MainMenuRepository()
    }
}