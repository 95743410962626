package data.screening.model.sacrf

import data.screening.common.PatientInfo
import data.screening.model.IScore
import data.screening.model.RiskLevel
import kotlinx.serialization.Serializable
import kotlin.math.max

@Serializable
data class SarcScore(
    val power: SarcScoreOption = SarcScoreOption.NONE,
    val helpWalking: SarcScoreOption = SarcScoreOption.NONE,
    val gettingUpChair: SarcScoreOption = SarcScoreOption.NONE,
    val walkingStairs: SarcScoreOption = SarcScoreOption.NONE,
    val falls: SarcScoreOption = SarcScoreOption.NONE
) : IScore {

    override fun getTotal(patientInfo: PatientInfo): Int {
        return max(0, power.score) +
                max(0, helpWalking.score) +
                max(0, gettingUpChair.score) +
                max(0, walkingStairs.score) +
                max(0, falls.score)
    }

    override fun getRiskLevel(patientInfo: PatientInfo): RiskLevel {
        return if (getTotal(patientInfo) >= 4) {
            RiskLevel.HIGH
        } else {
            RiskLevel.NO_RISK
        }
    }

    override fun isValid(): Boolean {
        return power != SarcScoreOption.NONE
                && helpWalking != SarcScoreOption.NONE
                && gettingUpChair != SarcScoreOption.NONE
                && walkingStairs != SarcScoreOption.NONE
                && falls != SarcScoreOption.NONE
    }
}