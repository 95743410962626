package pages.screening.sarcf.questionaire

import ThemeContext
import csstype.*
import data.screening.model.sacrf.SarcResult
import data.screening.model.sacrf.SarcScoreOption
import data.screening.repository.IScreeningRepository
import emotion.react.css
import localization.Translation
import mui.material.Box
import mui.material.Button
import mui.material.Container
import mui.material.Paper
import mui.system.sx
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.common.components.questionAnswerSelector.IAnswer
import pages.common.components.questionAnswerSelector.IQuestion
import pages.common.components.questionAnswerSelector.QuestionAnswerSelectorComponent
import pages.common.secondaryButton
import pages.screening.components.ScreeningScoreComponent
import react.*

class SarcfQuestionnairePage : KoinComponent {
    private val screeningRepository: IScreeningRepository by inject()

    private fun getSelectedAnswer(score: Int?, question: IQuestion): IAnswer? {
        if (score == null || score == -1)
            return null

        return question.answers[score]
    }

    private fun selectAnswer(props: SarcfQuestionnairePageProps, answer: IAnswer?, index: Int) {
        if (answer == null)
            return

        println("answer selected")

        val sarcFInfo = when (index) {
            0 -> props.patientInfo.sarcF.copy(power = SarcScoreOption.values()[answer.id + 1])
            1 -> props.patientInfo.sarcF.copy(helpWalking = SarcScoreOption.values()[answer.id + 1])
            2 -> props.patientInfo.sarcF.copy(gettingUpChair = SarcScoreOption.values()[answer.id + 1])
            3 -> props.patientInfo.sarcF.copy(walkingStairs = SarcScoreOption.values()[answer.id + 1])
            else -> props.patientInfo.sarcF.copy(falls = SarcScoreOption.values()[answer.id + 1])
        }
        props.onPatientInfoChanged.invoke(
            props.patientInfo.copy(
                sarcF = sarcFInfo
            )
        )
    }

    val component = FC<SarcfQuestionnairePageProps> { props ->
        val theme by useContext(ThemeContext)
        val questions by useState(screeningRepository.getSarcfQuestions())

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                alignItems = AlignItems.center
            }
            Paper {
                sx {
                    width = 100.pct
                }
                questions.forEachIndexed { index, iQuestion ->
                    QuestionAnswerSelectorComponent {
                        question = iQuestion
                        i18n4k = props.i18n4k
                        selectedAnswer = when (index) {
                            0 -> getSelectedAnswer(props.patientInfo.sarcF.power.score, iQuestion)
                            1 -> getSelectedAnswer(props.patientInfo.sarcF.helpWalking.score, iQuestion)
                            2 -> getSelectedAnswer(props.patientInfo.sarcF.gettingUpChair.score, iQuestion)
                            3 -> getSelectedAnswer(props.patientInfo.sarcF.walkingStairs.score, iQuestion)
                            else -> getSelectedAnswer(props.patientInfo.sarcF.falls.score, iQuestion)
                        }
                        onAnswerSelected = {
                            println("answer ${it}")
                            selectAnswer(props, it, index)
                        }
                    }
                }
            }

            ScreeningScoreComponent {
                i18n4k = props.i18n4k
                title = Translation.sarcfScreeningRiskTitle
                results = SarcResult.results
                risklevel = props.patientInfo.sarcF.getRiskLevel(props.patientInfo)
                score = props.patientInfo.sarcF.getTotal(props.patientInfo)
                pointsText = Translation.screeningScorePoints
                showPoints = props.country.uiSettings.showScorePoints
            }

            // continue button
            Button {
                +Translation.stressFactorContinue.invoke(props.i18n4k.locale)
                onClick = {
                    props.onNavigateNext.invoke()
                }
                disabled = !props.patientInfo.sarcF.isValid()
                sx {
                    secondaryButton(theme)
                    margin = "40px auto 0 auto".unsafeCast<Margin>()
                }
            }
        }
    }
}
