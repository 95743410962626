package pages.screening.sarcf

import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import pages.screening.ScreeningMethodProps
import pages.screening.ScreeningPage
import pages.screening.sarcf.questionaire.SarcfQuestionnairePage
import react.*
import react.router.Route
import react.router.Router
import react.router.Routes
import util.FirebaseWrapper

class SarcfPage : KoinComponent {
    private val firebaseWrapper: FirebaseWrapper by inject()
    private val sarcfQuestionnairePage = SarcfQuestionnairePage()

    val component = FC<ScreeningMethodProps> { props ->
        useEffectOnce {
            firebaseWrapper.logScreenView(FirebaseWrapper.SCREEN_NAME_SCREENING_SARC_METHOD)
        }

        Routes {
            Route {
                path = "/"
                element = sarcfQuestionnairePage.component.create {
                    patientInfo = props.patientInfo
                    country = props.country
                    i18n4k = props.i18n4k
                    onPatientInfoChanged = {
                        props.onPatientInfoChanged.invoke(it)
                    }
                    onNavigateNext = {
                        props.onNavigateNext.invoke()
                    }
                }
            }
        }
    }
}
