package data.screening.common

import de.comahe.i18n4k.Locale
import kotlinx.serialization.Serializable
import localization.Translation
import pages.common.components.buttonGroupPicker.IButtonGroupItem

@Serializable
data class Gender(val shortcut: String, val icon: String) : IButtonGroupItem {

    companion object {
        val male = Gender("M", "male")
        val female = Gender("F", "female")
        val genders = listOf(male, female)
    }

    override val iconName: String?
        get() = icon

    override fun getLabel(locale: Locale): String {
        return when (shortcut) {
            "F" -> Translation.genderFemale.invoke(locale)
            "M" -> Translation.genderMale.invoke(locale)
            else -> throw Throwable("Unsupported gender")
        }
    }
}